import { sendAPIRequest, stripHtml } from 'helpers';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import Spinner from "components/spinner/Spinner";
import { useTranslation } from 'react-i18next';

const DynamicModal = ({ show, handleClose, handleSave, handleConfirm, isCode, mode, id }) => {

  const [isLoading, setIsLoading] = useState({ updating_data: false });
  const [formState, setFormState] = useState({
    subsectionType: '',
    subsectionText: '',
    referenceNo: '',
    recommendationText: '',
    dropdownSelection: '',
  });
  const [error, setError] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (id) {
      loadSubsectionInformation();
    }
  }, [id]);

  useEffect(() => {
    if (!formState.dropdownSelection) {
      setFormState((prevState) => ({ ...prevState, recommendationText: '' }));
    }
  }, [formState.dropdownSelection]);

  const loadSubsectionInformation = async () => {
    try {
      const ret = await sendAPIRequest(`/api/draft-content/load-subsection/${id}`);
      const res = await ret.json();
      if (res.success) {
        const child = (res.data.children && res.data.children.length > 0) ? res.data.children[0] : null;
        setFormState({
          subsectionType: res.data.text_type,
          subsectionText: stripHtml(res.data.standard_content),
          referenceNo: res.data.ref_number,
          recommendationText: child ? stripHtml(child.standard_content) : '',
          dropdownSelection: child ? child.text_type : ''
        });
      }
    } catch (e) {
      console.log({ e });
    }
  }

  const handleConfirmUpdate = async () => {
    setIsLoading({ updating_data: true });
    try {
      await handleConfirm();
    } finally {
      setIsLoading({ updating_data: false });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateFields = useCallback(() => {
    const { subsectionText, referenceNo, dropdownSelection, recommendationText, subsectionType } = formState;
    if (isCode) {
      if ((dropdownSelection && !recommendationText)) {
        setError('Fields cannot be empty');
        return false;
      }
    } else {
      if (!subsectionType || !subsectionText) {
        setError('Fields cannot be empty');
        return false;
      }
    }
    setError('');
    return true;
  }, [formState, isCode]);

  const saveSubsection = useCallback(() => {
    if (validateFields()) {
      const { dropdownSelection, subsectionText, referenceNo, recommendationText, subsectionType } = formState;
      if (isCode) {
        handleSave(parseInt(dropdownSelection), subsectionText, referenceNo, recommendationText);
      } else {
        handleSave(parseInt(subsectionType), subsectionText);
      }
      setFormState({
        subsectionType: '',
        subsectionText: '',
        referenceNo: '',
        recommendationText: '',
        dropdownSelection: '',
      });
      handleClose();
    }
  }, [formState, isCode, handleSave, validateFields, handleClose]);

  const codeOptions = useMemo(() => [
    { id: 92, value: 'safe_commodities', label: 'Safe Commodities' },
    { id: 93, value: 'recommendations_for_importations', label: 'Recommendations for Importations' },
    { id: 94, value: 'inactivation_procedures', label: 'Inactivation Procedures' },
    { id: 101, value: 'general_heading', label: 'General Heading' }
  ], []);

  const nonCodeOptions = useMemo(() => [
    { id: 144, value: 'manual_heading', label: 'Manual Heading' },
    { id: 146, value: 'manual_reference', label: 'Manual Reference' },
    { id: 148, value: 'manual_appendex', label: 'Manual Appendex' },
    { id: 149, value: 'manual_biblography', label: 'Manual Bibliography' },
  ], []);

  const options = useMemo(() => (isCode ? codeOptions : nonCodeOptions), [isCode, codeOptions, nonCodeOptions]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{(!(mode === 'edit') ? (mode === 'delete') || (mode === 'update') ? 'Confirmation' : 'Add Article/Subsection' : 'Edit Article/Subsection')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(mode === 'delete') || (mode === 'update') ? (
          mode === 'delete' ?
            'Are you sure you want to delete the sub-section/article?'
            : 'Are you sure you want to update the sub-section/article?'
        ) : (
          <>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form>
              {isCode ? (
                <>
                  {/* <Form.Group controlId="subsectionText">
                    <Form.Label>Article Text</Form.Label>
                    <Form.Control
                      type="text"
                      name="subsectionText"
                      value={formState.subsectionText}
                      onChange={handleInputChange}
                      placeholder="Enter article text"
                    />
                  </Form.Group>
                  <Form.Group controlId="referenceNo">
                    <Form.Label>Reference No</Form.Label>
                    <Form.Control
                      type="text"
                      name="referenceNo"
                      value={formState.referenceNo}
                      onChange={handleInputChange}
                      placeholder="Enter reference number"
                    />
                  </Form.Group> */}
                  <Form.Group controlId="dropdownSelection">
                    <Form.Label>Type</Form.Label>
                    <Form.Control as="select" name="dropdownSelection" value={formState.dropdownSelection} onChange={handleInputChange}>
                      <option value="">Select type</option>
                      {options.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  {(formState.dropdownSelection || formState.recommendationText) && (
                    <Form.Group controlId="recommendationText">
                      <Form.Label>Recommendation Text</Form.Label>
                      <Form.Control
                        type="text"
                        name="recommendationText"
                        value={formState.recommendationText}
                        onChange={handleInputChange}
                        placeholder="Enter recommendation text"
                      />
                    </Form.Group>
                  )}
                </>
              ) : (
                <>
                  <Form.Group controlId="subsectionText">
                    <Form.Label>Subsection Text</Form.Label>
                    <Form.Control
                      type="text"
                      name="subsectionText"
                      value={formState.subsectionText}
                      onChange={handleInputChange}
                      placeholder="Enter subsection text"
                    />
                  </Form.Group>
                  <Form.Group controlId="subsectionType">
                    <Form.Label>Subsection Type</Form.Label>
                    <Form.Control as="select" name="subsectionType" value={formState.subsectionType} onChange={handleInputChange}>
                      <option value="">Select type</option>
                      {options.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </>
              )}
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        {(mode === 'delete') || (mode === 'update') ? (
          <Button
            variant="primary"
            onClick={handleConfirmUpdate}
            disabled={isLoading.updating_data}
          >
            {isLoading.updating_data ? (
              <Spinner message="Loading..." />
            ) : (
              <span>{t("-1", " Confirm")}</span>
            )}
          </Button>
          // <Button variant="primary" onClick={handleConfirm}>
          //   Confirm
          // </Button>
        ) : (
          <Button variant="primary" onClick={saveSubsection}>
            {mode === 'edit' ? "Update" : "Save"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

DynamicModal.defaultProps = {
  show: true,
  handleClose: () => { },
  handleSave: () => { },
  handleConfirm: () => { },
  isCode: true,
  mode: '',
  id: null,
};

export default DynamicModal;