import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

function PublishingHistory() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const authData = useSelector(s => s.auth.data);
    const [requestRelloader, setRequestRelloader] = useState(null);

    return (
        <>
            <div className='p-5'>
                <div className="card-title1 lh-1 border-bottom border-dark">{t("-1", "Publishing History")}</div>
                <ul className='pt-10 pb-20'>
                    <li>
                        11-Nov-23 11:43 AM <span className='ps-20 fw-bold'>Article 8.4</span>
                    </li>
                    <li>
                        24-Aug-23 02:03 PM <span className='ps-17 fw-bold'>Article 2.3</span>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default PublishingHistory
