import React, { useEffect, useMemo, useState } from 'react'
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import { useNavigate } from 'react-router-dom';
import { LIST_DETAIL_ELEMENTS, BASE_URL } from 'constants';
import { displayNotification, generateOptionsFromData, hasPermissionForAquaticCode, hasPermissionForAquaticManual, hasPermissionForTerrestrialCode, hasPermissionForTerrestrialManual, hasPermissionsForEN, hasPermissionsForES, hasPermissionsForFR, sendAPIRequest } from 'helpers';
import { CODE_VOLUMES_AGAINST_AREA_NUMBER } from 'constants';
import { TERRESTRIAL_MANUAL_VOLUMES_AGAINST_AREA_NUMBER } from 'constants';
import { AQUATIC_MANUAL_VOLUMES_AGAINST_AREA_NUMBER } from 'constants';
import { setCurrentPage } from 'redux/generalSlice';
import PopupAlert from 'components/common/PopupAlert';
import usePopupAlert from 'hooks/usePopupAlert';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'components/spinner/Spinner';
import StandardsSelectCard from 'components/common/StandardsSelectCard';
import StructureNavigationComponent from '../../StructureNavigationComponent';
import Container from 'components/common/Container';
import CustomCard from 'components/common/CustomCard';
import CardHeader from 'components/common/CardHeader';
import CardBody from 'components/common/CardBody';
import Button from 'components/common/Button';

const Draft = () => {

  const [componentLanguage, setComponentLanguage] = useState(LIST_DETAIL_ELEMENTS.languages.english);
  const authData = useSelector(s => s.auth?.data);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const headerTheme = useSelector((s) => s.general.headerTheme);
  const [section, setSelectedSection] = useState({
    value: '',
    label: ''
  });
  const [chapter, setSelectedChapter] = useState({
    value: '',
    label: ''
  });
  const [sections, setSections] = useState([]);
  const [codesSectionsGroups, setCodesSectionsGroups] = useState({});
  const [sectionID, setSectionID] = useState('');
  const [chapterID, setChapterID] = useState('');
  const [currentSection, setCurrentSection] = useState([]);
  const [currentChapter, setCurrentChapter] = useState([]);
  const [availableChapters, setAvailableChapters] = useState([]);
  const [selectedOption, setSelectedOption] = useState('terrestrial-code');
  const [isLoading, setIsLoading] = useState({
    loading_filters: false,
    loading_data: false,
    saving: false,
    subHeading: false,
  });
  const [selectedRadio, setSelectedRadio] = useState({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard: LIST_DETAIL_ELEMENTS.standard_type.codes });
  const [currentVolumeGroup, setCurrentVolumeGroup] = useState(CODE_VOLUMES_AGAINST_AREA_NUMBER);
  const [subHeading, setSelectedSubHeading] = useState({
    value: '',
    label: ''
  })
  const [availableSubHedings, setAvailableSubHeadings] = useState([]);
  const { showPopup, popupDetail, showPopupAlert, handleClose } = usePopupAlert();
  const [metaData, setMetaData] = useState(null);

  useEffect(() => {
    dispatch(setCurrentPage('administration'));
    setSelectedOption('terrestrial-code');
    loadSections();
  }, []);

  useEffect(() => {
    setAvailableChapters([]);
    setSelectedChapter({ value: '', label: '' });
    loadSections();
    loadChapters();
    if (selectedOption === 'terrestrial-manual') {
      setCurrentVolumeGroup(TERRESTRIAL_MANUAL_VOLUMES_AGAINST_AREA_NUMBER);
    } else if (selectedOption === 'aquatic-manual') {
      setCurrentVolumeGroup(AQUATIC_MANUAL_VOLUMES_AGAINST_AREA_NUMBER);
    } else {
      setCurrentVolumeGroup(CODE_VOLUMES_AGAINST_AREA_NUMBER);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (section.value) {
      try {
        loadChapters();
      } catch (error) {
        console.error('Error loading chapters:', error);
      }
    }
    else {
      setAvailableChapters([]);
      setSelectedChapter({ value: '', label: '' });
    }
  }, [section.value]);

  useEffect(() => {
    if (chapter.value) {
      try {
        loadSubHeadings()
      } catch (error) {
        console.error('Error loading chapters:', error);
      }
    }
    else {
      setAvailableSubHeadings([]);
      setSelectedSubHeading({ value: '', label: '' });
    }
  }, [chapter.value]);

  useEffect(() => {
    switchLanguage();
  }, [componentLanguage]);

  const changeComponentLanguage = async (lang) => {
    setComponentLanguage(lang);
  }

  const switchLanguage = async () => {
    try {
      const sect = await loadSections();
      const s_id = getSectionID(section.value, sect);
      if (s_id) {
        const chap = await loadChapters(s_id);
        getChapterID(chapter.value, chap);
      } else {
        await loadChapters();
      }
    } catch (err) {
      console.error(err);
    }
  }

  const setCodesSectionsGroupsList = (sect = []) => {
    let obj = {};
    sect.forEach(section => {
      obj[section.id] = section.display_name;
    });
    setCodesSectionsGroups(obj);
  }

  const getSectionID = (unique_name = null, sect = null) => {
    const tempSections = sect ? sect : sections;
    const s_id = tempSections.filter(section => section.unique_name === unique_name)[0];
    setSectionID(s_id?.id);
    return s_id?.id;
  }

  const getChapterID = (ref_number = null, chap = null, sect = null) => {
    const chapters = chap ? chap : availableChapters;
    let c_id;
    if (sect) {
      c_id = chapters.filter(chapter => chapter.ref_number === ref_number && chapter.parent_id === sect)[0];
    } else {
      c_id = chapters.filter(chapter => parseInt(chapter.ref_number) === parseInt(ref_number))[0];
    }
    setChapterID(c_id?.id);
    return c_id?.id;
  }

  const handleChange = async (event) => {
    switch (event.target.value) {
      case 'terrestrial-code':
        setSelectedRadio({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard: LIST_DETAIL_ELEMENTS.standard_type.codes });
        break;
      case 'terrestrial-manual':
        setSelectedRadio({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard: LIST_DETAIL_ELEMENTS.standard_type.manuals });
        break;
      case 'aquatic-code':
        setSelectedRadio({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.aquatic, standard: LIST_DETAIL_ELEMENTS.standard_type.codes });
        break;
      case 'aquatic-manual':
        setSelectedRadio({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.aquatic, standard: LIST_DETAIL_ELEMENTS.standard_type.manuals });
        break;
      default:
        return '';
    }
    setSelectedOption(event.target.value);
    setSelectedSection({ value: "", label: '' });
    setSelectedChapter({ value: "", label: '' });
    setSectionID('')
    setChapterID('')
  };

  // TODO // generalHeaderText

  const getHeaderText = () => {
    switch (selectedOption) {
      case 'terrestrial-code':
        return t("136", 'Terrestrial Animal Health Code');
      case 'terrestrial-manual':
        return t('137', 'Manual of Diagnostic Tests and Vaccines for Terrestrial Animals');
      case 'aquatic-code':
        return t('138', 'Aquatic Animal Health Code');
      case 'aquatic-manual':
        return t('139', 'Manual of Diagnostic Tests for Aquatic Animals');
      default:
        return '';
    }
  };

  const modifyData = (data, modiferText) => {
    let temp = [];
    data.forEach(d => {
      if (d.ref_number === 0) {
        d.children && d.children.length > 0 && d.children.forEach(child => {
          temp.push({ ...child, standard_content: `${child.standard_content}` });
        });
      } else {
        temp.push(d);
      }
    });
    return temp;
  }

  const loadSections = async () => {
    setIsLoading({ ...isLoading, load_sections: true });
    try {
      const ret = await sendAPIRequest(`/api/contents/generic-sections/?language_id=${componentLanguage}&animal_type=${selectedRadio.animal_type}&standard_type=${selectedRadio.standard}`);
      const res = await ret.json();
      if (res.success && res.status === 200) {
        const sect = modifyData(res.data, 'Section');
        setSections(sect);
        setCodesSectionsGroupsList(res.data);
        setIsLoading({ ...isLoading, load_sections: false });
        return sect;
      }
    } catch (e) {
      console.log({ e });
      setIsLoading({ ...isLoading, load_sections: false });
    }
    setIsLoading({ ...isLoading, load_sections: false });
  }

  const loadChapters = async (id = null) => {
    setIsLoading({ ...isLoading, load_chapters: true });
    try {
      const ret = await sendAPIRequest(`/api/contents/generic-chapters/?language_id=${componentLanguage}&section_id=${id ? id : sectionID ? sectionID : ''}&animal_type=${selectedRadio.animal_type}&standard_type=${selectedRadio.standard}`)
      const res = await ret.json();
      if (res.success && res.status === 200) {
        for (let ele of res.data) {
          ele.standard_content = removeHtmlTags(ele.standard_content)
        }
        setAvailableChapters(res.data);
        setIsLoading({ ...isLoading, load_chapters: false });
        return res.data;
      }
    } catch (e) {
      console.log({ e });
    }
    setIsLoading({ ...isLoading, load_chapters: false });
  }

  const loadSubHeadings = async (id = null) => {
    setIsLoading({ ...isLoading, subHeading: true });
    try {
      const ret = await sendAPIRequest(`/api/contents/chapter-subsections/?language_id=${componentLanguage}&chapter_id=${id ? id : chapterID ? chapterID : ''}&animal_type=${selectedRadio.animal_type}&standard_type=${selectedRadio.standard}`)
      const res = await ret.json();
      if (res.success && res.status === 200) {
        if (selectedRadio.standard === LIST_DETAIL_ELEMENTS.standard_type.manuals) {
          const modify = handleModifySubHeading(res.data);
          setAvailableSubHeadings(modify);
          return modify;
        } else {
          setAvailableSubHeadings(res.data);
          setIsLoading({ ...isLoading, subHeading: false });
          return res.data;
        }
      }
    } catch (error) {

      console.log({ error });
    }
    setIsLoading({ ...isLoading, subHeading: false });
  }
  const removeHtmlTags = (input) => {
    return input.replace(/<[^>]+>/g, '');
  }
  
  const handleSaveAsDraft = async () => {
    try {
      if (!window.confirm('Are you sure you want to save this chapter as draft?')) {
        return
      }
      // if (!metaData?.standard_type || !metaData?.animal_type || !metaData?.section?.section_no || !metaData?.section?.section_name || !metaData?.chapter?.chapter_no || !metaData?.chapter?.chapter_name
      if (!metaData?.standard_type || !metaData?.animal_type ||   (metaData?.section?.section_no === undefined || metaData?.section?.section_no === null) || !metaData?.section?.section_name || !metaData?.chapter?.chapter_no || !metaData?.chapter?.chapter_name
        || !metaData?.language_id
      ) {
        displayNotification('info', 'Please fill all fields!');
        return;
      }


      setIsLoading({ ...isLoading, saving: true });
      const info = {
        chapter_id: metaData?.chapter?.id,
        standard_type: metaData?.standard_type,
        animal_type: metaData?.animal_type,
        area_vol_no: metaData?.volume?.area_vol_no,
        area_vol_name: metaData?.volume?.area_vol_name,
        section_no: metaData?.section?.section_no,
        section_name: metaData?.section?.section_name,
        chapter_no: metaData?.chapter?.chapter_no,
        chapter_name: metaData?.chapter?.chapter_name,
        subsection_id: metaData?.sub_section?.id ?? '',
        lang_id: metaData?.language_id,
        RID: metaData?.chapter?.RID,
      };
      const ret = await sendAPIRequest(
        `/api/draft-chapter`,
        'POST',
        info
      );
      const res = await ret.json();
      if (res.success && res.status === 200) {
        const isCode = metaData?.standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes;
        // showPopupAlert('Success', `Successfully saved chapter as draft [x].</br>Go to<a target="_blank" href="${BASE_URL}/portal/draft/edit/${res.data.id}/?is_code=${isCode}"> editing</a>.`, 'success');
        displayNotification('success', 'Successfully saved chapter as draft [x].');
        // navigate(``)
        // window.open(`/portal/draft/edit/${res.data.id}/?is_code=${isCode}`, '_blank'); 
        navigate(`/portal/draft/edit/${res.data.id}/?is_code=${isCode}`)
      } else {
        displayNotification('error', res.message);
      }
      setIsLoading({ ...isLoading, saving: false });
    } catch (err) {
      displayNotification('error', 'Failed to save chapter as draft');
      setIsLoading({ ...isLoading, saving: false });
    }
  }

  const sectionOptions = useMemo(() => {
    return generateOptionsFromData(sections, {
      value: "unique_name",
      label: "display_name",
      group_value: 'group_name'
    },
      false,
      true,
      currentVolumeGroup
    );
  }, [sections, currentVolumeGroup]);

  const chapterOptions = useMemo(() => {
    return generateOptionsFromData(availableChapters, {
      value: "ref_number",
      label: "display_name",
      group_value: 'parent_id',
    },
      false,
      true,
      codesSectionsGroups
    );
  }, [availableChapters, codesSectionsGroups]);

  const subHeadingOptions = useMemo(() => {
    return generateOptionsFromData(availableSubHedings, {
      value: "id",
      label: "standard_content",
    },
    );
  }, [availableSubHedings]);

  const handleModifySubHeading = (data = []) => {
    if (data.length === 0) {
      return data;
    } else {
      for (let x of data) {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = x.standard_content;
        let tmp = tempElement.textContent;
        x.standard_content = tmp.trim();
      }
      return data = data.sort((a, b) => a.standard_content - b.standard_content);
    }
  }

  return (
    <Container>
      <PopupAlert show={showPopup} variant={popupDetail.variant} title={popupDetail.title} body={popupDetail.body} handleClose={handleClose} />
      <CustomCard className={'d-flex justify-content-between'}>
        <CardHeader>
          {t("-1", "Save As Draft")}
        </CardHeader>
        <CardBody>
          <Button
            onClick={() => navigate('/portal/draft/index')}
          >
            {t("-1", "Back")}
          </Button>
        </CardBody>
      </CustomCard>
      <StructureNavigationComponent
        config={{
          is_display_standard_heading: true,
          is_display_structures: true,
          is_display_area: false,
          is_display_sections: true,
          is_display_chapters: true,
          is_display_subsections: true,
          is_display_languages: true,
          is_display_font_changer: false,
        }}
        reFetch={componentLanguage}
        reloadData={(state) => setMetaData(state)}
      />
      <CustomCard className={'d-flex justify-content-between'}>
        <CardBody>
          <Button
            disabled={isLoading.saving || (!hasPermissionForTerrestrialCode(authData) && !hasPermissionForTerrestrialManual(authData) && !hasPermissionForAquaticCode(authData) && !hasPermissionForAquaticManual(authData))}
            onClick={() => handleSaveAsDraft()}
          >
            {isLoading.saving ? t("-1", "Saving...") : t("-1", "Save")}
          </Button>
        </CardBody>
      </CustomCard>
    </Container>
  )
}

export default Draft


