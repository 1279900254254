import { GridActionsCellItem } from "@mui/x-data-grid";
import { displayNotification, sendAPIRequest } from "helpers";
import DataTable from "pages/components/DataTable";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentPage } from "redux/generalSlice";

const CommoditiesDatabase = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(setCurrentPage("administration"));
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/commodities-animals/get-search-commodities-database`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data);
            }
        } catch (err) {
            console.log({ err });
        }
        setIsLoading(false);
    }

    const handleDelete = async (id) => {
        try {
            if (!window.confirm('Are you sure you want to delete?')) {
                return;
            }
            const ret = await sendAPIRequest(`/api/commodities-animals/${id}`, 'DELETE');
            const res = await ret.json();
            if (res.success && res.status === 200) {
                await loadData();
                displayNotification('success', 'Successfully deleted data [B]');
            } else {
                displayNotification('error', 'Failed to delete data [B]');
            }
        } catch (err) {
            console.error({ err });
            displayNotification('error', 'Failed to delete data [F]');
        }
    }

    return (
        <div>
            <div className="container my-2">
                <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
                    <div className='d-flex justify-content-between w-100'>
                        <div className="card-title1 lh-1">{t("-1", "Commodities Database")}</div>
                        <button
                            className="btn btn--primary font-custom fs-6 ms-1"
                            onClick={() => navigate('/portal/commodities-database/create')}
                        >
                            {t("-1", "Add New")}
                        </button>
                    </div>
                </div>
                <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
                    <DataTable
                        getRowId={(row) => row.id}
                        isLoading={isLoading}
                        rows={data}
                        className="text-white bg-primary rounded-0"
                        columns={
                            [
                                {
                                    field: "commodity_group_name",
                                    headerName: t("-1", "Commodity Group"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "commodity_name",
                                    headerName: t("-1", "Commodity"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "animal_name",
                                    headerName: t("-1", "Animal"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "recommendation",
                                    headerName: t("-1", "Recommendation"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "status_type",
                                    headerName: t("-1", "Recommendation Status Type"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "disease",
                                    headerName: t("-1", "Disease"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "section",
                                    headerName: t("-1", "Section"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "chapter",
                                    headerName: t("-1", "Chapter"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "article",
                                    headerName: t("-1", "Article"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "actions",
                                    type: "actions",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    headerName: t("-1", "Actions"),
                                    getActions: (params) => {
                                        return [
                                            <GridActionsCellItem
                                                label={
                                                    "Edit"
                                                }
                                                key={0}
                                                showInMenu
                                                onClick={() => {
                                                    navigate(`/portal/commodities-database/${params.row.id}/edit`);
                                                }}
                                            />,
                                            <GridActionsCellItem
                                                label={
                                                    "Delete"
                                                }
                                                key={0}
                                                showInMenu
                                                onClick={() => {
                                                    handleDelete(params.row.id);
                                                }}
                                            />,
                                        ]
                                    }
                                },
                            ]
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default CommoditiesDatabase;