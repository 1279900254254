import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { displayNotification, sendAPIRequest, sortASC } from 'helpers';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import HtmlRenderer from 'components/HtmlRenderer';
import DraftLinkModal from 'components/modals/DraftLinkModal';
import Container from 'components/common/Container';
import CustomCard from 'components/common/CustomCard';
import CardHeader from 'components/common/CardHeader';
import CardBody from 'components/common/CardBody';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'components/text_editor/CustomCKEditor';
import Spinner from 'components/spinner/Spinner';
import Row from 'components/common/Row';
import Column from 'components/common/Column';
import { MANUAL_HEADINGS } from 'constants';
import Button from 'components/common/Button';
import ReactDOMServer from 'react-dom/server';
import { useDispatch } from 'react-redux';
import { setCurrentPage, setHeaderTheme } from 'redux/generalSlice';
import { SIDEBAR_HEADINGS } from 'constants';
import DynamicModal from 'components/DynamicModal';
import { cloneDeep } from 'lodash';
import SideBarComponent from './SideBarComponent';
import AutoSaveButton from 'components/common/AutoSaveButton';
import useDebounce from 'hooks/useDebounse';
import { Grid } from '@mui/material';
import AddArticleHeadingModal from 'components/modals/AddArticleHeadingModal';

function DraftPreview(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const isCode = searchParams.get('is_code') === 'true' ? true : false;
    const navigate = useNavigate()
    const { id } = useParams();
    const [chapterId, setChapterId] = useState(id);
    const [data, setData] = useState('');
    const [editorData, setEditorData] = useState('');
    const [draftLinkModalOpen, setDraftLinkModalOpen] = useState(false);
    const [editLinkId, setEditLinkId] = useState(0);
    const [dataToShow, setDataToShow] = useState('');
    const [currentSelected, setCurrentSelected] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [currentArticle, setCurrentArticle] = useState('');
    const [linkLists, setLinkLists] = useState([])
    const [linkingData, SetlinkingData] = useState(null);
    const [parentDetail, setParentDetail] = useState({});
    const [draftImages, setDraftImages] = useState([]);
    const [sideBarDataLength, setSideBarDataLength] = useState(0);
    const [sideBarData, setSideBarData] = useState([]);
    const [autoSaveEnabled, setAutoSaveEnabled] = useState(false);
    const debouncedValue = useDebounce(editorData, 60 * 1000);
    const [isLoading, setIsLoading] = useState({
        updating_data: false,
    });
    const [articleMetaData, setArticleMetaData] = useState({
        section_no: '',
        chapter_no: '',
        max_ref_number: '',
        article_name: '',
    });
    const [editAddArticleModal, setArticleModal] = useState(false);

    useEffect(() => {
        dispatch(setHeaderTheme('white'));
        dispatch(setCurrentPage("draft-management"));
    }, []);


    useEffect(() => {
        if (autoSaveEnabled && currentSelected) {
            handleUpdate();
        }
    }, [debouncedValue, autoSaveEnabled]);

    useEffect(() => {
        loadData();
        getDraftImages();
    }, [id]);

    useEffect(() => {
        getHTMLContent(data);
    }, [dataToShow]);

    useEffect(() => {
        if (currentArticle) {
            handleSubSectionSelection(currentArticle);
        }
    }, [currentArticle]);

    useEffect(() => {
        getLinks()
    }, [])

    useEffect(() => {
        replaceMatchingImageSrc()
    }, [editorData])

    useEffect(() => {
        if (linkingData != null && linkingData != 'backdropClick') {
            setEditorData(editorData.replace(linkingData.data.link_text, `<a href="chapter-linking/${linkingData.linking_data.id}">${linkingData.data.link_text}</a>`))
        }
    }, [linkingData])


    const getLinks = async () => {
        try {
            const ret = await sendAPIRequest(`/api/chapter-linking/?draft_id=${id}`);
            const res = await ret.json()
            if (res.success && res.status === 200) {
                setLinkLists(res.data);
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getHTMLContent = async () => {
        const htmlContent = GenerateContent();
        setEditorData(htmlContent);
    };

    const goBack = () => {
        navigate('/portal/draft/index');
    }

    const generateSideBarDataLength = (data = []) => {
        const d = data.filter(d => SIDEBAR_HEADINGS.includes(parseInt(d.text_type)));
        setSideBarDataLength(d.length);
        setSideBarData(d);
    }

    const loadData = async () => {
        try {
            const ret = await sendAPIRequest(`/api/draft-content/preview/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                let max_ref_number = 0;
                res.data.forEach(d => {
                    let temp_number = d.ref_number;
                    if (temp_number > max_ref_number) {
                        max_ref_number = temp_number;
                    }
                });
                const structure = res.data[0]?.draft_chapter;
                setArticleMetaData({
                    ...articleMetaData, max_ref_number: max_ref_number,
                    section_no: structure.section_no,
                    chapter_no: structure.chapter_no,
                    article_name: structure.lang_id === 102 ? 'Article' : structure.lang_id === 103 ? 'Article' : 'Artículo'
                });
                generateSideBarDataLength(res.data);
                setData(res.data);
            } else {
                displayNotification('error', 'Error loading data [B]');
            }
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Error loading data [F]');
        }
    }

    const getDraftImages = async () => {
        try {
            const ret = await sendAPIRequest(`/api/draft-images/?draft_chapter_id=${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setDraftImages(res.data);
                // getHTMLContent();
            } else {
                displayNotification('error', 'Error loading data [B]');
            }
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Error loading data [F]');
        }
    }

    const replaceMatchingImageSrc = async () => {
        const srcRegex = /src="([^"]+)"(?<!data:image\/)/g;
        setEditorData(editorData.replace(srcRegex, (match, capturedUrl) => {
            const imageUrl = capturedUrl; // Remove quotes from captured URL
            const lastSegment = imageUrl.split('/').pop();
            const matchingImage = draftImages.find(image => image.image_name === lastSegment);
            // Replace only if a matching URL is found
            return matchingImage ? `src="${matchingImage.url}"` : match;
        }))
    }

    const GenerateContent = () => {
        return ReactDOMServer.renderToStaticMarkup(
            <>
                {
                    dataToShow && dataToShow.length > 0 && dataToShow.map(d => (
                        (d.text_type === LIST_DETAIL_ELEMENTS.text_types.article_heading || MANUAL_HEADINGS.includes(d.text_type)) && (
                            <React.Fragment key={d.id}>
                                {d.draft_html_contents && d.draft_html_contents.length > 0 ? (
                                    d.draft_html_contents.map((child) => (
                                        <>
                                            <HtmlRenderer
                                                tag={"div"}
                                                htmlContent={child.content}
                                            />
                                        </>
                                    ))
                                ) : (
                                    d.children && d.children.length > 0 && d.children.map(child => (
                                        <div key={`general-${child.id}`} id={`${child.id}`}>
                                            {child.draft_html_contents && child.draft_html_contents.length > 0 && child.draft_html_contents.map((ch) => (
                                                <div
                                                    key={`gen-child-${ch.id}`}
                                                    className={`d-flex ${ch.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text ? 'justify-content-end pt-3' : ''}`}
                                                >
                                                    <div className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === child.text_type ? "text-justified" : 'justify-content-end'}>
                                                        <HtmlRenderer
                                                            tag={LIST_DETAIL_ELEMENTS.heading_types.general_heading === child.text_type ? "div" : "p"}
                                                            htmlContent={ch.content}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))
                                )}
                            </React.Fragment>
                        )
                    ))
                }
            </>
        );
    };

    const handleSubSectionSelection = (id) => {
        try {
            const currentData = data.filter(d => d.id === id);
            if (currentData) {
                setParentDetail(currentData[0].draft_chapter ?? {});
                setDataToShow(currentData);
                setCurrentSelected(id)
            }
        } catch (err) {
            console.log({ err });
        }
    }

    const handleRemove = async () => {
        try {
            if (!currentSelected) {
                displayNotification('info', 'Please select any subsection/article to remove.');
                setShowDeleteModal(false);
                return;
            }
            const ret = await sendAPIRequest(`/api/draft-content/remove-subsection/${currentSelected}`, 'DELETE');
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification("success", 'Successfully removed subsection/article [B]');
                await loadData();
            } else {
                displayNotification("error", 'Failed to remove subsection/article [B]');
            }
            setShowDeleteModal(false);
        } catch (err) {
            console.log({ err });
            displayNotification("error", 'Failed to remove subsection/article [F]');
            setShowDeleteModal(false);
        }
    }

    const handleUpdate = async () => {
        setIsLoading({ ...isLoading, updating_data: true });
        try {
            if (!currentSelected) {
                displayNotification('info', 'Please select any subsection/article to update.');
                setShowUpdateModal(false);
                return;
            }
            const ret = await sendAPIRequest(`/api/draft-content/update-content/${currentSelected}`, 'PUT', {
                html: editorData
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                await loadData();
                displayNotification('success', 'Successfully updated subsection/article [B]');
            } else {
                displayNotification('error', 'Failed to update subsection/article [B]');
                setIsLoading({ ...isLoading, updating_data: false });
            }
            setShowUpdateModal(false);
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Failed to update subsection/article [F]');
            setShowUpdateModal(false);
            setIsLoading({ ...isLoading, updating_data: false });
        }
    }

    const handleCodeSave = async (recommendation_type, article_text = 'Test', ref_number, recommendation_text) => {
        try {
            const new_ref_number = articleMetaData.max_ref_number + 1;
            const ret = await sendAPIRequest(`/api/draft-content/add-subsection`, 'POST', {
                standard_content: `<div class="article-class">${articleMetaData.article_name} ${articleMetaData.section_no}.${articleMetaData.chapter_no}.${new_ref_number}.</div>`,
                recommendation_type: recommendation_type,
                recommendation_text: `<p class="document-article-intitule recommendation-class">${recommendation_text}<br></p>`,
                ref_number: new_ref_number,
                draft_chapter_id: id,
                is_code: true,
            });
            const res = await ret.json();
            if (res.success && res.status === 201) {
                displayNotification('success', 'Successfully added new subsection/article [B]');
                await loadData();
                setArticleMetaData({
                    ...articleMetaData, max_ref_number: new_ref_number
                });
            } else {
                displayNotification('error', 'Failed add new subsection/article [B]');
            }
            setShowModal(false);
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Failed add new subsection/article [F]');
            setShowModal(false);
        }
    }

    const handleManualSave = async (text_type, standard_content) => {
        try {
            const ret = await sendAPIRequest(`/api/draft-content/add-subsection`, 'POST', {
                standard_content: `<p class="A0"><span>${standard_content}</span></p>`,
                text_type: text_type,
                draft_chapter_id: id,
            });
            const res = await ret.json();
            if (res.success && res.status === 201) {
                displayNotification('success', 'Successfully added new subsection/article [B]');
                await loadData();
            } else {
                displayNotification('error', 'Failed add new subsection/article [B]');
            }
            setShowModal(false);
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Failed add new subsection/article [F]');
            setShowModal(false);
        }
    }

    const handleUpdateCodeSubsection = async (recommendation_type, article_text, ref_number, recommendation_text) => {
        try {
            const ret = await sendAPIRequest(`/api/draft-content/update-subsection/${currentSelected}`, 'PUT', {
                subsection: {
                    standard_content: `<div class="article-class">${article_text}</div>`,
                    ref_number: ref_number
                },
                ...((recommendation_text && recommendation_type) ? {
                    child: {
                        standard_content: `<p class="document-article-intitule recommendation-class">${recommendation_text}<br></p>`,
                        text_type: recommendation_type
                    }
                } : {})
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully updated subsection/article [B]');
                await loadData();
            } else {
                displayNotification('error', 'Failed update subsection/article [B]');
            }
            setShowEditModal(false);
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Failed updatesubsection/article [F]');
            setShowEditModal(false);
        }
    }
    const handleAddArticleHeading = async (id = '', article_heading = '') => {
        try {
            const ret = await sendAPIRequest(`/api/draft-html-content/`, 'POST', handleSetBody(id, article_heading));
            const res = await ret.json();
            if (res.success && res.status === 201) {
                displayNotification('success', 'Successfully Added Article Heading [B]');
                await loadData();
            } else {
                displayNotification('error', 'Failed Adding Article Heading [B]');
            }
            setArticleModal(false);
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Failed Adding Article Heading [F]');
            setArticleModal(false);
        }
    }
    const handleSetBody = (id = '', article_heading = '') => {
        let obj = {};
        let articleSubHead = `<p class="objet-de-recommandation">${article_heading}</p>`
        obj = {
            content_id: id,
            text_type: 135,
            content: articleSubHead,
            tag_name: "",
            class_name: "",
            text_content: article_heading,
            is_ignore: false,
            offset: null
        }
    return obj;
    }
    const handleUpdateManualSubsection = async (text_type, standard_content) => {
        try {
            const ret = await sendAPIRequest(`/api/draft-content/update-subsection/${currentSelected}`, 'PUT', {
                subsection: {
                    standard_content: `<p class="A0"><span>${standard_content}</span></p>`,
                    text_type: text_type
                }
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully updated subsection/article [B]');
                await loadData();
            } else {
                displayNotification('error', 'Failed update subsection/article [B]');
            }
            setShowEditModal(false);
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Failed updatesubsection/article [F]');
            setShowEditModal(false);
        }
    }

    const handleNavigate = (x) => {
        const currentData = data.filter(d => d.id === x);
        if (currentData.length > 0) {
            const url = `/portal/draft/taging/?chapter_id=${chapterId ?? ""}&section_no=${currentData[0]?.draft_chapter?.section_no}&chapter_no=${currentData[0]?.draft_chapter?.chapter_no}&article_no=${currentData[0]?.ref_number}`;
            window.open(url, '_blank');
        }
    };

    const handleOpenLinkModal = (id) => {
        setEditLinkId(id)
        SetlinkingData(null)
        setDraftLinkModalOpen(true);
    };
    const handleCloseLinkModal = (event, linking_data = null) => {
        setDraftLinkModalOpen(false);
        SetlinkingData(null)
        getLinks()
    };

    const handleDeleteLinkList = async (id) => {
        let deleteConfirmation = window.confirm('Are you sure you want to delete this record?')
        if (!deleteConfirmation) return false
        const ret = await sendAPIRequest(`/api/chapter-linking/${id}`, 'DELETE')
        const res = await ret.json()
        if (res.success && res.status === 200) {
            getLinks()
            displayNotification('success', 'Link deleted successfully!')
        }
    }

    const updateContent = async (id, standard_content = '', ref_number = '', offset = '') => {
        try {
            const ret = await sendAPIRequest(
                `/api/draft-content/${id}`,
                'PUT',
                {
                    ...(
                        ref_number ? {
                            standard_content,
                            ref_number: ref_number
                        } : {
                            offset: offset
                        }
                    )
                }
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Content updated successfully [x]');
            } else {
                displayNotification('error', 'Failed to update content [x]');
            }
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Failed to update content');
        }
    }

    const handleMoveUp = async (index) => {
        let temp = cloneDeep(sideBarData);
        let newObject = {
            id: '',
            standard_content: '',
            ref_number: '',
            offset: '',
        };
        let previousObject = {
            id: '',
            standard_content: '',
            ref_number: '',
            offset: '',
        };
        if (isCode) {
            if (temp[index].ref_number >= 2) {
                const prev_ref_number = temp[index].ref_number;
                temp[index].ref_number = temp[index - 1].ref_number;
                temp[index - 1].ref_number = prev_ref_number;
                if (temp[index].text_type === LIST_DETAIL_ELEMENTS.text_types.article_heading) {
                    const prev_article_number = temp[index].standard_content;
                    temp[index].standard_content = temp[index - 1].standard_content;
                    temp[index - 1].standard_content = prev_article_number;
                }
                newObject = {
                    id: temp[index].id,
                    standard_content: temp[index].standard_content,
                    ref_number: temp[index].ref_number,
                };
                previousObject = {
                    id: temp[index - 1].id,
                    standard_content: temp[index - 1].standard_content,
                    ref_number: temp[index - 1].ref_number
                }
            }
        } else {
            if (index > 0 && temp[index].offset) {
                const prev_offset = temp[index].offset;
                temp[index].offset = temp[index - 1].offset;
                temp[index - 1].offset = prev_offset;
                newObject = {
                    id: temp[index].id,
                    offset: temp[index].offset,
                };
                previousObject = {
                    id: temp[index - 1].id,
                    offset: temp[index - 1].offset
                }
            }
        }
        setSideBarData(sortASC(temp, isCode ? 'ref_number' : 'offset'));
        await updateContent(newObject.id, newObject.standard_content, newObject.ref_number, newObject.offset);
        await updateContent(previousObject.id, previousObject.standard_content, previousObject.ref_number, previousObject.offset);
    }

    const handleMoveDown = async (index) => {
        let temp = cloneDeep(sideBarData);
        let newObject = {
            id: '',
            standard_content: '',
            ref_number: '',
            offset: '',
        };
        let previousObject = {
            id: '',
            standard_content: '',
            ref_number: '',
            offset: '',
        };
        if (isCode) {
            if (temp[index + 1] && temp[index + 1].ref_number) {
                const prev_ref_number = temp[index].ref_number;
                temp[index].ref_number = temp[index + 1].ref_number;
                temp[index + 1].ref_number = prev_ref_number;
                if (temp[index].text_type === LIST_DETAIL_ELEMENTS.text_types.article_heading) {
                    const prev_article_number = temp[index].standard_content;
                    temp[index].standard_content = temp[index + 1].standard_content;
                    temp[index + 1].standard_content = prev_article_number;
                }
                newObject = {
                    id: temp[index].id,
                    standard_content: temp[index].standard_content,
                    ref_number: temp[index].ref_number,
                };
                previousObject = {
                    id: temp[index + 1].id,
                    standard_content: temp[index + 1].standard_content,
                    ref_number: temp[index + 1].ref_number
                }
            }
        } else {
            if (temp[index + 1] && temp[index + 1].offset) {
                const prev_offset = temp[index].offset;
                temp[index].offset = temp[index + 1].offset;
                temp[index + 1].offset = prev_offset;
                newObject = {
                    id: temp[index].id,
                    offset: temp[index].offset,
                };
                previousObject = {
                    id: temp[index + 1].id,
                    offset: temp[index + 1].offset
                }
            }
        }
        setSideBarData(sortASC(temp, isCode ? 'ref_number' : 'offset'));
        await updateContent(newObject.id, newObject.standard_content, newObject.ref_number, newObject.offset);
        await updateContent(previousObject.id, previousObject.standard_content, previousObject.ref_number, previousObject.offset);
    }

    const handleConfirmationClose = () => {
        setShowModal(false);
        setShowEditModal(false);
        setShowDeleteModal(false);
        setShowUpdateModal(false);
    }
    const handleCloseModal = () => {
        setArticleModal(false)
    }

    const handleReturn = (id) => {
        setCurrentArticle(id);
    }

    const handleEdit = (id) => {
        setCurrentArticle(id);
        setShowEditModal(true);
    }
    const handleAddArticle = (id) => {
        setCurrentArticle(id);
        setArticleModal(true);
    }

    const handleDelete = (id) => {
        setCurrentArticle(id);
        setShowDeleteModal(true);
    }

    const handleTagging = (id) => {
        handleNavigate(id);
    }

    return (
        <>
            <DynamicModal show={showDeleteModal} mode='delete' handleClose={handleConfirmationClose} handleConfirm={handleRemove} isAddArtHeading={false} />
            <DynamicModal show={showUpdateModal} mode='update' handleClose={handleConfirmationClose} handleConfirm={handleUpdate} isLoading={isLoading} isAddArtHeading={false} />
            <DynamicModal show={showModal} handleClose={handleConfirmationClose} isCode={isCode} handleSave={isCode ? handleCodeSave : handleManualSave} isAddArtHeading={false} />
            <DynamicModal show={showEditModal} mode='edit' handleClose={handleConfirmationClose} isCode={isCode} handleSave={isCode ? handleUpdateCodeSubsection : handleUpdateManualSubsection} id={currentSelected} isAddArtHeading={false} />
            <AddArticleHeadingModal show={editAddArticleModal} mode='Add Article Heading' handleClose={handleCloseModal} handleSave={handleAddArticleHeading} id={currentSelected} />
            <Container>
                <CustomCard className={'d-flex justify-content-between'}>
                    <CardHeader>
                        {t("-1", "Edit Draft")} {data[0]?.draft_chapter?.chapter_name ? `-(${data[0]?.draft_chapter?.chapter_name})` : ""}
                    </CardHeader>
                    <CardBody>
                        <Button
                            onClick={goBack}
                        >
                            Go To Draft Management
                        </Button>
                    </CardBody>
                </CustomCard>
                <CustomCard className={'ps-0'}>
                    <CardBody className={'d-flex justify-content-between'}>
                        <div>
                            <Button className='' variant={'custom_primary'} sx={{ height: "3rem" }} onClick={() => setShowModal(true)}>
                                Add Article/Subsection
                            </Button>
                            <AutoSaveButton className='ms-3' saveState={autoSaveEnabled} returnData={(state) => setAutoSaveEnabled(state)} />
                        </div>
                        <div>
                            <Button className='mx-1' variant={'secondry'} sx={{ height: "4rem" }} onClick={() =>
                                window.open(`/portal/draft-chapter-preview/?chapter_id=${chapterId ?? ''}`, '_blank')
                            }>
                                Preview
                            </Button>
                            <Button className='mx-1' variant={'dark'} sx={{ height: "3rem" }} onClick={() => window.open(`/portal/draft/taging/chapter/create/?chapter_id=${chapterId ?? ''}`, '_blank')}>
                                Chapter Level Taging
                            </Button>
                        </div>
                    </CardBody>
                </CustomCard>
                <Row className={"mb-0 pb-0 g-0"}>
                    <Column
                        col={2}
                        className={'border border-secondary mx-0 px-0 bg-light'}
                        style={{
                            position: 'sticky',
                            top: 0,
                            height: '100vh',
                            zIndex: '999',
                            overflowY: 'auto',
                        }}
                    >
                        <SideBarComponent
                            data={sideBarData}
                            isCode={isCode}
                            showAction={true}
                            striptHtml={!isCode}
                            returnData={(id) => handleReturn(id)}
                            handleEdit={(id) => handleEdit(id)}
                            handleDelete={(id) => handleDelete(id)}
                            handleMoveUp={(index) => handleMoveUp(index)}
                            handleMoveDown={(index) => handleMoveDown(index)}
                            handleTagging={(id) => handleTagging(id)}
                            handleAddArticle={(id) => handleAddArticle(id)}
                        />
                    </Column>
                    <Column col={10}>
                        {data && data.length > 0 ? (
                            <CKEditor
                                className="rounded-0"
                                editor={ClassicEditor}
                                data={editorData}
                                onReady={(editor) => {
                                }}
                                onChange={(event, editor) => {
                                    setEditorData(editor.getData());
                                }}
                            />
                        ) : (
                            <Spinner message="Loading editor..." />
                        )}
                    </Column>
                    <Column col={12}>
                        <div className="row my-2 pt-2 w-100">
                            <div className="d-flex justify-content-end">
                                <Button
                                    onClick={() => handleOpenLinkModal(0)}
                                >
                                    Inter-Standard Linking
                                </Button>
                                <Button className="ms-1"
                                    variant="primary"
                                    onClick={() => setShowUpdateModal(true)}
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                        <div className="accordion  border-0" id="accordionExample">
                            <div className="accordion-item rounded-0">
                                <h1 className="accordion-header accordion__item-label" id="headingOne">
                                    <button
                                        className="accordion-button font-custom-4 fs-3 rounded-0 py-2"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="true"
                                        aria-controls="collapseTwo"
                                    >
                                        <span className="me-auto fw-bold">
                                            {t("-1", "Link ")}
                                        </span>
                                    </button>
                                </h1>
                                <div
                                    id="collapseTwo"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    {linkLists.map((l, j) => (
                                        <div
                                            key={l.id}
                                            className={[
                                                "accordion-body",
                                                "border-start",
                                                "border-4",
                                                "mt-1",
                                                "pe-4",
                                                "ps-0",
                                                "w-100",
                                                ...(j % 2
                                                    ? ["bg-light-accordion", "border-light-accordion"]
                                                    : ["bg-light-accordion-2", "border-light-accordion-2"]),
                                            ].join(" ")}
                                        >
                                            <div className="row g-0 font-custom-4 fs-3 text-dark ps-4 w-100">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        {++j}. {l.link_text}
                                                    </div>
                                                    <div>
                                                        <span className="badge badge-danger ms-2 p-2 cursor-pointer" onClick={() => handleDeleteLinkList(l.id)}>
                                                            <i className="text-white fa-solid fa-trash-can pe-2"></i>
                                                            {t("-1", "Delete")}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Column>
                </Row>
            </Container>
            <DraftLinkModal
                open={draftLinkModalOpen}
                handleClose={handleCloseLinkModal}
                draft_id={id}
                currentArticleId={currentArticle}
                editLinkId={editLinkId}
            />
        </>
    )
}

export default DraftPreview