import { Tooltip } from "@mui/material";
import TextFieldCustom from "components/TextFieldCustom";
import AutoCompleteDropdown from "components/autocomplete/AutoCompleteDropdown";
import { displayNotification, generateOptionsFromData, sendAPIRequest } from "helpers";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setCurrentPage } from "redux/generalSlice";

const CommoditiesDatabaseForm = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const initForm = {
        commodity_group_id: '',
        commodity_id: '',
        animal_id: '',
        recommendation_id: '',
        status_type_id: '',
        disease_id: '',
        section_id: '',
        chapter_id: '',
        article_id: ''
    }

    const [form, setForm] = useState(initForm);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [commodityGroups, setCommodityGroups] = useState([]);
    const [commodities, setCommodities] = useState([]);
    const [animals, setAnimals] = useState([]);
    const [recommendations, setRecommendations] = useState([]);
    const [recommendationStatusType, setRecommendationStatusType] = useState([]);
    const [diseases, setDiseases] = useState([]);

    useEffect(() => {
        dispatch(setCurrentPage("administration"));
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        try {
            if (id) {
                await getCommodityData();
            }
            await getCommodityGroups();
            await getCommodities();
            await getAnimals();
            await getDiseases();
            await getRecommendations();
            await getRecommendationsStatusType();
        } catch (err) {
            console.error({ err });
        }
        setIsLoading(false);
    }

    const getCommodityData = async () => {
        try {
            const ret = await sendAPIRequest(`/api/commodities-animals/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                const temp = {
                    commodity_group_id: res.data.commodity_group_id,
                    commodity_id: res.data.commodity_id,
                    animal_id: res.data.animal_id,
                    recommendation_id: res.data.recommendation_id,
                    status_type_id: res.data.status_type_id,
                    disease_id: res.data.disease_id,
                    section_id: res.data.section_id,
                    chapter_id: res.data.chapter_id,
                    article_id: res.data.article_id
                };
                setForm(temp);
            }
        } catch (err) {
            console.error({ err });
        }
    }

    const getCommodityGroups = async () => {
        try {
            const ret = await sendAPIRequest(`/api/commoditiy-groups`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setCommodityGroups([...res.data]);
            } else {
                setCommodityGroups([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }
    const getCommodities = async () => {
        try {
            const ret = await sendAPIRequest(`/api/commdities`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setCommodities([...res.data]);
            } else {
                setCommodities([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }
    const getAnimals = async () => {
        try {
            const ret = await sendAPIRequest(`/api/animals`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setAnimals([...res.data]);
            } else {
                setAnimals([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }
    const getDiseases = async () => {
        try {
            const ret = await sendAPIRequest(`/api/lists/master/3`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setDiseases([...res.data]);
            } else {
                setDiseases([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }
    const getRecommendations = async () => {
        try {
            const ret = await sendAPIRequest(`/api/lists/master/5`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setRecommendations([...res.data]);
            } else {
                setRecommendations([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }
    const getRecommendationsStatusType = async () => {
        try {
            const ret = await sendAPIRequest(`/api/lists/master/6`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setRecommendationStatusType([...res.data]);
            } else {
                setRecommendationStatusType([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }

    const handleSave = async () => {
        setIsSaving(true);
        // if (!form.commodity_group_id || !form.commodity_id || !form.animal_id || !form.disease_id || !form.recommendation_id || !form.status_type_id || !form.section_id || !form.chapter_id || !form.article_id) {
        if (!form.commodity_group_id ||  !form.animal_id || !form.disease_id || !form.recommendation_id || !form.status_type_id || !form.section_id || !form.chapter_id || !form.article_id) {
            displayNotification('info', 'Please fill in all fields');
            setIsSaving(false);
            return;
        }
        try {
            if (!window.confirm('Are you sure you want to perform operation?')) {
                return;
            }
            let ret;
            if (id) {
                ret = await sendAPIRequest(`/api/commodities-animals/${id}`, 'PUT', form);
            } else {
                ret = await sendAPIRequest(`/api/commodities-animals`, 'POST', form);
            }
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully completed request [B]');
                setTimeout(() => {
                    navigate(-1);
                }, 2000);
            } else {
                displayNotification('error', 'Failed to complete request [B]');
            }
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Failed to complete request [F]');
        }
        setIsSaving(false);
    }

    const commoditiyGroupsOptions = useMemo(() => {
        return generateOptionsFromData(commodityGroups, {
            value: "id",
            label: "commodity_group_name",
        });
    }, [commodityGroups]);
    const commoditiesOptions = useMemo(() => {
        return generateOptionsFromData(commodities, {
            value: "id",
            label: "commodity_name",
        });
    }, [commodities]);
    const animalsOptions = useMemo(() => {
        return generateOptionsFromData(animals, {
            value: "id",
            label: "animal_name",
        });
    }, [animals]);
    const diseasesOptions = useMemo(() => {
        return generateOptionsFromData(diseases, {
            value: "id",
            label: "list_element_name",
        });
    }, [diseases]);
    const recommendationsOptions = useMemo(() => {
        return generateOptionsFromData(recommendations, {
            value: "id",
            label: "list_element_name",
        });
    }, [recommendations]);
    const recommendationsStatusTypeOptions = useMemo(() => {
        return generateOptionsFromData(recommendationStatusType, {
            value: "id",
            label: "list_element_name",
        });
    }, [recommendationStatusType]);

    return (
        <div className="container my-2">
            <div className="header card-woah border border-secondary d-flex px-4 py-2 my-2 shadow-sm w-100">
                <div className='d-flex justify-content-between w-100'>
                    <div className="card-title1 lh-1">{t("-1", "Commodities Database Form")}</div>
                    <button
                        className="btn btn--primary font-custom fs-6 ms-1"
                        onClick={() => navigate(-1)}
                    >
                        {t("-1", "Go Back")}
                    </button>
                </div>
            </div>

            <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
                <div className="row w-100">
                    <div className="col-md-4 col-12 mt-3">
                        <AutoCompleteDropdown
                            className={isLoading ? 'opacity-50' : 'opacity-100'}
                            disabled={isLoading}
                            label={isLoading ? 'loading...' : t("-1", "Commodity Groups")}
                            showClearIcon={true}
                            error={false}
                            required={true}
                            multiple={false}
                            hasselectall={false}
                            options={commoditiyGroupsOptions}
                            onChange={(event, value) => {
                                setForm({ ...form, commodity_group_id: value.value });
                            }}
                            value={
                                !form.commodity_group_id
                                    ? { value: '', label: '' }
                                    : commoditiyGroupsOptions.find((r) => parseInt(r.value) === parseInt(form.commodity_group_id)) ?? { value: '', label: '' }
                            }
                            size='small'
                        />
                    </div>
                    <div className="col-md-4 col-12 mt-3">
                        <AutoCompleteDropdown
                            className={isLoading ? 'opacity-50' : 'opacity-100'}
                            disabled={isLoading}
                            label={isLoading ? 'loading...' : t("-1", "Commodities")}
                            showClearIcon={true}
                            error={false}
                            required={true}
                            multiple={false}
                            hasselectall={false}
                            options={commoditiesOptions}
                            onChange={(event, value) => {
                                setForm({ ...form, commodity_id: value.value });
                            }}
                            value={
                                !form.commodity_id
                                    ? { value: '', label: '' }
                                    : commoditiesOptions.find((r) => parseInt(r.value) === parseInt(form.commodity_id)) ?? { value: '', label: '' }
                            }
                            size='small'
                        />
                    </div>
                    <div className="col-md-4 col-12 mt-3">
                        <AutoCompleteDropdown
                            className={isLoading ? 'opacity-50' : 'opacity-100'}
                            disabled={isLoading}
                            label={isLoading ? 'loading...' : t("-1", "Animals")}
                            showClearIcon={true}
                            error={false}
                            required={true}
                            multiple={false}
                            hasselectall={false}
                            options={animalsOptions}
                            onChange={(event, value) => {
                                setForm({ ...form, animal_id: value.value });
                            }}
                            value={
                                !form.animal_id
                                    ? { value: '', label: '' }
                                    : animalsOptions.find((r) => parseInt(r.value) === parseInt(form.animal_id)) ?? { value: '', label: '' }
                            }
                            size='small'
                        />
                    </div>
                    <div className="col-md-4 col-12 mt-3">
                        <AutoCompleteDropdown
                            className={isLoading ? 'opacity-50' : 'opacity-100'}
                            disabled={isLoading}
                            label={isLoading ? 'loading...' : t("-1", "Diseases")}
                            showClearIcon={true}
                            error={false}
                            required={true}
                            multiple={false}
                            hasselectall={false}
                            options={diseasesOptions}
                            onChange={(event, value) => {
                                setForm({ ...form, disease_id: value.value });
                            }}
                            value={
                                !form.disease_id
                                    ? { value: '', label: '' }
                                    : diseasesOptions.find((r) => parseInt(r.value) === parseInt(form.disease_id)) ?? { value: '', label: '' }
                            }
                            size='small'
                        />
                    </div>
                    <div className="col-md-4 col-12 mt-3">
                        <AutoCompleteDropdown
                            className={isLoading ? 'opacity-50' : 'opacity-100'}
                            disabled={isLoading}
                            label={isLoading ? 'loading...' : t("-1", "Recommendations")}
                            showClearIcon={true}
                            error={false}
                            required={true}
                            multiple={false}
                            hasselectall={false}
                            options={recommendationsOptions}
                            onChange={(event, value) => {
                                setForm({ ...form, recommendation_id: value.value });
                            }}
                            value={
                                !form.recommendation_id
                                    ? { value: '', label: '' }
                                    : recommendationsOptions.find((r) => parseInt(r.value) === parseInt(form.recommendation_id)) ?? { value: '', label: '' }
                            }
                            size='small'
                        />
                    </div>
                    <div className="col-md-4 col-12 mt-3">
                        <AutoCompleteDropdown
                            className={isLoading ? 'opacity-50' : 'opacity-100'}
                            disabled={isLoading}
                            label={isLoading ? 'loading...' : t("-1", "Recommendations Status Type")}
                            showClearIcon={true}
                            error={false}
                            required={true}
                            multiple={false}
                            hasselectall={false}
                            options={recommendationsStatusTypeOptions}
                            onChange={(event, value) => {
                                setForm({ ...form, status_type_id: value.value });
                            }}
                            value={
                                !form.status_type_id
                                    ? { value: '', label: '' }
                                    : recommendationsStatusTypeOptions.find((r) => parseInt(r.value) === parseInt(form.status_type_id)) ?? { value: '', label: '' }
                            }
                            size='small'
                        />
                    </div>
                    <div className="col-md-4 col-12 mt-3 pe-0">
                        <TextFieldCustom
                            variant="outlined"
                            className={`${isLoading} ? 'opacity-50' : 'opacity-100'`}
                            disabled={isLoading}
                            label={isLoading ? 'loading...' : t("-1", "Section")}
                            id="section-id"
                            type='number'
                            fullWidth
                            required={true}
                            size='small'
                            sx={{
                                backgroundColor: "white",
                                color: "white",
                                border: "#ff4815",
                                width: "95%",
                            }}
                            value={form.section_id}
                            onChange={(e) =>
                                setForm({ ...form, section_id: e.target.value })
                            }
                        />
                    </div>
                    <div className="col-md-4 col-12 mt-3 pe-0">
                        <TextFieldCustom
                            variant="outlined"
                            className={`${isLoading} ? 'opacity-50' : 'opacity-100'`}
                            disabled={isLoading}
                            label={isLoading ? 'loading...' : t("-1", "Chapter")}
                            id="chapter-id"
                            type='number'
                            fullWidth
                            required={true}
                            size='small'
                            sx={{
                                backgroundColor: "white",
                                color: "white",
                                border: "#ff4815",
                                width: "95%",
                            }}
                            value={form.chapter_id}
                            onChange={(e) =>
                                setForm({ ...form, chapter_id: e.target.value })
                            }
                        />
                    </div>
                    <div className="col-md-4 col-12 mt-3 pe-0">
                        <TextFieldCustom
                            variant="outlined"
                            className={`${isLoading} ? 'opacity-50' : 'opacity-100'`}
                            disabled={isLoading}
                            label={isLoading ? 'loading...' : t("-1", "Article")}
                            id="article-id"
                            type='number'
                            fullWidth
                            required={true}
                            size='small'
                            sx={{
                                backgroundColor: "white",
                                color: "white",
                                border: "#ff4815",
                                width: "95%",
                            }}
                            value={form.article_id}
                            onChange={(e) =>
                                setForm({ ...form, article_id: e.target.value })
                            }
                        />
                    </div>
                    <div className="col-md-12 col-12 text-end my-3">
                        <button
                            className="btn btn--primary font-custom fs-6"
                            onClick={() => {
                                handleSave();
                            }}
                            disabled={isLoading || isSaving}
                        >
                            {
                                id ? "Update" : "Save"
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CommoditiesDatabaseForm;
