import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import CardHeader from 'components/common/CardHeader'
import Container from 'components/common/Container'
import CustomCard from 'components/common/CustomCard'
import { TERRESTRIAL_MANUAL_VOLUMES_AGAINST_AREA_NUMBER } from 'constants'
import { AQUATIC_MANUAL_VOLUMES_AGAINST_AREA_NUMBER } from 'constants'
import { CODE_VOLUMES_AGAINST_AREA_NUMBER } from 'constants'
import { LIST_DETAIL_ELEMENTS } from 'constants'
import { generateOptionsFromData, sendAPIRequest } from 'helpers'
import AutoCompleteDropdown from 'pages/components/autoComplete/AutoCompleteDropdown'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setCurrentPage } from 'redux/generalSlice'
import ListsComponent from './ListsComponent'

const StyleLists = () => {
    const { t } = useTranslation();
    const siteLanguage = useSelector(s => s.general.siteLanguageSont);
    const [componentLanguage, setComponentLanguage] = useState('102');

    const navigate = useNavigate();
    const [section, setSelectedSection] = useState({
        value: '',
        label: ''
    });
    const [chapter, setSelectedChapter] = useState({
        value: '',
        label: ''
    });
    const [sections, setSections] = useState([]);
    const [codesSectionsGroups, setCodesSectionsGroups] = useState({});
    const [sectionID, setSectionID] = useState('');
    const [chapterID, setChapterID] = useState('');
    const [currentSection, setCurrentSection] = useState([]);
    const [currentChapter, setCurrentChapter] = useState([]);
    const [is_clicked, setIsClicked] = useState(false);
    const [availableChapters, setAvailableChapters] = useState([]);
    const [availableManualChapters, setAvailableManualChapters] = useState([]);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const isSectionVisible = useSelector(s => s.expand.isSection);
    const isSectionSecVisible = useSelector(s => s.expand.isSectionSec);
    const [selectedOption, setSelectedOption] = useState('terrestrial-code');
    const dispatch = useDispatch();
    const [baseFontSize, setBaseFontSize] = useState(16);
    const [docIdForZeroSections, setDocIdForZeroSections] = useState('')
    const [isLoading, setIsLoading] = useState({
        loading_filters: false,
        loading_data: false,
        load_chapters: false,
        load_sections: false,
    });
    const [generatingPDF, setGeneratingPDF] = useState(false);
    const [disableChapterField, setDisableChapterField] = useState(false);
    const currentDate = new Date().toISOString().slice(0, 10);
    const [selectedRadio, setSelectedRadio] = useState({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard: LIST_DETAIL_ELEMENTS.standard_type.codes });
    const [chapterMetaData, setChapterMetaData] = useState({ ref_number: '', id: '', structures_id: '' });
    const [sectionMetaData, setSectionMetaData] = useState({ ref_number: '', id: '', volume_no: '' });
    const [currentVolumeGroup, setCurrentVolumeGroup] = useState(CODE_VOLUMES_AGAINST_AREA_NUMBER);
    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(setCurrentPage('tools'));
        setSelectedOption('terrestrial-code');
        loadSections();
    }, []);

    useEffect(() => {
        setAvailableChapters([]);
        setSelectedChapter({ value: '', label: '' });
        setDisableChapterField(false);
        loadSections();
        loadChapters();
        if (selectedOption === 'terrestrial-manual') {
            setCurrentVolumeGroup(TERRESTRIAL_MANUAL_VOLUMES_AGAINST_AREA_NUMBER);
        } else if (selectedOption === 'aquatic-manual') {
            setCurrentVolumeGroup(AQUATIC_MANUAL_VOLUMES_AGAINST_AREA_NUMBER);
        } else {
            setCurrentVolumeGroup(CODE_VOLUMES_AGAINST_AREA_NUMBER);
        }
    }, [selectedOption]);

    useEffect(() => {
        if (section.value) {
            try {
                loadChapters();
            } catch (error) {
                console.error('Error loading chapters:', error);
            }
        }
        else {
            setAvailableChapters([]);
            setSelectedChapter({ value: '', label: '' });
        }
    }, [section.value]);

    useEffect(() => {
        switchLanguage();
    }, [componentLanguage]);

    useEffect(() => {
        if (chapterID) {
            getData();
        }
    }, [chapterID]);

    const getData = async () => {
        try {
            const ret = await sendAPIRequest(`/api/html-contents/clean-manual-lists/${chapterID}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data);
            }
        } catch (err) {
            console.log({ err });
        }
    }


    const switchLanguage = async () => {
        try {
            const sect = await loadSections();
            const s_id = getSectionID(section.value, sect);
            if (s_id) {
                const chap = await loadChapters(s_id);
                getChapterID(chapter.value, chap);
            } else {
                await loadChapters();
            }
        } catch (err) {
            console.error(err);
        }
    }

    const setCodesSectionsGroupsList = (sect = []) => {
        let obj = {};
        sect.forEach(section => {
            obj[section.id] = section.display_name;
        });
        setCodesSectionsGroups(obj);
    }

    const getSectionID = (unique_name = null, sect = null) => {
        const tempSections = sect ? sect : sections;
        const s_id = tempSections.filter(section => section.unique_name === unique_name)[0];
        if (s_id) {
            setSectionMetaData({ id: s_id?.id, ref_number: s_id?.ref_number, volume_no: s_id?.volume_no });
        }


        setSectionID(s_id?.id);
        return s_id?.id;
    }

    const getChapterID = (ref_number = null, chap = null, sect = null) => {
        const chapters = chap ? chap : availableChapters;
        let c_id;
        if (sect) {
            c_id = chapters.filter(chapter => chapter.group_name === ref_number && chapter.parent_id === sect)[0];
        } else {
            c_id = chapters.filter(chapter => chapter.group_name === ref_number)[0];
        }
        setChapterMetaData({ id: c_id?.id, ref_number: c_id?.ref_number, structures_id: c_id?.structures });
        setChapterID(c_id?.id);
        return c_id?.id;
    }

    const handleChange = async (event) => {
        switch (event.target.value) {
            case 'terrestrial-code':
                setSelectedRadio({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard: LIST_DETAIL_ELEMENTS.standard_type.codes });
                break;
            case 'terrestrial-manual':
                setSelectedRadio({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard: LIST_DETAIL_ELEMENTS.standard_type.manuals });
                break;
            case 'aquatic-code':
                setSelectedRadio({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.aquatic, standard: LIST_DETAIL_ELEMENTS.standard_type.codes });
                break;
            case 'aquatic-manual':
                setSelectedRadio({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.aquatic, standard: LIST_DETAIL_ELEMENTS.standard_type.manuals });
                break;
            default:
                return '';
        }
        setSelectedOption(event.target.value);
        setDocIdForZeroSections('')
        setSelectedSection({ value: "", label: '' });
        setSelectedChapter({ value: "", label: '' });
        setCodesSectionsGroups({})
        setSections([])
        setSectionID('')
        setChapterID('')
    };

    const modifyData = (data, modiferText) => {
        let temp = [];
        data.forEach(d => {
            if (d.ref_number === 0) {
                d.children && d.children.length > 0 && d.children.forEach(child => {
                    temp.push({ ...child, standard_content: `${child.standard_content}` });
                });
            } else {
                temp.push(d);
            }
        });
        return temp;
    }

    const loadSections = async () => {
        setIsLoading({ ...isLoading, load_sections: true });
        try {
            const ret = await sendAPIRequest(`/api/contents/generic-sections/?language_id=${componentLanguage}&animal_type=${selectedRadio.animal_type}&standard_type=${selectedRadio.standard}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                const sect = modifyData(res.data, 'Section');
                setSections(sect);
                setCodesSectionsGroupsList(res.data);
                setIsLoading({ ...isLoading, load_sections: false });
                return sect;
            }
        } catch (e) {
            console.log({ e });
            setIsLoading({ ...isLoading, load_sections: false });
        }
    }

    const loadChapters = async (id = null) => {
        setIsLoading({ ...isLoading, load_chapters: true });
        try {
            const ret = await sendAPIRequest(`/api/contents/generic-chapters/?language_id=${componentLanguage}&section_id=${id ? id : sectionID ? sectionID : ''}&animal_type=${selectedRadio.animal_type}&standard_type=${selectedRadio.standard}`)
            const res = await ret.json();
            if (res.success && res.status === 200) {
                for (let ele of res.data) {
                    ele.standard_content = removeHtmlTags(ele.standard_content)
                }
                setAvailableChapters(res.data);
                setIsLoading({ ...isLoading, load_chapters: false });
                return res.data;
            }
        } catch (e) {
            console.log({ e });
        }
        setIsLoading({ ...isLoading, load_chapters: false });
    }

    const sectionOptions = useMemo(() => {
        return generateOptionsFromData(sections, {
            value: "unique_name",
            label: "display_name",
            group_value: 'group_name'
        },
            false,
            true,
            currentVolumeGroup
        );
    }, [sections, currentVolumeGroup]);

    const chapterOptions = useMemo(() => {
        return generateOptionsFromData(availableChapters, {
            value: "group_name",
            label: "display_name",
            group_value: 'parent_id',
        },
            false,
            true,
            codesSectionsGroups
        );
    }, [availableChapters, codesSectionsGroups]);

    const changeComponentLanguage = async (lang) => {
        setComponentLanguage(lang);
    }
    function removeHtmlTags(input) {
        return input.replace(/<[^>]+>/g, '');
    }

    return (
        <>
            <Container>
                <CustomCard>
                    <CardHeader>
                        {t("-1", "Editing Tool")}
                    </CardHeader>
                </CustomCard>
                <CustomCard className={'my-2'}>
                    <Grid p={1} justifyContent='center'
                        display="flex" alignItems='center'
                        border="1px solid LightGray"
                        borderRadius='4px'
                        bgcolor='#F4F4F4'>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                className="text-center d-flex justify-content-between"
                                onChange={handleChange}
                                value={selectedOption}
                            >
                                <FormControlLabel
                                    sx={{
                                        paddingX: '20px',
                                    }}
                                    value="terrestrial-code"
                                    control={<Radio size="small" />}
                                    label={t("67", "Terrestrial Code​")}
                                />
                                <FormControlLabel
                                    sx={{
                                        paddingX: '20px',
                                    }}
                                    value="terrestrial-manual"
                                    control={<Radio size="small" />}
                                    label={t("68", "Terrestrial Manual​")}
                                />
                                <FormControlLabel
                                    sx={{
                                        paddingX: '20px',
                                    }}
                                    value="aquatic-code"
                                    control={<Radio size="small" />}
                                    label={t("69", "Aquatic Code​")}
                                />
                                <FormControlLabel
                                    sx={{
                                        paddingX: '20px',
                                    }}
                                    value="aquatic-manual"
                                    control={<Radio size="small" />}
                                    label={t("60", "Aquatic Manual​")}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid >
                    <div className="row d-flex my-3">
                        <div className="col-md-6">
                            <Box sx={{ minWidth: 120 }}>
                                <AutoCompleteDropdown
                                    label={isLoading.load_sections ? 'loading...' : selectedOption === 'terrestrial-code' || selectedOption === 'aquatic-code' ? t("119", "Area/Section") : t("120", "Part/Section")}
                                    className={isLoading.load_sections ? 'opacity-50' : 'opacity-100'}
                                    disabled={isLoading.load_sections}
                                    isGroup={true}
                                    showClearIcon={true}
                                    error={false}
                                    required={true}
                                    multiple={false} 
                                    hasselectall={false}
                                    options={sectionOptions}
                                    onChange={(event, value) => {
                                        setSelectedChapter({
                                            value: "",
                                            label: "",
                                            group_value: ""
                                        })
                                        setCurrentChapter([])
                                        setSelectedSection({ value: value?.value, label: value?.label });
                                        const isChapter = value?.value?.split('.');
                                        setChapterID("");
                                        if (isChapter && isChapter.length < 3) {
                                            setDisableChapterField(false);
                                        } else {
                                            const filteredSection = sections.filter(section => section.unique_name === value?.value)[0];
                                            setChapterID(filteredSection?.id);
                                            setCurrentSection(filteredSection);
                                            setDisableChapterField(true);
                                        }
                                        getSectionID(value?.value);
                                    }}
                                    value={
                                        sectionOptions.find(r => r.value == section?.value) ?? { label: '', value: '' }
                                    }
                                    size='small'
                                />
                            </Box>
                        </div>
                        <div className="col-md-6">
                            {section !== 'glossary' && section !== 'foreward' && section !== 'userguide'
                                && (
                                    <Box sx={{ minWidth: 120 }}>
                                        <AutoCompleteDropdown
                                            className={isLoading.load_chapters ? 'opacity-50' : 'opacity-100'}
                                            disabled={isLoading.load_chapters}
                                            label={isLoading.load_chapters ? 'loading...' : t("23", "Chapter")}
                                            showClearIcon={true}
                                            isGroup={true}
                                            error={false}
                                            required={false}
                                            multiple={false}
                                            hasselectall={false}
                                            options={chapterOptions}
                                            onChange={(event, value) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                const sect = sectionOptions.filter(sec => sec.label?.toLowerCase() === value?.group_value?.toLowerCase())[0] ?? { value: '', label: '', group_value: '' }
                                                const sectID = getSectionID(sect.value?.split('.')[1]);
                                                setSelectedSection(sect);
                                                const chapter_number = value?.value.split('.');
                                                setSelectedChapter({
                                                    value: value?.value,
                                                    label: value?.label,
                                                    group_value: value?.group_value
                                                });
                                                getChapterID(value?.value, null, sectID);
                                                if (chapter_number && chapter_number.length > 0) {
                                                    setCurrentChapter(availableChapters.filter(chapter => chapter.ref_number === parseInt(chapter_number[2]) && chapter.parent_id === sectID)[0]);
                                                }
                                            }}
                                            value={chapterOptions.find(r => r.value === chapter?.value) ?? { value: '', label: '' }}
                                            size='small'
                                        />
                                    </Box>
                                )}
                        </div>
                    </div>
                </CustomCard>

                {
                    data && data.length > 0 && data.map(d => 
                        <ListsComponent
                            list={d}
                        />
                    )
                    
                }

            </Container>
        </>
    )
}

export default StyleLists
