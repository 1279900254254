import { Badge, Divider, List, ListItem, ListItemButton, ListItemText, Popover, Tooltip, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { displayNotification, getBaseURL, getFormattedDate, sendAPIRequest } from 'helpers';
import { BsBell } from 'react-icons/bs';

const Notifications = ({ className }) => {

    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const [data, setData] = useState([]);

    const handleClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'notification-popover' : undefined;

    useEffect(() => {
        // loadData();
        // const loader = setInterval(loadData, 5 * 1000);
        // return () => clearInterval(loader);
    }, []);

    const loadData = async () => {
        try {
            const req = await sendAPIRequest(`/api/notifications`);
            const res = await req.json();
            if (res.success) {
                setData(res.data);
            } else {
                displayNotification('error', `${t("960", "Error loading notifications")} [B]`);
            }
        } catch (e) {
            console.log(e);
            displayNotification('error', `${t("960", "Error loading notifications")} [F]`);
        }
    };

    const readNotification = async (id) => {
        try {
            const req = await sendAPIRequest(
                `/api/notifications/${id}`,
                'POST'
            );
            const res = await req.json();
            if (res.success) {
                await loadData();
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <Tooltip title={t("715", "Notifications")}>
                <a
                    href="#"
                    className={`me-2 h-15px d-inline-block ${className}`}
                    onClick={handleClick}
                >
                    <Badge
                        className='align-top'
                        variant="dot"
                        badgeContent={data.filter(r => !r.is_read).length}
                        color="primary"
                    >
                        <BsBell className="align-top text-dark" />
                    </Badge>
                </a>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiPopover-paper': {
                        width: 300
                    }
                }}
            >
                <Typography sx={{ p: 1, textAlign: 'center' }}>
                    <h4 className='text-dark mb-0'>
                        {t("715", "Notifications")}
                    </h4>
                </Typography>
                <List
                    sx={{
                        width: '100%',
                        maxWidth: 300,
                        bgcolor: 'var(--theme-light)',
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 300,
                        paddingBottom: 0,
                        '& .MuiDivider-inset': { margin: 0 },
                        '& .MuiListItemText-root': { margin: 0 },
                        '& .MuiTypography-root': { margin: 0 },
                    }}
                    subheader={<li />}
                >
                    {
                        data.length > 0 ? (
                            data.map(noti => (
                                <Fragment key={`notification-${noti.id}`}>
                                    <ListItemButton
                                        sx={{ p: 0, width: '100%' }}
                                        selected={!noti.is_read}
                                        onClick={() => readNotification(noti.id)}
                                    >
                                        <ListItem className='d-block'>
                                            <ListItemText primary={noti.content} />
                                            <em className='d-flex flex-end fs-9'>{getFormattedDate(noti.createdAt, 'DD-MMM-YYYY (hh:mm A) UTC')}</em>
                                        </ListItem>
                                    </ListItemButton>
                                    <Divider variant="inset" component="li" />
                                </Fragment>
                            ))
                        ) : (
                            <>
                                <ListItem sx={{ width: '100%' }}>
                                    <ListItemText
                                        primary={t("959", "No Notifications Available")}
                                        sx={{ textAlign: 'center' }}
                                    />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </>
                        )
                    }
                </List>
            </Popover>
        </>
    );
}

Notifications.defaultProps = {
    className: ''
};

export default Notifications;
