import React, { useRef } from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

const CustomDropdownMenu = (props) => {

    const { t } = useTranslation();
    const siteLanguage = useSelector(s => s.general.siteLanguageSont);
    const linkRef = useRef();
    const bodyRef = useRef();

    const [topMargin, setTopMargin] = useState('0');

    useEffect(() => {
        if (document.getElementById('headerEndMarker')) {
            setTopMargin(window.pageYOffset + document.getElementById('headerEndMarker').getBoundingClientRect().top);
            setTimeout(() => {
                if (document.getElementById('headerEndMarker')) {
                    setTopMargin(window.pageYOffset + document.getElementById('headerEndMarker').getBoundingClientRect().top);
                }
            }, 1000);
        }
    }, []);

    useEffect(() => {
		window.removeEventListener("scroll", handleWindowScroll);
		window.addEventListener("scroll", handleWindowScroll, { passive: true });
		return () => window.removeEventListener("scroll", handleWindowScroll);
	}, []);

	const handleWindowScroll = () => {
        linkRef.current?.classList.remove('show');
        bodyRef.current?.classList.remove('show');
    };

    return (
        <>
            {props.children(linkRef)}
            <div
                ref={bodyRef}
                className="dropdown-menu bg-dropdown-menu rounded-0 border-bottom border-5 border-primary"
                style={{
                    top: topMargin,
                    left: 0,
                    right: 0
                }}
            >
                <div className="">
                    <div className="row py-7">
                        <div className="col-md-6 col-sm-6 col-12">
                            <div className="caption ps-7 text-dropdown-gold">
                                <h1 className=" font-custom-2 fs-b border-bottom border-2 border-dark pb-5 text-dropdown-gold">
                                    {props.menuName}
                                </h1>
                                <div className="paragraph pt-5">
                                    <p className='fs-5 text-justify text-dark'>
                                        {props.menuText}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12 border-start border-2 border-dark">
                            <ul className="list-group text-white list-unstyled">
                                {props.menuLinks.map(r => (
                                    <li className='my-3' key={r.route}>
                                        <Link to={r.route} className="text-dark font-custom fs-2 fw-bold text-hover-gold">{r.label}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

CustomDropdownMenu.defaultProps = {
    children: '',
    menuName: '',
    menuText: '',
    menuLinks: [],
    isOpen: false,
};

export default CustomDropdownMenu
