import collect from 'collect.js';
import HtmlRenderer from 'components/HtmlRenderer';
import Spinner from 'components/spinner/Spinner';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import { displayNotification, sendAPIRequest } from 'helpers';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useRef } from 'react'
import { Divider, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { setCurrentPage, setHeaderTheme } from 'redux/generalSlice';
import { Box } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import Container from "components/common/Container";


function ChapterData() {

    const dispatch = useDispatch();
    const [location, setLocation] = useSearchParams();
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [currentTerm, setCurrentTerm] = useState(0);
    const [currentElement, setCurrentElement] = useState('');
    const [count, setCount] = useState(0);
    const [highlightedData, setHighlightedData] = useState(data);
    const { chapter_id, article_id, structure_id, ismanual, rid, componentLanguage } = {
        structure_id: location.get('structure'),
        chapter_id: location.get('chapter'),
        article_id: location.get('article'),
        ismanual: location.get('ismanual') === "true" ? true : false,
        rid: location.get('rid'),
        componentLanguage: location.get('language')
    };
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState({
        loading_data: false,
    });
    const [isZeroSection, setIsZeroSection] = useState(false);
    const [documentMetaData, setDocumentMetaData] = useState({
        section_name: '',
        chapter_name: '',
        chapter_no: '',
        section_no: "",
        area_vol_no: ''
    });

    useEffect(() => {
        dispatch(setHeaderTheme('white'));
        dispatch(setCurrentPage('access-commodities'));
        getChapterData();
        if (document.getElementById(`${article_id}`)) {
            const headingElement = document.getElementById(`${article_id}`);
            if (headingElement) {
                const offsetTop = headingElement.offsetTop;
                window.scrollTo({
                    top: offsetTop - 50,
                    behavior: 'smooth'
                });
            }
        }
    }, []);

    useEffect(() => {
        if (document.getElementById(`${article_id}`)) {
            const headingElement = document.getElementById(`${article_id}`);
            if (headingElement) {
                const offsetTop = headingElement.offsetTop;
                window.scrollTo({
                    top: offsetTop - 50,
                    behavior: 'smooth'
                });
            }
        }
    }, [isLoading.loading_data]);

    const getChapterData = async () => {
        setIsLoading({ ...isLoading, loading_data: true });
        try {
            let ret;
            // const ret = await sendAPIRequest(`/api/contents/chapter/${document_id}/?content_type=html`);
            if (structure_id) {
                ret = await sendAPIRequest(`/api/contents/navigation-chapter-preview/?structures_id=${structure_id}&language_id=${componentLanguage}`);
            } else if (rid) {
                ret = await sendAPIRequest(`/api/contents/navigation-chapter-preview/?is_rid=true&RID=${rid}&language_id=${componentLanguage}`);
            } else {
                ret = await sendAPIRequest(`/api/contents/navigation-chapter-preview/?chapter=${chapter_id}&language_id=${componentLanguage}`);
            }
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data);
                const section = res.data.filter(d => d.text_type === LIST_DETAIL_ELEMENTS.text_types.section_name)[0]?.ref_number;

                if (section !== undefined && section !== null && section === 0) {
                    setIsZeroSection(true);
                }
                const chapter = res.data.filter(d => d.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name)[0]?.ref_number;
                let ty = res.data.find(d => d.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name);
                if (ty && ty.standards_structures_revised && ismanual) {
                    setDocumentMetaData(prevState => ({
                        ...prevState, section_no: ty.standards_structures_revised.section_no, area_vol_no: ty.standards_structures_revised.area_vol_no,
                        chapter_no: ty.standards_structures_revised.chapter_no
                    }));
                } else {
                    setDocumentMetaData(prevState => ({
                        ...prevState, section_name: section,
                        chapter_name: chapter,

                    }));
                }
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Failed to load chapter data [F]');
        }
        setIsLoading({ ...isLoading, loading_data: false });
    }

    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
    };

    const highlightSearchTerm = (htmlContent, search, currentIndex) => {
        if (typeof htmlContent !== 'string' || !search) return { highlightedContent: htmlContent, occurrences: 0 };
        const escapedSearch = escapeRegExp(search);
        const regex = new RegExp(`\\b(${escapedSearch})`, 'gi'); // Match whole words only
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        let occurrences = 0;
        const highlightNode = (node) => {
            Array.from(node.childNodes).forEach((childNode) => {
                if (childNode.nodeType === Node.TEXT_NODE) {
                    const parts = childNode.textContent.split(regex);
                    const fragment = document.createDocumentFragment();
                    parts.forEach((part, index) => {
                        const span = document.createElement('span');
                        if (regex.test(part)) {
                            span.style.backgroundColor = 'yellow';
                            span.textContent = part;
                            const uniqueId = `highlight_${currentIndex + occurrences + 1}`; // Generate unique ID
                            span.id = uniqueId;
                            if (occurrences === currentIndex) {
                            }
                            occurrences++;
                        } else {
                            span.textContent = part;
                        }
                        fragment.appendChild(span);
                    });
                    childNode.parentNode.replaceChild(fragment, childNode);
                } else if (childNode.nodeType === Node.ELEMENT_NODE) {
                    highlightNode(childNode);
                }
            });
        };
        highlightNode(doc.body);
        const serializer = new XMLSerializer();
        const highlightedHtml = serializer.serializeToString(doc);
        return { highlightedContent: highlightedHtml, occurrences };
    };

    useEffect(() => {
        if (!search) {
            setHighlightedData(data);
            setCount(0);
            return;
        }
        let totalCount = 0;
        let itemOccurrences = 0;
        const highlighted = data.map(item => {
            const highlightedItem = {};
            for (const key in item) {
                if (Object.hasOwnProperty.call(item, key)) {
                    if (key === 'html_contents') {
                        const highlightedContents = item[key].map(htmlContent => {
                            const { highlightedContent, occurrences } = highlightSearchTerm(htmlContent.content, search, itemOccurrences);
                            itemOccurrences += occurrences;
                            return {
                                ...htmlContent,
                                content: highlightedContent
                            };
                        });
                        highlightedItem[key] = highlightedContents;
                    } else if (key === 'child') {
                        const highlightedChild = {};
                        for (const childKey in item[key]) {
                            if (Object.hasOwnProperty.call(item[key], childKey)) {
                                if (childKey === 'html_contents') {
                                    const highlightedChildContents = item[key][childKey].map(htmlContent => {
                                        const { highlightedContent, occurrences } = highlightSearchTerm(htmlContent.content, search, itemOccurrences);
                                        itemOccurrences += occurrences;
                                        return {
                                            ...htmlContent,
                                            content: highlightedContent
                                        };
                                    });
                                    highlightedChild[childKey] = highlightedChildContents;
                                } else {
                                    const { highlightedContent, occurrences } = highlightSearchTerm(item[key][childKey], search, itemOccurrences);
                                    if (occurrences > 0) {
                                        itemOccurrences += occurrences;
                                        highlightedChild[childKey] = highlightedContent;
                                    } else {
                                        highlightedChild[childKey] = item[key][childKey];
                                    }
                                }
                            }
                        }
                        highlightedItem[key] = highlightedChild;
                    } else {
                        const { highlightedContent, occurrences } = highlightSearchTerm(item[key], search, itemOccurrences);
                        if (occurrences > 0) {
                            itemOccurrences += occurrences;
                            highlightedItem[key] = highlightedContent;
                        } else {
                            highlightedItem[key] = item[key];
                        }
                    }
                }
            }
            return highlightedItem;
        });
        totalCount += itemOccurrences;
        setHighlightedData(highlighted);
        setCount(totalCount);
    }, [search, data]);

    const handleClearSearch = () => {
        setSearch('');
    };
    const isInViewport = (element) => {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };
    
    return (

        <>
            <Container className="">
                <Box
                    sx={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: '#fff',
                        zIndex: 999,
                        paddingY: '30px'
                    }}
                >
                    <Grid container spacing={2} alignItems="center"
                    >
                        <Grid item xs={10}
                            sx={{
                                paddingTop: 0,
                                paddingBottom: '30px',
                            }}>
                            <TextField
                                required={false}
                                error={false}
                                fullWidth
                                size="small"
                                value={search}
                                onChange={(e) => (setSearch(e.target.value), setCurrentTerm(0))}
                                placeholder='Search content here...'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon
                                                style={{
                                                    fontSize: 'medium'
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {search.length > 0 && (
                                                <ClearIcon
                                                    onClick={handleClearSearch}
                                                    style={{
                                                        cursor: 'pointer',
                                                        fontSize: 'medium'
                                                    }}
                                                />
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    backgroundColor: '#fff',
                                    marginTop: '10px',
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}
                            sx={{
                                backgroundColor: '#fff',
                                paddingBottom: '20px',
                            }}>
                            {
                                search && search.length > 0 &&
                                <Grid sx={{ 'display': 'flex' }}>
                                    <span className='cursor-pointer mx-1'
                                        onClick={() => {
                                            const previousTerm = currentTerm - 1;
                                            if (previousTerm > 0) {
                                                const element = document.getElementById(`highlight_${previousTerm}`);
                                                if (currentElement) {
                                                    currentElement.style.backgroundColor = 'yellow';
                                                    currentElement.style.color = '';
                                                }
                                                if (element) {
                                                    setCurrentElement(element);
                                                    element.style.backgroundColor = '#ff4815';
                                                    element.style.color = '#fff';
                                                    if (!isInViewport(element)) {
                                                        const offset = 150;
                                                        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                                                        const offsetPosition = elementPosition - offset;
                                                        window.scrollTo({
                                                            top: offsetPosition,
                                                            behavior: 'smooth'
                                                        });
                                                    }
                                                }
                                                setCurrentTerm(previousTerm);
                                            }
                                        }}
                                    >
                                        <KeyboardArrowUp sx={{
                                            color: '#ff4815'
                                        }}
                                            fontSize="small"
                                        />
                                    </span>
                                    <span className='cursor-pointer mx-1'
                                        onClick={() => {
                                            const nextTerm = currentTerm + 1;
                                            if (nextTerm <= count) {
                                                const element = document.getElementById(`highlight_${nextTerm}`);
                                                if (currentElement) {
                                                    currentElement.style.backgroundColor = 'yellow';
                                                    currentElement.style.color = '';
                                                }
                                                if (element) {
                                                    setCurrentElement(element);
                                                    element.style.backgroundColor = '#ff4815';
                                                    element.style.color = '#fff';
                                                    if (!isInViewport(element)) {
                                                        const offset = 150;
                                                        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                                                        const offsetPosition = elementPosition - offset;
                                                        window.scrollTo({
                                                            top: offsetPosition,
                                                            behavior: 'smooth'
                                                        });
                                                    }
                                                }
                                                setCurrentTerm(nextTerm);
                                            }
                                        }}
                                    >
                                        <KeyboardArrowDown sx={{
                                            color: '#ff4815'
                                        }}
                                            fontSize="small"
                                        />
                                    </span>

                                    <Typography
                                        variant="body2"
                                    >
                                        {t("101", "Number of Matches:")} {count}
                                    </Typography>

                                </Grid>
                            }

                        </Grid>
                    </Grid>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    my={2}
                    width="100%"
                >
                    {
                        isLoading.loading_data ? (

                            <Box sx={{ textAlign: 'center' }}>
                                <Spinner message={t('98', 'Loading Data, Please wait...')} />
                            </Box>
                        ) : (
                            <Box px={2} pb={2}>
                                {
                                    highlightedData && highlightedData.length > 0 ? (
                                        highlightedData.map((d, index) => (
                                            <Box key={`key-${index}`}>
                                                {
                                                    d.text_type === LIST_DETAIL_ELEMENTS.text_types.section_name && !isZeroSection
                                                    && (
                                                        <Box key={`section-${d.id}`}>
                                                            <Typography
                                                                textAlign="center"
                                                                pt={2}
                                                                style={{
                                                                    letterSpacing: '2px',
                                                                    textTransform: 'uppercase'
                                                                }}
                                                                key={d.id}
                                                            >
                                                                {/* {t("65", "Section")} {documentMetaData.section_name} */}
                                                                {
                                                                    ismanual ?
                                                                        `${t('65', 'Section')} ${documentMetaData.area_vol_no}.${documentMetaData.section_no}.` :
                                                                        `${t('65', 'Section')} ${documentMetaData.section_name}.`
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                className='chapter-heading'
                                                                textAlign="center"
                                                                py={0}
                                                                style={{
                                                                    letterSpacing: '2px',
                                                                    textTransform: 'uppercase'
                                                                }}
                                                                key={`name-${d.id}`}
                                                            >
                                                                <HtmlRenderer
                                                                    tag={'span'}
                                                                    className="fs-6"
                                                                    htmlContent={d.standard_content}
                                                                />
                                                            </Typography>
                                                            <Divider
                                                                variant="middle"
                                                                sx={{
                                                                    width: '50%',
                                                                    margin: 'auto',
                                                                    marginTop: '16px',
                                                                    marginBottom: '6px',
                                                                    backgroundColor: '#000',
                                                                }}
                                                            />
                                                            <Divider
                                                                variant="middle"
                                                                sx={{
                                                                    width: '50%',
                                                                    margin: 'auto',
                                                                    backgroundColor: '#000',
                                                                }}
                                                            />
                                                        </Box>
                                                    )
                                                }
                                                {
                                                    d.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name
                                                    && (
                                                        <Box key={`chapter-${d.id}`}>
                                                            <Typography
                                                                my={0}
                                                                py={0}
                                                                textAlign="center"
                                                                p={3}
                                                                style={{
                                                                    letterSpacing: '2px',
                                                                    textTransform: 'uppercase'
                                                                }}
                                                                key={d.id}
                                                            >
                                                                {/* {t("23", "CHAPTER")} {documentMetaData.section_name}.{documentMetaData.chapter_name}. */}
                                                                {
                                                                    isZeroSection ? "" : ismanual ?
                                                                        `${t("33", "Chapter")} ${documentMetaData.area_vol_no}.${documentMetaData.section_no}.${documentMetaData.chapter_no}.` :
                                                                        `${t("33", "Chapter")} ${documentMetaData.section_name}.${documentMetaData.chapter_name}.`
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                // variant="h5"
                                                                textAlign="center"
                                                                style={{
                                                                    letterSpacing: '2px',
                                                                    textTransform: 'uppercase'
                                                                }}
                                                                key={`name-${d.id}`}
                                                                className='chapter-heading'
                                                            >
                                                                <HtmlRenderer
                                                                    tag={'p'}
                                                                    className="document-chapitre-intitule"
                                                                    htmlContent={d.standard_content}
                                                                />
                                                            </Typography>
                                                        </Box>
                                                    )
                                                }
                                                {
                                                    d.text_type === LIST_DETAIL_ELEMENTS.text_types.article_heading
                                                    && (
                                                        <Box
                                                            key={`${d.id}`}
                                                            id={`${d.id}`}
                                                        >
                                                            <HtmlRenderer
                                                                tag={'div'}
                                                                className="text-center py-4 fs-6"
                                                                htmlContent={d.standard_content}
                                                            />
                                                            {
                                                                d.html_contents && d.html_contents.length > 0 ? d.html_contents.map(child =>
                                                                    <Box
                                                                        className='px-4 text-justified'
                                                                        key={`art-child-${child.id}`}
                                                                    >
                                                                        <HtmlRenderer
                                                                            tag={'p'}
                                                                            className=''
                                                                            htmlContent={child.content}
                                                                            style={{
                                                                                fontSize: '1rem'
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                ) : (
                                                                    d.child && Object.keys(d.child).length > 0 && (
                                                                        d.child.text_type === LIST_DETAIL_ELEMENTS.heading_types.general_heading
                                                                            ? (
                                                                                <Box
                                                                                    key={`general-${d.child.id}`}
                                                                                    id={`${d.child.id}`}
                                                                                >
                                                                                    <HtmlRenderer
                                                                                        tag={'div'}
                                                                                        className=" document-article-intitule"
                                                                                        htmlContent={d.child.standard_content}
                                                                                    />
                                                                                    {
                                                                                        d.child.html_contents && d.child.html_contents.length > 0 &&
                                                                                        d.child.html_contents.map((child, i) =>
                                                                                            <Box
                                                                                                key={`gen-child-${child.id}`}
                                                                                                className={`d-flex ${child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && 'justify-content-center'}`}
                                                                                            >
                                                                                                <Box
                                                                                                    className='text-justified'
                                                                                                >
                                                                                                    <HtmlRenderer
                                                                                                        tag={'p'}
                                                                                                        className=''
                                                                                                        htmlContent={child.content}
                                                                                                    />
                                                                                                </Box>
                                                                                            </Box>
                                                                                        )
                                                                                    }
                                                                                </Box>
                                                                            ) :
                                                                            <Box
                                                                                id={d.child.id}
                                                                                key={`recommendation-${d.child.id}`}
                                                                            >
                                                                                <HtmlRenderer
                                                                                    tag={'div'}
                                                                                    // className="py-4"
                                                                                    htmlContent={d.child.standard_content}
                                                                                />
                                                                                {
                                                                                    d.child.html_contents && d.child.html_contents.length > 0 &&
                                                                                    d.child.html_contents.map(child =>
                                                                                        <>
                                                                                            {child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && (
                                                                                                <Divider />
                                                                                            )}
                                                                                            <Box
                                                                                                key={`rec-child-${child.id}`}
                                                                                                className={child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text ? "text-justified" : 'justify-content-end'}>
                                                                                                {/* className={`d-flex ${child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && 'justify-content-center pb-7'}`}> */}
                                                                                                <Box className='text-justified'>
                                                                                                    <HtmlRenderer
                                                                                                        tag={'p'}
                                                                                                        className=''
                                                                                                        htmlContent={child.content}
                                                                                                    />
                                                                                                </Box>
                                                                                            </Box>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </Box>
                                                                    )
                                                                )
                                                            }
                                                        </Box>
                                                    )
                                                }
                                                {
                                                    (
                                                        d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_heading
                                                        || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_reference
                                                        || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_appendex
                                                        || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_biblography
                                                        || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_Further_reading
                                                        || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_online_resource
                                                        || d.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text
                                                        || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_footer
                                                        || d.text_type === LIST_DETAIL_ELEMENTS.text_types.glossary_term
                                                    )
                                                    && (
                                                        <Box
                                                            key={`${d.id}`}
                                                            id={`${d.id}`}
                                                        >
                                                            <HtmlRenderer
                                                                tag={'div'}
                                                                className="text-center py-4 fs-6"
                                                                htmlContent={d.standard_content}
                                                            />
                                                            {
                                                                d.html_contents && d.html_contents.length > 0 ? d.html_contents.map(child =>
                                                                    <Box
                                                                        className='px-4 text-justified'
                                                                        key={`art-child-${child.id}`}
                                                                    >
                                                                        <HtmlRenderer
                                                                            tag={'p'}
                                                                            className=''
                                                                            htmlContent={child.content}
                                                                            style={{
                                                                                fontSize: '1rem'
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                ) : (
                                                                    d.child && Object.keys(d.child).length > 0 && (
                                                                        d.child.text_type === LIST_DETAIL_ELEMENTS.heading_types.general_heading
                                                                            ? (
                                                                                <Box
                                                                                    key={`general-${d.child.id}`}
                                                                                    id={`${d.child.id}`}
                                                                                >
                                                                                    <HtmlRenderer
                                                                                        tag={'div'}
                                                                                        className=" document-article-intitule"
                                                                                        htmlContent={d.child.standard_content}
                                                                                    />
                                                                                    {
                                                                                        d.child.html_contents && d.child.html_contents.length > 0 &&
                                                                                        d.child.html_contents.map((child, i) =>
                                                                                            <Box
                                                                                                key={`gen-child-${child.id}`}
                                                                                                className={`d-flex ${child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && 'justify-content-center'}`}
                                                                                            >
                                                                                                <Box
                                                                                                    className='text-justified'
                                                                                                >
                                                                                                    <HtmlRenderer
                                                                                                        tag={'p'}
                                                                                                        className=''
                                                                                                        htmlContent={child.content}
                                                                                                    />
                                                                                                </Box>
                                                                                            </Box>
                                                                                        )
                                                                                    }
                                                                                </Box>
                                                                            ) :
                                                                            <Box
                                                                                id={d.child.id}
                                                                                key={`recommendation-${d.child.id}`}
                                                                            >
                                                                                <HtmlRenderer
                                                                                    tag={'div'}
                                                                                    // className="py-4"
                                                                                    htmlContent={d.child.standard_content}
                                                                                />
                                                                                {
                                                                                    d.child.html_contents && d.child.html_contents.length > 0 &&
                                                                                    d.child.html_contents.map(child =>
                                                                                        <>
                                                                                            {child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && (
                                                                                                <Divider />
                                                                                            )}
                                                                                            <Box
                                                                                                key={`rec-child-${child.id}`}
                                                                                                className={child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text ? "text-justified" : 'justify-content-end'}>
                                                                                                {/* className={`d-flex ${child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && 'justify-content-center pb-7'}`}> */}
                                                                                                <Box className='text-justified'>
                                                                                                    <HtmlRenderer
                                                                                                        tag={'p'}
                                                                                                        className=''
                                                                                                        htmlContent={child.content}
                                                                                                    />
                                                                                                </Box>
                                                                                            </Box>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </Box>
                                                                    )
                                                                )
                                                            }
                                                        </Box>
                                                    )
                                                }
                                            </Box>
                                        ))
                                    ) : (
                                        <Box p={10}>
                                            <Typography
                                                variant='h6'
                                            >
                                                {t("99", "No Data Found")}
                                            </Typography>
                                        </Box>
                                    )
                                }
                            </Box>
                        )
                    }
                </Box>

            </Container>

        </>
    )
}

export default ChapterData
