import { sendAPIRequest, stripHtml } from 'helpers';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import Spinner from "components/spinner/Spinner";
import { useTranslation } from 'react-i18next';

const AddArticleHeadingModal = ({ show, handleClose, handleSave, handleConfirm, isCode = null, mode = '', id = '' }) => {

    const [isLoading, setIsLoading] = useState({ updating_data: false });
    const [formState, setFormState] = useState({
        article_heading: ""
    });
    const [error, setError] = useState('');
    const { t } = useTranslation();

    const handleConfirmUpdate = async () => {
        setIsLoading({ updating_data: true });
        try {
            await handleConfirm();
        } finally {
            setIsLoading({ updating_data: false });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleCloseModal = () => {
        setError("");
        setFormState({
            article_heading: ""
        })
        handleClose();
    }

    const validateFields = useCallback(() => {
        const { article_heading } = formState;
        if (!article_heading) {
            setError('Fields cannot be empty');
            return false;
        }
        setError('');
        return true;
    }, [formState]);

    const saveHeading = useCallback(() => {
        if (validateFields()) {
            const { article_heading } = formState;
            handleSave(id, article_heading);
            setFormState({
                article_heading: ""
            });
            handleClose();
        }
    }, [formState,handleSave, validateFields, handleClose]);


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Article Heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {mode === 'articleheading' ? (
                    'Are you sure you want to update the sub-section/article?'
                ) : (
                    <>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form>
                            <Form.Group controlId="article_text">
                                <Form.Label>Article Heading Text</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="article_heading"
                                    value={formState.article_heading}
                                    onChange={handleInputChange}
                                    placeholder="Enter subsection text"
                                />
                            </Form.Group>
                        </Form>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={saveHeading}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

AddArticleHeadingModal.defaultProps = {
    show: true,
    handleClose: () => { },
    handleSave: () => { },
    handleConfirm: () => { },
    isCode: true,
    mode: '',
    id: null,
};

export default AddArticleHeadingModal;