// =========================NEw=============================
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Divider, Grid, IconButton, Tooltip, Typography, styled, tooltipClasses } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Articles from 'pages/frontend/pages-component/Articles';
import { AQUATIC_MANUAL_VOLUMES_AGAINST_AREA_NUMBER, CODE_VOLUMES_AGAINST_AREA_NUMBER, CONTENT_PDF_DOWNLOAD_API, CONTENT_PDF_GENERATE_API, LIST_DETAIL_ELEMENTS, TERRESTRIAL_MANUAL_VOLUMES_AGAINST_AREA_NUMBER } from 'constants';
import { setCurrentPage } from 'redux/generalSlice';
import { isSectionState, isSectionSecState } from 'redux/expandSlice';
import { displayNotification, generateOptionsFromData, getBaseURL, sendAPIRequest } from 'helpers';
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import StandardsSelectCard from 'components/common/StandardsSelectCard';
import Container from 'components/common/Container';
import CardHeader from 'components/common/CardHeader';
import CardBody from 'components/common/CardBody';
import Button from 'components/common/Button';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import CustomCard from 'components/common/CustomCard';
import StructureNavigationComponent from '../StructureNavigationComponent';
import useLocalStorage from "hooks/useLocalStorage";
import { document } from 'postcss';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#ffece8',
        color: '#ff4815',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        padding: '13px'
    },
}));

const ContentNavigation = () => {

    const { t } = useTranslation();
    const siteLanguage = useSelector(s => s.general.siteLanguageSont);
    const [componentLanguage, setComponentLanguage] = useState(siteLanguage);
    const headerTheme = useSelector((s) => s.general.headerTheme);

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [section, setSelectedSection] = useState({
        value: '',
        label: ''
    });
    const [chapter, setSelectedChapter] = useState({
        value: '',
        label: ''
    });
    const [sections, setSections] = useState([]);
    const [codesSectionsGroups, setCodesSectionsGroups] = useState({});
    const [sectionID, setSectionID] = useState('');
    const [chapterID, setChapterID] = useState('');
    const [currentSection, setCurrentSection] = useState([]);
    const [currentChapter, setCurrentChapter] = useState([]);
    const [is_clicked, setIsClicked] = useState(false);
    const [availableChapters, setAvailableChapters] = useState([]);
    const [availableManualChapters, setAvailableManualChapters] = useState([]);
    const isSectionVisible = useSelector(s => s.expand.isSection);
    const isSectionSecVisible = useSelector(s => s.expand.isSectionSec);
    const [selectedOption, setSelectedOption] = useState('terrestrial-code');
    const [baseFontSize, setBaseFontSize] = useState(16);
    const [docIdForZeroSections, setDocIdForZeroSections] = useState('')
    const [isLoading, setIsLoading] = useState({
        loading_filters: false,
        loading_data: false,
        load_chapters: false,
        load_sections: false,
    });
    const [generatingPDF, setGeneratingPDF] = useState(false);
    const [disableChapterField, setDisableChapterField] = useState(false);
    const currentDate = new Date().toISOString().slice(0, 10);
    const [selectedRadio, setSelectedRadio] = useState({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard: LIST_DETAIL_ELEMENTS.standard_type.codes });
    const [chapterMetaData, setChapterMetaData] = useState({ ref_number: '', id: '', structures_id: '' });
    const [sectionMetaData, setSectionMetaData] = useState({ ref_number: '', id: '', volume_no: '' });
    const [currentVolumeGroup, setCurrentVolumeGroup] = useState(CODE_VOLUMES_AGAINST_AREA_NUMBER[102]);
    const [metaData, setMetaData] = useState(null);
    const [reFetch, setRefetch] = useState(false);
    const [documentMetaData, setDocumentMetaData] = useState({
        section_name: '',
        chapter_name: '',
        chapter_no: '',
        section_no: "",
        area_vol_no: ''
    });
    const { setItem } = useLocalStorage('metadata');
    useEffect(() => {
        setDocumentMetaData(prevState => ({
            ...prevState,
            section_name: metaData?.chapter?.section_name,
            chapter_name: metaData?.chapter?.chapter_name,
            chapter_no: metaData?.chapter?.chapter_no,
            section_no: metaData?.chapter?.section_no,
            area_vol_no: metaData?.standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals ? metaData?.chapter?.area_vol_no : null,
        }));
    }, [metaData]);

    useEffect(() => {
        setItem(documentMetaData);
    }, [documentMetaData]);

    useEffect(() => {
        dispatch(setCurrentPage('display-navigation'));
        setSelectedOption('terrestrial-code');
        loadSections();
    }, []);

    useEffect(() => {
        setAvailableChapters([]);
        setSelectedChapter({ value: '', label: '' });
        setDisableChapterField(false);
        loadSections();
        loadChapters();
        setVolume();
    }, [selectedOption]);

    useEffect(() => {
        if (section.value) {
            try {
                loadChapters();
            } catch (error) {
                console.error('Error loading chapters:', error);
            }
        }
        else {
            setAvailableChapters([]);
            setSelectedChapter({ value: '', label: '' });
        }
    }, [section.value]);

    // useEffect(() => {
    //   switchLanguage();
    //   setVolume();
    // }, [componentLanguage]);

    const setVolume = () => {
        if (selectedOption === 'terrestrial-manual') {
            setCurrentVolumeGroup(TERRESTRIAL_MANUAL_VOLUMES_AGAINST_AREA_NUMBER[parseInt(componentLanguage)]);
        } else if (selectedOption === 'aquatic-manual') {
            setCurrentVolumeGroup(AQUATIC_MANUAL_VOLUMES_AGAINST_AREA_NUMBER[parseInt(componentLanguage)]);
        } else {
            setCurrentVolumeGroup(CODE_VOLUMES_AGAINST_AREA_NUMBER[parseInt(componentLanguage)]);
        }
    }


    const scrollToTop = () => {
        const element = document.getElementById(`topOfGrid`);
        element.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const scrollToTopButtonStyle = {
        position: 'fixed',
        bottom: '120px',
        right: '40px',
        zIndex: 1000,
        fontSize: '1.5rem'
    };

    const switchLanguage = async () => {
        try {
            const sect = await loadSections();
            const s_id = getSectionID(section.value, sect);
            if (s_id) {
                const chap = await loadChapters(s_id);
                getChapterID(chapter.value, chap);
            } else {
                await loadChapters();
            }
        } catch (err) {
            console.error(err);
        }
    }

    const setCodesSectionsGroupsList = (sect = []) => {
        let obj = {};
        sect.forEach(section => {
            obj[section.id] = section.display_name;
        });
        setCodesSectionsGroups(obj);
    }

    const getSectionID = (unique_name = null, sect = null) => {
        const tempSections = sect ? sect : sections;
        const s_id = tempSections.filter(section => section.unique_name === unique_name)[0];
        if (s_id) {
            setSectionMetaData({ id: s_id?.id, ref_number: s_id?.ref_number, volume_no: s_id?.volume_no });
        }
        setSectionID(s_id?.id);
        return s_id?.id;
    }

    const getChapterID = (ref_number = null, chap = null, sect = null) => {
        const chapters = chap ? chap : availableChapters;
        let c_id;
        if (sect) {
            c_id = chapters.filter(chapter => chapter.group_name === ref_number && chapter.parent_id === sect)[0];
        } else {
            c_id = chapters.filter(chapter => chapter.group_name === ref_number)[0];
        }
        setChapterMetaData({ id: c_id?.id, ref_number: c_id?.ref_number, structures_id: c_id?.structures });
        setChapterID(c_id?.id);
        return c_id?.id;
    }

    const handleChange = async (event) => {
        switch (event.target.value) {
            case 'terrestrial-code':
                setSelectedRadio({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard: LIST_DETAIL_ELEMENTS.standard_type.codes });
                break;
            case 'terrestrial-manual':
                setSelectedRadio({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard: LIST_DETAIL_ELEMENTS.standard_type.manuals });
                break;
            case 'aquatic-code':
                setSelectedRadio({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.aquatic, standard: LIST_DETAIL_ELEMENTS.standard_type.codes });
                break;
            case 'aquatic-manual':
                setSelectedRadio({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.aquatic, standard: LIST_DETAIL_ELEMENTS.standard_type.manuals });
                break;
            default:
                return '';
        }
        setSelectedOption(event.target.value);
        setDocIdForZeroSections('')
        setSelectedSection({ value: "", label: '' });
        setSelectedChapter({ value: "", label: '' });
        setCodesSectionsGroups({})
        setSections([])
        setSectionID('')
        setChapterID('')
    };

    const getHeaderText = () => {
        switch (selectedOption) {
            case 'terrestrial-code':
                return t("136", 'Terrestrial Animal Health Code');
            case 'terrestrial-manual':
                return t('137', 'Manual of Diagnostic Tests and Vaccines for Terrestrial Animals');
            case 'aquatic-code':
                return t('138', 'Aquatic Animal Health Code');
            case 'aquatic-manual':
                return t('139', 'Manual of Diagnostic Tests for Aquatic Animals');
            default:
                return '';
        }
    };

    const modifyData = (data, modiferText) => {
        let temp = [];
        data.forEach(d => {
            if (d.ref_number === 0) {
                d.children && d.children.length > 0 && d.children.forEach(child => {
                    temp.push({ ...child, standard_content: `${child.standard_content}`, RID: child.standards_structures_revised.RID });
                });
            } else {
                temp.push(d);
            }
        });
        return temp;
    }

    const loadSections = async () => {
        setIsLoading({ ...isLoading, load_sections: true });
        try {
            const ret = await sendAPIRequest(`/api/contents/generic-sections/?language_id=${siteLanguage}&animal_type=${selectedRadio.animal_type}&standard_type=${selectedRadio.standard}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                const sect = modifyData(res.data, 'Section');
                setSections(sect);
                setCodesSectionsGroupsList(res.data);
                setIsLoading({ ...isLoading, load_sections: false });
                return sect;
            }
        } catch (e) {
            console.log({ e });
            setIsLoading({ ...isLoading, load_sections: false });
        }
    }

    const loadAreaVolumns = async () => {
        setIsLoading({ ...isLoading, load_sections: true });
        try {
            const ret = await sendAPIRequest(`/api/contents/generic-volumns/?language_id=${componentLanguage}&animal_type=${selectedRadio.animal_type}&standard_type=${selectedRadio.standard}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                const sect = modifyData(res.data, 'Section');
                setSections(sect);
                setCodesSectionsGroupsList(res.data);
                setIsLoading({ ...isLoading, load_sections: false });
                return sect;
            }
        } catch (e) {
            console.log({ e });
            setIsLoading({ ...isLoading, load_sections: false });
        }
    }

    /**
     * @deprecated
     */
    const getManualVolumes = (data) => {
        let manualVolumes = {};
        data.forEach(d => {
            manualVolumes[d.area_vol_no] = d.area_vol_name;
        });
        return manualVolumes;
    };

    const loadChapters = async (id = null) => {
        setIsLoading({ ...isLoading, load_chapters: true });
        try {
            const ret = await sendAPIRequest(`/api/contents/generic-chapters/?language_id=${siteLanguage}&section_id=${id ? id : sectionID ? sectionID : ''}&animal_type=${selectedRadio.animal_type}&standard_type=${selectedRadio.standard}`)
            const res = await ret.json();
            if (res.success && res.status === 200) {
                for (let ele of res.data) {
                    ele.standard_content = removeHtmlTags(ele.standard_content)
                }
                setAvailableChapters(res.data);
                setIsLoading({ ...isLoading, load_chapters: false });
                return res.data;
            }
        } catch (e) {
            console.log({ e });
        }
        setIsLoading({ ...isLoading, load_chapters: false });
    }

    const sectionOptions = useMemo(() => {
        return generateOptionsFromData(sections, {
            value: "unique_name",
            label: "display_name",
            group_value: 'group_name'
        },
            false,
            true,
            currentVolumeGroup
        );
    }, [sections, currentVolumeGroup]);

    const chapterOptions = useMemo(() => {
        return generateOptionsFromData(availableChapters, {
            value: "group_name",
            label: "display_name",
            group_value: 'parent_id',
        },
            false,
            true,
            codesSectionsGroups
        );
    }, [availableChapters, codesSectionsGroups]);

    const changeComponentLanguage = async (lang) => {
        setMetaData({ ...metaData, language_id: lang });
        setRefetch(!reFetch)
        setComponentLanguage(lang);
    }

    const increaseFontSize = () => {
        if (baseFontSize < 24) {
            setBaseFontSize(prevSize => prevSize + 2);
        }
    };

    const decreaseFontSize = () => {
        if (baseFontSize > 12) {
            setBaseFontSize(prevSize => prevSize - 2);
        }
    };

    const sendDataToBackend = async () => {
        setGeneratingPDF(true);
        try {
            displayNotification('info', 'Generating PDF please wait...');
            const fileName = `WOAH_Report_${currentDate}.pdf`;

            // for local use only
            // const ret = await sendAPIRequest(
            //     `/api/content-navigation-pdf/`,
            //     'POST',
            //     {
            //         chapter: metaData.chapter.RID,
            //         standard_type: metaData.standard_type,
            //         animal_type: metaData.animal_type,
            //         componentLanguage: metaData?.language_id,
            //         volumeNo: metaData.standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals ? metaData?.chapter?.area_vol_no : null,

            //     })
            // For live
            const ret = await fetch(`${CONTENT_PDF_GENERATE_API}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Assuming there's an authorization token needed, otherwise remove this line
                    // 'Authorization': Bearer ${yourAuthTokenHere},
                },
                body: JSON.stringify({
                    chapter: metaData.chapter.RID,
                    standard_type: metaData.standard_type,
                    animal_type: metaData.animal_type,
                    componentLanguage: metaData?.language_id,
                    volumeNo:metaData.standard_type===LIST_DETAIL_ELEMENTS.standard_type.manuals? metaData?.chapter?.area_vol_no: null,

                })
            });
            const data = await ret.json();
            if (data.success && data.status === 200) {
                // For local enviornment
                // const downloadResponse = await sendAPIRequest(`/api/content-navigation-pdf/download/?file_name=${fileName}&uniqueName=${data.data.file_name}`);
                // For live enviornment only
                const downloadResponse = await fetch(`${CONTENT_PDF_DOWNLOAD_API}?fileName=${fileName}&uniqueName=${data.data.file_name}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        // Assuming there's an authorization token needed, otherwise remove this line
                        // 'Authorization': Bearer ${yourAuthTokenHere},
                    },
                });
                if (downloadResponse.ok) {
                    const blob = await downloadResponse.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName ?? 'testing.pdf';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    displayNotification('success', 'Successfully downloaded file');
                } else {
                    displayNotification('error', 'Error downloading file');
                }
            } else {
                displayNotification('error', 'Error creating PDF');
            }
        } catch (error) {
            console.error('Error sending data:', error);
        }
        setGeneratingPDF(false);
    };


    const sharePdf = async () => {
        if (metaData?.chapter.id) {
            await sendDataToBackendShare()
        }
        else {
            displayNotification('info', 'Please select content for pdf');
        }
    }

    const generatePdf = async () => {
        if (metaData?.chapter.id) {
            await sendDataToBackend()
        }
        else {
            displayNotification('info', 'Please select content for pdf');
        }
    }

    const navigateTo = () => {
        navigate(`/portal/advance-search`);
    }

    const sendDataToBackendShare = async () => {
        try {
            displayNotification('info', 'Generating Shareable PDF link please wait...');
            const fileName = `WOAH_Report_${currentDate}.pdf`;
            const ret = await fetch(`${CONTENT_PDF_GENERATE_API}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chapter: metaData.chapter.RID,
                    standard_type: metaData.standard_type,
                    animal_type: metaData.animal_type,
                    componentLanguage: metaData?.language_id,
                    volumeNo: metaData?.chapter?.area_vol_no,
                    volumeNo: metaData.standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals ? metaData?.chapter?.area_vol_no : null,

                })
            });
            const data = await ret.json();
            if (data.success && data.status === 200) {
                const uniqueName = data.data.file_name;
                const subject = encodeURIComponent("PDF Report");
                const body = encodeURIComponent(`Here is the link to the PDF report: ${CONTENT_PDF_DOWNLOAD_API}/?uniqueName=${uniqueName}&fileName=${fileName}`);

                window.open(`mailto:?subject=${subject}&body=${body}`);
                displayNotification('success', 'Successfully generated shareable link');
            } else {
                displayNotification('error', 'Error creating PDF');
            }
        } catch (error) {
            console.error('Error sending data:', error);
        }
    };

    const generateFullPdf = async () => {
        try {
            const fileName = `WOAH_Report_${currentDate}.pdf`;
            let generalText = '';
            const ret = await sendAPIRequest(`/api/generate-pdf/full-chapter-pdf-download?lang_id=${componentLanguage}&animal_type=${selectedRadio.animal_type}&standard_type=${selectedRadio.standard}&general_text=${generalText}`, 'GET')
            const res = await ret.json()
            if (res.success && res.status === 200) {
                // For local enviornment
                const downloadResponse = await sendAPIRequest(`/api/content-navigation-pdf/download?fileName=${fileName}&delete_action=false & uniqueName=${res.data.pdf_link}`);
                // For live enviornment only
                // const downloadResponse = await fetch(${CONTENT_PDF_DOWNLOAD_API}?file_name=${fileName}, {
                //     method: 'GET',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                // });
                if (downloadResponse.ok) {
                    const blob = await downloadResponse.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName ?? 'testing.pdf';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    displayNotification('success', 'Successfully downloaded file');
                } else {
                    displayNotification('error', 'Error downloading file');
                }
            } else {
                displayNotification('error', 'Error creating PDF');
            }
            console.log(res)
        } catch (error) {
        }
    }

    const handlePrint = () => {
        if (metaData.chapter.RID && metaData.language_id) {
            const file_name = `WOAH_Report_${currentDate}.pdf`;
            const url = getBaseURL(`/portal/content-navigation/print?RId=${metaData?.chapter.RID}&language_id=${metaData?.language_id}&fileName=${file_name}`);
            window.open(url, "self");
        } else {
            displayNotification('warning', `${t("96", "No content available for  print")}`);
        }
    };

    function removeHtmlTags(input) {
        return input.replace(/<[^>]+>/g, '');
    }

    return (
        <>
            <Container>
                <StructureNavigationComponent
                    config={{
                        is_display_header: true,
                        is_display_structures: true,
                        is_display_area: false,
                        is_display_sections: true,
                        is_display_chapters: true,
                        is_shown_broweFile_button: false,
                        is_download_pdf: true,
                        checkForPermissions: false,
                        is_display_languages: false,
                        is_display_font_changer: false,
                        is_display_pdf_component: true,
                        is_display_chapter_name: true,
                        is_display_standard_heading: true,
                        is_display_completed_standard_pdf: true
                    }}
                    reFetch={componentLanguage}
                    chilldCheck={true}
                    reloadData={(state) => setMetaData(state)}
                />
                <CustomCard sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                    <Grid container>
                        <Grid item lg={8} md={8} sm={8} xs={12}>
                            <Box className='chapter-font-custom'>
                                {
                                    selectedOption === "terrestrial-manual" ? `${chapter.label ?? ''}`
                                        :
                                        chapter.label
                                            ? `${chapter.label ?? ""}`
                                            : null
                                }
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                            <Box sx={{ display: 'flex', justifyItems: 'end', justifyContent: 'end', alignItems: 'center' }}>
                                <Typography
                                    variant="button"
                                    sx={{
                                        cursor: 'pointer',
                                        marginX: '5px',
                                        textDecoration: parseInt(componentLanguage) === LIST_DETAIL_ELEMENTS.languages.english ? 'underline' : 'none',
                                    }}
                                    onClick={() => (changeComponentLanguage(LIST_DETAIL_ELEMENTS.languages.english))}
                                >
                                    EN
                                </Typography>
                                <Typography
                                    variant="button"
                                    sx={{
                                        cursor: 'pointer',
                                        marginX: '5px',
                                        textDecoration: parseInt(componentLanguage) === LIST_DETAIL_ELEMENTS.languages.french ? 'underline' : 'none',
                                    }}
                                    onClick={() => (changeComponentLanguage(LIST_DETAIL_ELEMENTS.languages.french))}
                                >
                                    FR
                                </Typography>
                                <Typography
                                    variant="button"
                                    sx={{
                                        cursor: 'pointer',
                                        marginX: '5px',
                                        textDecoration: parseInt(componentLanguage) === LIST_DETAIL_ELEMENTS.languages.spanish ? 'underline' : 'none',
                                        paddingRight: 1
                                    }}
                                    onClick={() => (changeComponentLanguage(LIST_DETAIL_ELEMENTS.languages.spanish))}
                                >
                                    ES
                                </Typography>
                                <Divider orientation="vertical" flexItem style={{ backgroundColor: '#000', opacity: 0.87 }} />
                                <Typography
                                    variant="h6"
                                    ms={2}
                                    sx={{
                                        cursor: 'pointer',
                                        paddingLeft: 1,
                                    }}
                                    onClick={increaseFontSize}
                                >
                                    A
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    mx={2}
                                    sx={{ cursor: 'pointer' }}
                                    onClick={decreaseFontSize}
                                >
                                    A
                                </Typography>
                                <Divider orientation="vertical" flexItem style={{ backgroundColor: '#000', opacity: 0.87 }} />
                                {
                                    generatingPDF ?
                                        <LightTooltip title="Generating PDF" placement="top" style={{ margin: '1rem' }}>
                                            <CircularProgress size={20} />
                                        </LightTooltip>
                                        :
                                        <LightTooltip title={t("133", "Download report PDF")} placement="top"
                                        >
                                            <IconButton>
                                                <PictureAsPdfIcon
                                                    color='primary'
                                                    onClick={generatePdf}
                                                />
                                            </IconButton>
                                        </LightTooltip>
                                }
                                <LightTooltip title="Print report" placement="top"
                                >
                                    <IconButton >
                                        <PrintIcon
                                            color='primary'
                                            onClick={handlePrint}
                                        />
                                    </IconButton>
                                </LightTooltip>
                                <LightTooltip title={t("134", "Share report via email")} placement="top"
                                >
                                    <IconButton >
                                        <EmailRoundedIcon
                                            color='primary'
                                            onClick={() => {
                                                sharePdf();
                                            }}
                                        />
                                    </IconButton>
                                </LightTooltip>
                            </Box>
                        </Grid>
                    </Grid>
                </CustomCard>
                {
                    metaData && metaData.chapter && metaData.chapter.RID && metaData.standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes && (
                        <Articles
                            componentLanguage={metaData?.language_id}
                            currentChapter={metaData?.chapter}
                            chapter={metaData?.chapter.id}
                            section={metaData?.section}
                            fontSize={baseFontSize}
                            is_clicked={is_clicked}
                            isCode={true}
                            volume_no={metaData?.chapter?.area_vol_no}
                            selectedOption={selectedOption}
                            metaData={metaData}
                            animalType={metaData.animal_type}
                            standardType={metaData.standard_type}
                        />
                    )
                }
                {
                    metaData && metaData.chapter && metaData.chapter.RID && metaData.standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals && (
                        <Articles
                            componentLanguage={metaData?.language_id}
                            currentChapter={metaData?.chapter}
                            chapter={metaData?.chapter.id}
                            section={metaData?.section}
                            fontSize={baseFontSize}
                            is_clicked={is_clicked}
                            isCode={false}
                            volume_no={metaData?.chapter?.area_vol_no}
                            selectedOption={selectedOption}
                            metaData={metaData}
                            animalType={metaData.animal_type}
                            standardType={metaData.standard_type}
                        />
                    )
                }
                {/* {
                    (
                        (chapterID && (selectedOption === "terrestrial-manual" || selectedOption === 'aquatic-manual')) &&
                        <Articles
                            componentLanguage={metaData?.language_id}
                            currentChapter={metaData?.chapter}
                            chapter={chapterID}
                            section={metaData?.section}
                            fontSize={baseFontSize}
                            is_clicked={is_clicked}
                            isCode={false}
                            volume_no={metaData?.volumn}
                            selectedOption={selectedOption}
                        />
                    )
                } */}
            </Container>
        </>
    )
}

export default ContentNavigation
