import TextFieldCustom from "components/TextFieldCustom";
import Spinner from "components/spinner/Spinner";
import { displayNotification, sendAPIRequest } from "helpers";
import useDebounce from "hooks/useDebounse";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "redux/generalSlice";

const StyleClasses = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [classes, setClasses] = useState([]);
    const [classProperties, setClassproperties] = useState([]);
    const [isLoading, setIsLoading] = useState({
        loading_classes: false,
        loading_properties: false,
        removing_properties: false,
    });
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [currentClassMetadata, setCurrentClassMetadata] = useState({
        tag: '',
        class_name: '',
        id: null
    });
    const [isGenerateCode, setIsGenerateCode] = useState(false);
    const [generatedCode, setGeneratedCode] = useState('');
    const [copied, setCopied] = useState(false);
    const [value, setValue] = useState('');
    const debouncedValue = useDebounce(value);
    const [propertyID, setPropertyID] = useState('');
    const [offset, setOffset] = useState(1);
    const [propertyChapters, setPropertyChapters] = useState([]);
    const [currentProperty, setCurrentProperty] = useState();

    useEffect(() => {
        handleUpdate();
    }, [debouncedValue]);

    useEffect(() => {
        dispatch(setCurrentPage('tools'));
        getClasses();
    }, []);

    const handleUpdate = async () => {
        try {
            if (propertyID) {
                const ret = await sendAPIRequest(
                    `/api/style/${propertyID}`,
                    'PUT',
                    {
                        svalue: debouncedValue
                    }
                );
                const res = await ret.json();
                if (!res.success) {
                    console.error("Failed to update");
                }
            }
        } catch (err) {
            console.log({ err });
        }
    }

    const getClasses = async () => {
        setIsLoading({ ...isLoading, loading_classes: true });
        try {
            const ret = await sendAPIRequest(`/api/style/style-classes`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setClasses(res.data);
                setIsGenerateCode(false);
                setOffset(1);
                setPropertyChapters();
                setCurrentProperty('');
            }
        } catch (err) {
            console.log(err);
        }
        setIsLoading({ ...isLoading, loading_classes: false });
    }

    const modifyClassProperties = (data) => {
        let temp = data;
        for (const t of temp) {
            t['checked'] = false;
        }
        return temp;
    }

    const getClassProperties = async (tag, class_name, id) => {
        setIsLoading({ ...isLoading, loading_properties: true });
        try {
            const ret = await sendAPIRequest(`/api/style/style-class-properties/?tag=${tag.replace(/\s/g, "")}&class_name=${class_name ?? ''}&status=1`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setCurrentClassMetadata({ tag: tag.replace(/\s/g, ""), class_name: class_name, id: id });
                setClassproperties(modifyClassProperties(res.data));
            }
        } catch (err) {
            console.log(err);
        }
        setIsLoading({ ...isLoading, loading_properties: false });
    }
    const getNextProperties = async () => {
        setIsLoading({ ...isLoading, loading_properties: true });
        try {
            const tempOffset = offset + 1;
            const ret = await sendAPIRequest(`/api/style/style-class-properties/?offset=${tempOffset}&tag=${currentClassMetadata.tag}&class_name=${currentClassMetadata.class_name ?? ''}&status=1`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setClassproperties(modifyClassProperties(res.data));
                if (res.data.length > 0) {
                    setOffset(tempOffset);
                }
            }
        } catch (err) {
            console.log(err);
        }
        setIsLoading({ ...isLoading, loading_properties: false });
    }
    const getPreviousProperties = async () => {
        setIsLoading({ ...isLoading, loading_properties: true });
        try {
            if (offset <= 1) {
                setIsLoading({ ...isLoading, loading_properties: true });
                return;
            }
            const tempOffset = offset - 1;
            const ret = await sendAPIRequest(`/api/style/style-class-properties/?offset=${tempOffset}&tag=${currentClassMetadata.tag}&class_name=${currentClassMetadata.class_name ?? ''}&status=1`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setClassproperties(modifyClassProperties(res.data));
                if (res.data.length > 0) {
                    setOffset(tempOffset);
                }
            }
        } catch (err) {
            console.log(err);
        }
        setIsLoading({ ...isLoading, loading_properties: false });
    }
    const removeProperties = async () => {
        if (!window.confirm('Are you sure you want to remove selected properties?')) return;
        setIsLoading({ ...isLoading, removing_properties: true });
        try {
            const ret = await sendAPIRequest(
                `/api/style/property-status`,
                'PUT',
                {
                    status: 0,
                    id: selectedProperties
                }
            );
            const res = await ret.json();
            if (res.status === 200 && res.success) {
                setSelectedProperties([]);
                await getClassProperties(currentClassMetadata.tag, currentClassMetadata.class_name);
            }
        } catch (err) {
            console.log(err);
        }
        setIsLoading({ ...isLoading, removing_properties: false });
    }

    const handleCheckbyProperty = (property) => {
        const propertyValues = classProperties.filter(p => p.sproperty === property).map(p => p.id);
        let temp = selectedProperties;
        let checked = false;
        if (temp.some(p => propertyValues.includes(p))) {
            temp = temp.filter(p => !propertyValues.includes(p));
            checked = false;
        } else {
            temp.push(...propertyValues);
            checked = true;
        }
        const tempData = classProperties.map(p => {
            if (propertyValues.includes(p.id)) {
                p.checked = checked;
            }
            return p;
        });
        setClassproperties(tempData);
        setSelectedProperties(temp);
    }

    const handleCheck = (checked, id) => {
        let temp = selectedProperties;
        if (checked) {
            temp.push(id);
        } else {
            temp = temp.filter(t => t !== id);
        }
        const tempData = classProperties.map(p => {
            if (p.id === id) {
                p.checked = checked;
            }
            return p;
        });
        setClassproperties(tempData);
        setSelectedProperties(temp);
    }

    const parsing = (cssString) => {
        const cssCode = Object.keys(cssString).map(property => {
            return `${property}: ${cssString[property]}`;
        }).join('\n\t');
        return ['{', cssCode, '}'].join('\n\t');
    }

    const generateCSS = () => {
        let obj = {};
        classProperties.forEach(p => {
            obj[p.sproperty.replace(/[\n\t\s]/g, '')] = `${p.svalue};`;
        });
        setGeneratedCode(`${currentClassMetadata.tag}.${currentClassMetadata.class_name} ${parsing(obj)}`);
        setIsGenerateCode(true);
    }

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(generatedCode);
            setCopied(true);
            setTimeout(() => setCopied(false), 3000); // Reset copied state after 2 seconds
        } catch (error) {
            console.error('Failed to copy:', error);
        }
    };

    const handleOnChange = (value, key) => {
        let temp = classProperties;
        temp[key].svalue = value;
        setClassproperties(temp);
    }

    const getPropertyChapters = async (ids, name) => {
        try {
            const ret = await sendAPIRequest(`/api/style/property-chapters/?properties=${ids.join(',')}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setPropertyChapters(res.data);
                setCurrentProperty(name);
            } else {
                setPropertyChapters([]);
                setCurrentProperty('');
            }
        } catch (err) {
            console.log(err);
        }
    }

    const removeClassWithProperties = async (id) => {
        try {
            if (!window.confirm('Are you sure you want to remove?')) {
                return;
            }
            const ret = await sendAPIRequest(`/api/style/remove-class-with-properties/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully removed class [B]');
                await getClasses();
            } else {
                displayNotification('error', 'Failed to remove class [B]');
            }
        } catch (err) {
            console.log(err);
            displayNotification('error', 'Failed to remove class [F]');
        }
    }

    return (
        <>
            <div className="container my-2">
                <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2">
                    <div className="d-flex justify-content-between w-100">
                        <div className="card-title1 lh-1">
                            {t("-1", "Tanveer Tool")}
                        </div>
                    </div>
                </div>
                <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2">
                    <div className="row">
                        <div
                            className={isLoading.loading_classes ? "col-md-4 w-100" : "col-md-4"}
                        >
                            <div
                                className="scrollspy-inner overflow-x-hidden overflow-y-auto py-2"
                                style={{ height: "465px" }}
                            >
                                <div className="d-flex flex-column">
                                    {
                                        isLoading.loading_classes
                                            ? <Spinner message="Loading..." className="w-100" />
                                            : classes.map(
                                                c =>
                                                    <div key={c.id} className='my-1' id={`style-${c.id}`} >
                                                        <div className='list-group bg-light btn-sm d-flex flex-column p-1'>
                                                            <div className="d-flex justify-content-between">
                                                                <button
                                                                    disabled={isLoading.loading_properties || isLoading.removing_properties}
                                                                    className={
                                                                        `${isLoading.loading_properties || isLoading.removing_properties ? 'opacity-50' : 'opacity-100'} list-group-item rounded-0 list-group-item-action my-1 fs-5 fw-bold cursor-pointer  ${currentClassMetadata.id === c.id ? 'list-group-item-checked' : ''}`
                                                                    }
                                                                    onClick={() => getClassProperties(c.tag_name, c.class_name, c.id)}
                                                                >
                                                                    <div className='d-flex flex-column'>
                                                                        <span>
                                                                            {c.tag_name} {c.class_name}
                                                                        </span>
                                                                    </div>
                                                                </button>
                                                                <span className="align-items-center cursor-pointer d-flex mx-1" onClick={() => removeClassWithProperties(c.id)}>
                                                                    <i className="fa-solid fa-trash"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="d-flex">
                                {
                                    !isLoading.loading_classes &&
                                    <div>
                                        {/* <div className="m-1">
                                            <button
                                                className="btn btn-sm btn-primary"
                                                disabled={offset === 1 || isLoading.loading_properties || isLoading.removing_properties}
                                                onClick={() => getPreviousProperties()}
                                            >
                                                Previous
                                            </button>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                disabled={isLoading.loading_properties || isLoading.removing_properties}
                                                onClick={() => getNextProperties()}
                                            >
                                                Next
                                            </button>
                                        </div> */}
                                        <div
                                            className="scrollspy-inner overflow-x-hidden overflow-y-auto py-2 mb-1"
                                            style={{ height: "465px" }}
                                        >
                                            <div className="m-1">
                                                <table className="table table-striped table-bordered">
                                                    <thead className="bg-primary position-sticky text-white">
                                                        <tr>
                                                            <th scope="col">Property</th>
                                                            <th scope="col">Value</th>
                                                            <th scope="col">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            classProperties && classProperties.length > 0 && classProperties.map((property, key) =>
                                                                <tr
                                                                    key={`child-${property.id}`}
                                                                    id={`child-${property.id}`}
                                                                >
                                                                    <td
                                                                        className={isLoading.loading_properties ? "opacity-50" : "opacity-100 cursor-pointer"}
                                                                        onClick={
                                                                            // () => handleCheckbyProperty(property.sproperty)
                                                                            () => getPropertyChapters(property.other_records, property.sproperty)
                                                                        }
                                                                    >
                                                                        {property.sproperty}
                                                                    </td>
                                                                    <td className={isLoading.loading_properties ? "opacity-50" : "opacity-100"}>
                                                                        <TextFieldCustom
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            size="small"
                                                                            sx={{
                                                                                backgroundColor: "white",
                                                                                color: "white",
                                                                                border: "#ff4815",
                                                                                width: "98%",
                                                                            }}
                                                                            value={property.svalue}
                                                                            onChange={(e) => {
                                                                                setValue(e.target.value);
                                                                                setPropertyID(property.id);
                                                                                handleOnChange(e.target.value, key);
                                                                            }
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            id={property.id}
                                                                            checked={property.checked}
                                                                            disabled={isLoading.loading_properties || isLoading.removing_properties}
                                                                            type="checkbox"
                                                                            onChange={(e) => handleCheck(e.target.checked, property.id)}
                                                                        >
                                                                        </input>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="m-1">
                                            <button
                                                className="btn btn-sm btn-primary"
                                                disabled={isLoading.loading_properties || isLoading.removing_properties}
                                                onClick={removeProperties}
                                            >
                                                {
                                                    isLoading.removing_properties
                                                        ? "Removing..."
                                                        : "Remove Selected"
                                                }
                                            </button>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                disabled={isLoading.loading_properties || isLoading.removing_properties}
                                                onClick={generateCSS}
                                            >
                                                Generate CSS
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    !isLoading.loading_classes && isGenerateCode &&
                                    <div className="p-2">
                                        <div className="d-flex justify-content-end bg-dark text-white w-100 my-1 p-1 rounded-1">
                                            <span
                                                className="cursor-pointer h-auto"
                                                onClick={handleCopy}
                                            >
                                                {
                                                    copied
                                                        ? <>
                                                            <i className="fa-solid fa-check"></i>
                                                            <span className="mx-1">Copied!</span>
                                                        </>
                                                        : <>
                                                            <i className="fa-regular fa-copy"></i>
                                                            <span className="mx-1">Copy Code</span>
                                                        </>
                                                }
                                            </span>
                                        </div>
                                        <pre>
                                            <code>
                                                {generatedCode}
                                            </code>
                                        </pre>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            !isLoading.loading_classes && propertyChapters && propertyChapters.length > 0 &&
                                    <div className="row p-2">
                                        <div
                                            className="scrollspy-inner overflow-x-hidden overflow-y-auto py-2 mb-1"
                                            style={{ height: "465px" }}
                                        >
                                            <div className="d-flex flex-column bg-dark text-white w-100 my-1 p-4">
                                                <h3 className="text-white">Associated Chapters against: {currentProperty}</h3>
                                                {
                                                    propertyChapters.map(p =>
                                                        <p
                                                            className="h-auto"
                                                        >
                                                            {p}
                                                        </p>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                    </div>
                </div>
            </div>
        </>
    );
}

StyleClasses.defaultProps = {

};

export default StyleClasses;