import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
// import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';
import SpecialCharactersCurrency from '@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency';
import SpecialCharactersArrows from '@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows';
import SpecialCharactersMathematical from '@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical';
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
import BlockToolbar from '@ckeditor/ckeditor5-ui/src/toolbar/block/blocktoolbar';
import SelectAll from '@ckeditor/ckeditor5-select-all/src/selectall';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak';
import Markdown from '@ckeditor/ckeditor5-markdown-gfm/src/markdown';

import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
import HtmlComment from '@ckeditor/ckeditor5-html-support/src/htmlcomment';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize'; // Font Size
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily'; // Font Family
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor'; // Font Color
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor'; // Font Background Color
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'; // Text Alignment
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import ImagePicker from './ImageGalleryPlugin'
import CustomStylePlugin from './CustomStylePlugin'
import fetchCustomStyles  from './CustomStyles'; // Import the fetch function
import FormatPainter from '@ckeditor/ckeditor5-format-painter/src/formatpainter';
import CaseChange from '@ckeditor/ckeditor5-case-change/src/casechange';
import DocumentOutline from '@ckeditor/ckeditor5-document-outline/src/documentoutline';
import PasteFromOfficeEnhanced from '@ckeditor/ckeditor5-paste-from-office-enhanced/src/pastefromofficeenhanced';
// import TableOfContents from '@ckeditor/ckeditor5-table-of-contents/src/tableofcontents';
import Template from '@ckeditor/ckeditor5-template/src/template';
import Mention from '@ckeditor/ckeditor5-mention/src/mention'; 
import SlashCommand from '@ckeditor/ckeditor5-slash-command/src/slashcommand';
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace';


// import './ckeditor.css'


class ClassicEditor extends ClassicEditorBase { }

const customStyles = await fetchCustomStyles() ?? [];

ClassicEditor.builtinPlugins = [
    Highlight,
    FormatPainter,
    CustomStylePlugin,
    // DocumentOutline,
    Template,
    PasteFromOfficeEnhanced,
    Mention,
    SlashCommand,
    CaseChange,
    Essentials,
    TableProperties,
    TableCellProperties, // Add the TableCellProperties plugin here
    SourceEditing,
    BlockToolbar,
    Image,
    FindAndReplace,
    SelectAll,
    RemoveFormat,
    PasteFromOffice,
    PageBreak,
    Indent,
    IndentBlock,
    HtmlComment,
    HorizontalLine,
    Autoformat,
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    BlockQuote,
    // Heading,
    ImageCaption,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    ImageResize,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    HtmlEmbed,
    Table,
    TableToolbar,
    GeneralHtmlSupport,
    CodeBlock,
    SpecialCharacters,
    SpecialCharactersEssentials,
    SpecialCharactersCurrency,
    SpecialCharactersArrows,
    SpecialCharactersMathematical,
    FontSize,
    FontFamily,
    FontColor,
    FontBackgroundColor,
    Alignment,
    Base64UploadAdapter,
    ImagePicker,
    CustomStylePlugin
];

// Document Outline
ClassicEditor.defaultConfig = {
    toolbar: {
        items: [
            'formatPainter',
            'caseChange',
            'insertTemplate',
            'findAndReplace',
            // 'documentOutline',
            'imagePicker',
            'imageUpload',
            'customStyles',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            'link',
            'autoformat',
            'imageCaption',
            'highlight',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'blockQuote',
            'horizontalLine',
            'selectAll',
            'sourceEditing',
            'insertTable',
            'tableToolbar',
            'tableProperties',
            'tableCellProperties', // Add the tableCellProperties here
            'removeFormat',
            'mediaEmbed',
            'htmlEmbed',
            '|',
            'codeBlock',
            'specialCharacters',
            'pageBreak',
            'undo',
            'redo'
        ],

        shouldNotGroupWhenFull: true
    },
    customStyleData: [...customStyles],
    // heading: {
    //     options: [
    //         { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
    //         { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
    //         { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
    //         { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
    //         { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
    //         { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
    //         { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
    //     ]
    // },
    htmlSupport: {
        allow: [
            {
                name: /.*/,
                attributes: true,
                classes: true,
                styles: true
            }
        ]
    },
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableProperties', // Add the table properties here
            'tableCellProperties' // Add the table cell properties here
        ]
    },
    fontSize: {
        options: [
            9,
            10,
            11,
            12,
            14,
            'default',
            16,
            18,
            20,
            22,
            24,
            26,
            28,
            36,
            48,
            60,
            72,
            84,
            96
        ],
        supportAllValues: true
    },
    // documentOutline: {

    //     container: document.querySelector( '.document-outline-container' ),

    // },
    image: {
        toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            'imageResize'
        ],
        resizeOptions: [
            {
                name: 'imageResize:original',
                label: 'Original',
                value: null
            },
            {
                name: 'imageResize:50',
                label: '50%',
                value: '50'
            },
            {
                name: 'imageResize:75',
                label: '75%',
                value: '75'
            }
        ],
        resizeUnit: '%'
    },
    language: 'en'
};

export default ClassicEditor;