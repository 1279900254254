import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ManualParentChildMapping = ({handleRadioButtonChangeState, linkChildWithParent, index, index2, selectedValue,handleManualChildLink ,handleLinkChildLinking}) => {

    const { t } = useTranslation();
    const [selectedID, setSelectedID] = useState(''); 
    const [link ,setLink] = useState("link")
    const handleRadioChange = (event) => {
        setLink(event.target.value === "link");
    };

    return (
        <React.Fragment>
            <div>
                <TextField
                    value={selectedID}
                    onChange={(e) => setSelectedID(e.target.value)}
                    id="outlined-basic"
                    label="Enter Child Id"
                    variant="outlined"
                    size="small"
                    type="number"
                    sx={{
                        margin: "5px",
                    }}
                    className="bg-white"
                />
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        sx={{ marginTop: "13px" }}
                        value={link ? "link" : "un-link"} 
                        onChange={handleRadioChange}
                    >
                        <FormControlLabel
                            value="link"
                            control={<Radio style={{ height: "18px" }} />}
                            label="Link"
                        />
                        <FormControlLabel
                            value="un-link"
                            control={<Radio style={{ height: "18px" }} />}
                            label="Unlink"
                        />
                    </RadioGroup>
                </FormControl>
                <button
                    className="btn btn--primary mx-2"
                    onClick={() =>
                        // linkChildWithParent(index, selectedID, selectedValue)
                        // handleLinkChildLinking(index,selectedID,link)
                        handleManualChildLink(index ,selectedID ,link)
                    }
                >
                    {t("-1", "Link")}
                </button>
            </div>
        </React.Fragment>
    );
};

ManualParentChildMapping.defaultProps = {
    handleRadioButtonChangeState: () => {},
    linkChildWithParent: () => {}, 
    index: null, 
    index2: null,
    selectedValue: null,
};

export default ManualParentChildMapping;
