import { GridActionsCellItem } from "@mui/x-data-grid";
import Button from "components/common/Button";
import CardBody from "components/common/CardBody";
import CardHeader from "components/common/CardHeader";
import Container from "components/common/Container";
import CustomCard from "components/common/CustomCard";
import { displayNotification, sendAPIRequest } from "helpers";
import DataTable from "pages/components/DataTable";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentPage } from "redux/generalSlice";

const DynamicFiltersList = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(setCurrentPage("administration"));
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/lists/?is_filter=true`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data);
            }
        } catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    }

    const handleDelete = async (id) => {
        try {
            const ret = await sendAPIRequest(`/api/lists/master/${id}`, "DELETE");
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully deleted [B]');
                await loadData();
            } else {
                displayNotification('error', 'Fail to delete [B]');
            }
        } catch (err) {
            console.log(err);
            displayNotification('error', 'Fail to delete [F]');
        }
    }

    return (
        <>
            <Container>
                <CustomCard className={'d-flex'}>
                    <CardHeader>
                        Dynamic Filters
                    </CardHeader>
                    <CardBody>
                        <Button
                            onClick={() => navigate('/portal/catalog')}
                        >
                            Back
                        </Button>
                        <Button
                            onClick={() => navigate('/portal/dynamic-filters/create')}
                        >
                            Add New Filter
                        </Button>
                    </CardBody>
                </CustomCard>
                <CustomCard className={'my-2'}>
                    <DataTable
                        getRowId={(row) => row.id}
                        isLoading={isLoading}
                        rows={data}
                        className="text-white bg-primary rounded-0"
                        columns={
                            [
                                {
                                    field: "list_name",
                                    headerName: "Filter Name",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "actions",
                                    type: "actions",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    headerName: "Actions",
                                    getActions: (params) => [
                                        <GridActionsCellItem
                                            label="Add Item"
                                            key={1}
                                            showInMenu
                                            onClick={() => navigate(`/portal/dynamic-filters/item/create/?filter_name=${params.row.list_name}&master_id=${params.row.id}`)}
                                        />,
                                        <GridActionsCellItem
                                            label="View Items"
                                            key={1}
                                            showInMenu
                                            onClick={() => navigate(`/portal/dynamic-filters/items/view/${params.row.id}/?filter_name=${params.row.list_name}`)}
                                        />,
                                        <GridActionsCellItem
                                            label="Edit"
                                            key={1}
                                            showInMenu
                                            onClick={() => navigate(`/portal/dynamic-filters/update/${params.row.id}`)}
                                        />,
                                        <GridActionsCellItem
                                            label={
                                                "Delete"
                                            }
                                            key={0}
                                            showInMenu
                                            onClick={() => {
                                                handleDelete(params.row.id);
                                            }
                                            }
                                        />
                                    ]
                                }
                            ]
                        }
                    />
                </CustomCard>
            </Container>
        </>
    );
}

DynamicFiltersList.defaultProps = {};

export default DynamicFiltersList;