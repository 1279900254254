import ThreeDotsDropdown from "components/ThreeDotsDropdown";
import { LIST_DETAIL_ELEMENTS } from "constants";
import React from "react";

const DropDownMenu = ({editRef, splitRef, setContentNumber, handleMarking, handleMarkAsParagraph, updateContent, ignoreContent, splitText, index, index2, d}) => {
    return (
        <ThreeDotsDropdown
                options={[
                    (className) => (
                        <div
                                className={className}
                                onClick={() => {
                                    editRef.current.handleOpen(d?.ref_number, "Section Number", (content) => setContentNumber(index, index2, content, 'section', LIST_DETAIL_ELEMENTS.text_types.section_name))
                                }}
                            >
                                Mark as Section
                            </div>
                    ),
                    (className) => (
                            <div
                                className={className}
                                onClick={() => {
                                    editRef.current.handleOpen(d?.ref_number, "Chapter Number", (content) => setContentNumber(index, index2, content, 'chapter', LIST_DETAIL_ELEMENTS.text_types.chapter_name))
                                }}
                            >
                                Mark as Chapter
                            </div>
                    ),
                    (className) => (
                            <div
                                className={className}
                                onClick={() => {
                                    editRef.current.handleOpen(d?.ref_number, "Article Number", (content) => setContentNumber(index, index2, content, 'article', LIST_DETAIL_ELEMENTS.text_types.article_heading))
                                }}
                            >
                                Mark as Article
                            </div>
                    ),
                    (className) => (
                            <div
                                className={className}
                                onClick={() => {
                                    handleMarking(index, index2, "recommendation", LIST_DETAIL_ELEMENTS.recommendation_types.safe_commodities)
                                }}
                            >
                                Mark as Safe Commodities
                            </div>
                    ),
                    (className) => (
                            <div
                                className={className}
                                onClick={() => {
                                    handleMarking(index, index2, "recommendation", LIST_DETAIL_ELEMENTS.recommendation_types.recommendations_for_importations)
                                }}
                            >
                                Mark as Recommendations for Importations
                            </div>
                    ),
                    (className) => (
                            <div
                                className={className}
                                onClick={() => {
                                    handleMarking(index, index2, "recommendation", LIST_DETAIL_ELEMENTS.recommendation_types.inactivation_procedures)
                                }}
                            >
                                Mark as Inactivation Procedures
                            </div>
                    ),
                    (className) => (
                            <div
                                className={className}
                                onClick={() => {
                                    handleMarking(index, index2, "general_heading", LIST_DETAIL_ELEMENTS.heading_types.general_heading)
                                }}
                            >
                                Mark as General Heading
                            </div>
                    ),
                    (className) => (
                            <div
                                className={className}
                                onClick={() => handleMarkAsParagraph(index)}
                            >
                                Mark as Paragraph
                            </div>
                    ),
                    (className) => (
                            <div className={className}>Mark as Table</div>
                    ),
                    (className) => (
                            <div
                                className={className}
                                onClick={() => {
                                    editRef.current.handleOpen(d?.json_content, "Edit Text", (content) => updateContent(index, index2, content, 10))
                                }}
                            >
                                Edit
                            </div>
                    ),
                    (className) => (
                            <div
                                className={className}
                                onClick={() => {
                                    splitRef.current.handleOpen(d?.json_content, d?.offset, d?.id, "Split Text", (content) => splitText(index, index2, content))
                                }}
                            >
                                Split Text
                            </div>
                    ),
                    (className) => (
                            <div
                                className={className}
                                onClick={() => {
                                    ignoreContent(index, index2);
                                }}
                            >
                                Ignore
                            </div>
                    ),
                ]}
                className="text-primary fs-5 ms-2"
                isVertical={true}
            />
    );
}

DropDownMenu.defaultProps = {
    editRef: null,
    splitRef: null,
    setContentNumber: () => {},
    handleMarking: () => {},
    handleMarkAsParagraph: () => {},
    updateContent: () => {},
    ignoreContent: () => {},
    handleHeadingMarking: () => {},
    index: null,
    index2: null,
    d: {}
}

export default DropDownMenu;