export const APP_VERSION = 'v1.2.0';
export const BASE_URL = process.env.REACT_APP_BASE_URL
    ? process.env.REACT_APP_BASE_URL
    : 'https://woah-sont.sysreformsint.com';
export const API_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'https://be.woah-sont.sysreformsint.com/sont-be';
export const ENV_SITE_DEV = process.env.REACT_APP_SITE_DEV ? String(process.env.REACT_APP_SITE_DEV).toLowerCase() == 'true' : false;
export const LAST_SYSTEM_UPDATE_DATETIME = '10-Sep-2024 (04:42 PM GMT +5)';
export const PDF_GENERATE_API = 'https://be.woah-sont.sysreformsint.com/sont-be/api/generate-pdf/';
export const PDF_DOWNLOAD_API = 'https://be.woah-sont.sysreformsint.com/sont-be/api/generate-pdf/download/';
export const CONTENT_MANUAL_PDF_GENERATE_API = 'https://be.woah-sont.sysreformsint.com/sont-be/api/content-navigation-pdf/manual';
export const CONTENT_PDF_GENERATE_API = 'https://be.woah-sont.sysreformsint.com/sont-be/api/content-navigation-pdf/';
export const CONTENT_PDF_DOWNLOAD_API = 'https://be.woah-sont.sysreformsint.com/sont-be/api/content-navigation-pdf/download';
export const Search_By_Keywords_Detail = 'https://be.woah-sont.sysreformsint.com/sont-be/api/search-by-keywords-pdf/detail';
export const Search_By_Keywords_List_Download = 'https://be.woah-sont.sysreformsint.com/sont-be/api/search-by-keywords-pdf/download';
export const Search_By_Keywords_List = 'https://be.woah-sont.sysreformsint.com/sont-be/api/search-by-keywords-pdf/';
export const ALL_FILTERS = [95, 96, 97, 98, 99, 100, 136];
export const ALL_RISK_FILTERS = [95, 97, 100, 136];
export const SHOW_RISK_FILTERS = [97, 100, 95];
export const ALL_FREE_NOT_FREE_FILTERS = [98, 99, 96];
export const SHOW_FREE_NOT_FREE_FILTERS = [99, 96];
export const ALL_SELECTION_FILTERS = [98, 136];
export const HTML_DROP_DOWN_MENU_LIST = [
    // { id: 121, label: 'h1'},
    // { id: 122, label: 'h2'},
    // { id: 123, label: 'h3'},
    // { id: 124, label: 'h4'},
    // { id: 125, label: 'h5'},
    // { id: 126, label: 'h6'},
    // { id: 129, label: 'Under Line'},
    // { id: 130, label: 'Order List'},
    // { id: 131, label: 'Un-Order List'},
    { id: 92, label: 'Mark as Safe Commodities' },
    { id: 93, label: 'Mark as Recommendations for Importations' },
    { id: 94, label: 'Mark as Inactivation Procedures' },
    { id: 101, label: 'Mark as General Heading' },
    { id: 135, label: 'Mark as Sub Title' },
    { id: 138, label: 'Mark as Image' },
    { id: 139, label: 'Mark as Table' },
    { id: 137, label: 'Mark as Code editing date text' },
    { id: 0, label: 'Ignore Content' },
];

export const CODE_VOLUMES = { 153: 'General provisions', 154: 'Recommendations applicable to WOAH Listed diseases and other diseases of importance to international trade' };
export const CODE_VOLUME_Array = [
    {
        area_vol_no: 1,
        area_vol_name: 'General provisions',
    },
    {
        area_vol_no: 2,
        area_vol_name: 'Recommendations applicable to WOAH Listed diseases and other diseases of importance to international trade',
    },
]

export const CODE_VOLUMES_AGAINST_AREA_NUMBER = {
    102: { 1: 'General provisions', 2: 'Recommendations applicable to WOAH Listed diseases and other diseases of importance to international trade' },
    103: { 1: 'Dispositions générales', 2: `Recommandations applicables aux maladies listées par l'OMSA et autres maladies ayant une importance pour le commerce international` },
    104: { 1: 'Informaciones generales', 2: `Recomendaciones aplicables a las enfermedades de la lista de la OIE y a otras enfermedades importantes para el comercio internacional` }
};



export const MANUAL_HEADINGS = [144, 146, 148, 149, 150, 151, 152, 156];

export const SIDEBAR_HEADINGS = [90, 144,147, 146, 148, 149, 150, 151, 152, 156];

export const CHAPTERS_NOT_VISIBLE_IN_FILTERS_FOR_CODES = [0];
export const CHAPTERS_NOT_VISIBLE_IN_FILTERS_FOR_TERRESTRIAL_MANUALS = [1];
export const CHAPTERS_NOT_VISIBLE_IN_FILTERS_FOR_AQUATIC_MANUALS = [0];

export const USER_ROLES = [
    {
        id: 3,
        value: 'Author'
    },
    {
        id: 5,
        value: 'Validator'
    }
];

export const TRANSFER_STATUS = {
    editing: 1,
    validation: 2,
    publishing: 3,
    published: 4,
};

export const USERS_WITH_PUBLISHER = [
    {
        id: 3,
        value: 'Author'
    },
    {
        id: 5,
        value: 'Validator'
    },
    {
        id: 6,
        value: 'Publisher'
    }
];

export const USER_PERMISSIONS = {
    author: 3,
    validator: 5,
    publisher: 6,
};

export const LIST_DETAIL_ELEMENTS = {
    roles: {
        admin: 1,
        business_admin: 2,
        author: 3,
        data_migrator: 4,
        validator: 5,
        publisher: 6,
    },
    standard_type: {
        codes: 5,
        manuals: 6,
    },
    animal_type: {
        terrestrial: 7,
        aquatic: 8,
    },
    text_types: {
        section_number: 85,
        section_name: 86,
        chapter_number: 87,
        chapter_name: 88,
        article_number: 89,
        article_heading: 90,
        paragraph: 91,
        manual_heading: 144,
        manual_paragraph: 145,
        manual_reference: 146,
        manual_footer: 147,
        manual_appendex: 148,
        manual_biblography: 149,
        manual_appendex_heading: 150,
        manual_Further_reading: 151,
        manual_online_resource: 152,
        glossary_term: 156
    },
    recommendation_types: {
        safe_commodities: 92,
        recommendations_for_importations: 93,
        inactivation_procedures: 94,
    },
    recommendation_status_types: {
        undetermined_risk: 95,
        not_free: 96,
        negligible_risk: 97,
        free_and_not_free: 98,
        free: 99,
        controlled_risk: 100,
    },
    heading_types: {
        general_heading: 101,
    },
    languages: {
        english: 102,
        french: 103,
        spanish: 104,
    },
    manual_details: {
        heading: 141,
        paragraph: 142
    },
    styles_categories: {
        h1: 121,
        h2: 122,
        h3: 123,
        h4: 124,
        h5: 125,
        h6: 126,
        bold: 127,
        italic: 128,
        underline: 129,
        order_list: 130,
        un_order_list: 131,
        link: 132,
        sub_title: 135,
        code_editing_date_text: 137,
        image: 138,
        table: 139
    },
    code_volumes: {
        volume1: 153,
        volume2: 154,
    }
}
export const LIST_MASTER_ELEMENTS = {
    standard_type: 1,
    animal_type: 2,
    disease_name: 3,
    text_type: 4,
    recommendation_types: 5,
    recommendation_status_types: 6,
    heading_types: 7,
    languages: 8,
    styles_categories: 9
}
export const RECOMMENDATION_STATUS_TYPES = {
    "Undetermined Risk": 95,
    "Not Free": 96,
    "Negligible Risk": 97,
    "Free and Not Free": 98,
    "Free": 99,
    "Controlled Risk": 100,
    "Always visible regardless the category of risk selected": 136
};
export const MISSION_STATUSES = {
    0: 'In-Active',
    1: 'Active',
};
export const SITE_LANGUAGES = {
    102: 'en',
    103: 'fr',
    104: 'es',
};
export const ANIMAL_STATUSES = {
    0: 'Terrestrial',
    1: 'Aquatic',
};
// export const CODE_VOLUMES_AGAINST_AREA_NUMBER = { 1: 'General provisions', 2: 'Recommendations applicable to WOAH Listed diseases and other diseases of importance to international trade' }
export const TERRESTRIAL_MANUAL_VOLUMES_AGAINST_AREA_NUMBER = {
    1: 'General Standards',
    2: 'Specific Recommendations',
    3: 'WOAH Listed Diseases and Other Diseases of Importance',

};
export const AQUATIC_MANUAL_VOLUMES_AGAINST_AREA_NUMBER = {
    1: 'General provisions',
    2: 'Recommendations applicable to specific diseases',

};

export const TERRESTRIAL_MANUAL_VOLUMES_AGAINST_AREA_NUMBER_ARRAY = [{
    area_vol_no: 1,
    area_vol_name: 'General Standards'
},
{
    area_vol_no: 2,
    area_vol_name: 'Specific Recommendations'
},
{
    area_vol_no: 3,
    area_vol_name: 'WOAH Listed Diseases and Other Diseases of Importance'
}
]

export const AQUATIC_MANUAL_VOLUMES_AGAINST_AREA_NUMBER_ARRAY = [{
    area_vol_no: 1,
    area_vol_name: 'General provisions'
},
{
    area_vol_no: 2,
    area_vol_name: 'Recommendations applicable to specific diseases'
}
]