import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const EditButton = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const authData = useSelector(s => s.auth.data);
    const [currentTab, setCurrentTab] = useState('codes');

    return (
        <div>
            <p
                className="text-primary fs-7 mt-7"
                onClick={() => setCurrentTab('codes')}
            >
                {t("-1", "Status: Locked")}
                <span className='ps-4 fw-bold text-dark'>
                    {t("-1", "Edit")}
                </span>
            </p>
        </div>
    )
}

export default EditButton
