import React, { useCallback, useState, } from 'react'
import { useEffect } from 'react';
import logo from "images/WOAH_ANNIVERSARY_PRIMARY LOGO_ORANGE + GOLD_ENG.png";
import { useLocation, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import html2pdf from 'html2pdf.js';
import { BsExclamationTriangleFill, BsInfoCircleFill } from 'react-icons/bs';
import { Chip } from '@mui/material';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import HtmlRenderer from 'components/HtmlRenderer';
import { useSelector } from 'react-redux';
import useLocalStorage from 'hooks/useLocalStorage';


let readyForPrint = false;

function PdfDetails() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const siteLanguage = useSelector((s) => s.general.siteLanguageSont);
  const componentRef = React.useRef();
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const { level1, level2, level3, fileName } = {
    level1: searchParams.get("level1"),
    level2: searchParams.get("level2"),
    level3: searchParams.get("level3"),
    fileName: searchParams.get("file_name"),
  };
  const labels = searchParams.get("labels").split(",");
  const isDownload = searchParams.get("download") === "true" ? true : false;
  const [DATA, setDATA] = useState([]);
  const [DATA_REC, setDATA_REC] = useState([]);
  const [data, setData] = useState([]);
  const location = useLocation();
  const currentPage =
    parseInt(new URLSearchParams(location.search).get("page"), 10) || 1;

  useEffect(() => {
    const savedData = JSON.parse(
      localStorage.getItem("sont-commodities-search-data")
    );
    if (savedData) {
      setDATA(savedData.DATA);
      setDATA_REC(savedData.DATA_REC);
      readyForPrint = true;
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    readyForPrint && isDownload && DATA.length > 0 && handleDownloadPDF();
    readyForPrint && !isDownload && DATA.length > 0 && handlePrint();
  }, [readyForPrint]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: fileName ? fileName : "emp-data",
    onAfterPrint: () => window.close(),
  });

  const handleDownloadPDF = () => {
    const tempDiv = document.createElement("div");
    tempDiv.appendChild(componentRef.current.cloneNode(true));
    const pdfOptions = {
      margin: 10,
      filename: fileName ? fileName : "emp-data.pdf",
      // image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, dpi: 75 },
      jsPDF: { unit: "mm", format: "letter", orientation: "portrait" },
    };

    html2pdf(tempDiv, pdfOptions)
      .outputPdf()
      .then(() => {
        if (document.body?.contains(tempDiv)) {
          document.body.removeChild(tempDiv);
        }
        window.close();
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  return (
    <div className="container pt-4 pb-5">
      <div
        style={{
          display: "flex",
          position: "fixed",
          zIndex: 99999,
          background: "#fff",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <Spinner message="Loading..." className="m-auto" />
      </div>
      <div ref={componentRef} className="p-4">
        <div className="mx-4">
          <div className="border-bottom border-dark navbar">
            <div className="d-flex header-res">
              <a
                className="navbar-brand"
                id="logo-brand"
                href="https://www.woah.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo} alt="woah-logo" height="40px" />
              </a>
              <div
                style={{ height: "4rem" }}
                className="ms-4 title-res border border-right-0 border-top-0 border-bottom-0 border-dark ps-3"
              >
                <Link className="navbar-brand" id="logo-brand-pvs" to="/">
                  <h4 className="pe-4 pvs-title fw-bold py-4">
                    {t(
                      "53",
                      "Recommendations for safe International Commodity"
                    )}
                  </h4>
                </Link>
              </div>
            </div>
          </div>

          <div
            className="alert rounded-0  d-flex align-items-center mt-2"
            role="alert"
          >
            <BsInfoCircleFill
              className="bi flex-shrink-0 me-5 mb-auto text-blue-light-2"
              size={24}
            />
            <div className="fs-7 text-justify me-5">
              {t(
                "54",
                "The disease-specific chapters of the WOAH Terrestrial and Aquatic Codes recommend sanitary measures to mitigate the risk of pathogenic agents of WOAH-listed diseases, infections or infestations being introduced into an importing country through international trade of Commodities. The complete text of the Terrestrial Code is available on (link to the navigation tool)"
              )}
            </div>
          </div>
        </div>

        <div className="border-bottom border-top border-dark  py-2">
          <span className="fw-bold fs-6 pt-2">
            {t("16", "Search Criteria")}:
          </span>

          <div className="row ps-2 mb-2 ">
            <div className="col-2 mt-1">
              <span className=" fs-6 ">{t("-1", "Commodity Group")}:</span>
            </div>
            <div className="col">
              <Chip label={labels[0] ?? "All"} className="bg-blue-light-1" />
            </div>
          </div>

          <div className="row ps-2 mb-2">
            <div className="col-2 mt-1">
              <span className=" fs-6">{t("-1", "Commodity")}:</span>
            </div>
            <div className="col">
              <Chip label={labels[1] ?? "All"} className="bg-green-light-5" />
            </div>
          </div>

          <div className="row ps-2 mb-2 ">
            <div className="col-2 mt-1">
              <span className=" fs-6 ">{t("-1", "Animal")}:</span>
            </div>
            <div className="col">
              <Chip label={labels[2] ?? "All"} className="bg-light-orange" />
            </div>
          </div>
        </div>

        <div
          className="alert rounded-0 bg-alert-light-1 d-flex align-items-center my-3"
          role="alert"
        >
          <BsExclamationTriangleFill
            className="bi flex-shrink-0 me-5 mb-auto text-primary"
            size={24}
          />
          <div className="fs-7 text-justify me-5">
            The absence of recommendations on specific pathogenic agents or
            commodities does not preclude the application of appropriate
            sanitary measures by the Veterinary Authorities, provided they are
            based on risk analyses conducted in accordance with the Terrestrial
            Animal Health Code.
          </div>
        </div>

        <div className="fs-1  py-2 my-5 mx-4 mt-3">
          <h1 className="fw-bold text-center py-4">
            Filter by Disease Status at origin
          </h1>
          {DATA && DATA.length > 0 ? (
            DATA.map((d) => (
              <div
                key={`data-${d.id}`}
                className={[...(d.id % 2 ? [""] : [""])].join(" ")}
              >
                <div className="row d-flex justify-content-between">
                  <div className="col-8 pdf-heading-size pt-3 pb-2">
                    {/* {d.chapter_title} */}
                    <HtmlRenderer
                      className="document-chapitre-intitule"
                      htmlContent={d.chapter_title}
                      tag="p"

                    />
                  </div>
                  <div className="col-4 text-end fs-7 pt-3 pb-2">
                    {d.status_filters &&
                      d.status_filters.length > 0 &&
                      d.status_filters.map(
                        (filter) =>
                          filter.type_id !== 98 && (
                            <span key={`${filter.type_id}-${d.id}`}>
                              <span className="mx-2">
                                <span className="my-auto text-dark ps-1">
                                  {filter.checked ? (
                                    <i class=" fa-solid fa-check mx-1 my-auto"></i>
                                  ) : (
                                    <i class=" far fa-square mx-1 my-auto"></i>
                                  )}
                                  {filter.type_name}
                                </span>
                              </span>
                            </span>
                          )
                      )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>No data to print.</div>
          )}
        </div>

        <div className="fs-1 fw-bold py-2 my-5">
          <h1 className=" fw-bold text-center py-4 my-4">
            {t("10", "Recommendations")}
          </h1>
          {DATA && DATA.length > 0 ? (
            DATA.map((d) => (
              <div className='border border-black mt-5'>
                <div
                  key={`data-${d.id}`}
                  className={[...(d.id % 2 ? ["", ""] : ["", ""])].join(" ")}
                >
                  <div className="d-flex justify-content-between">
                    <div className="pdf-heading-size fw-bold pt-2 pb-2">
                      {/* {d.chapter_title} */}
                      <HtmlRenderer
                        tag={"p"}
                        className="ps-3 pdf-subheading-size text-dark "
                        htmlContent={d.chapter_title}
                      />
                    </div>
                  </div>
                  {d.articles &&
                    d.articles.length > 0 &&
                    d.articles.map((r) =>
                      r.is_show ? ( // Check if r.is_show is true
                        <div key={r.id} className='border-black border-top'>
                          <div className="">
                            <p className="pdf-subheading-size fw-bold text-center mt-1">
                              {r.article_name}
                            </p>
                            <div className="">
                              <HtmlRenderer
                                tag={"p"}
                                className="ps-3 pdf-subheading-size text-dark "
                                htmlContent={r.standard_content}
                              />
                            </div>
                          </div>
                          {r.content_paragraphs &&
                            r.content_paragraphs.length > 0 &&
                            r.content_paragraphs.map((t) => (
                              t.style !== 137 ? (
                                <HtmlRenderer
                                  key={`content-${t.id}`}
                                  tag={"p"}
                                  className={
                                    "pdf-text-size fw-light px-5 print text-justified"
                                  }
                                  htmlContent={t.content_text}
                                />
                              ) : null
                            ))}
                        </div>
                      ) : null
                    )}
                </div>
              </div>
            ))
          ) : (
            <div>No data to print.</div>
          )}
        </div>

        <div>
          <div
            className="alert rounded-0 bg-alert-light-1 d-flex align-items-center mt-3 mb-6"
            role="alert"
          >
            <BsExclamationTriangleFill
              className="bi flex-shrink-0 me-5 mb-auto text-primary"
              size={24}
            />
            <div className="fs-7 text-justify me-5">
              {t(
                "11",
                "These recommendations must be considered in the context of a corresponding full chapter, and in conjunction with other standards in the Code. The standards take into account the nature of the traded commodity, the animal health status of the animal population or subpopulation at origin, and the risk reduction measures applicable to each commodity, and assume compliance with the relevant horizontal chapters in Sections 1,2,3,4,5, and 6 of the Terrestrial Code."
              )}
            </div>
          </div>
          <div className="d-flex mb-10 justify-content-between botder border-top border-dark border-2">
            {currentDateTime.toLocaleString()}
            <em>
              2024 - © World Organisation for Animal Health - Terrestrial Animal
              Health Code{" "}
            </em>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PdfDetails;
