import { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomTab from 'components/common/CustomTab';
import HtmlRenderer from "components/HtmlRenderer";
import { stripHtml } from "helpers";
import { OpenInNew } from "@mui/icons-material";

const SideBarComponent = ({ data, isCode, showAction, striptHtml, returnData, handleEdit, handleDelete, handleMoveUp, handleMoveDown, handleTagging, handleAddArticle, setAddButtonClicked}) => {

    const [currentSelected, setCurrentSelected] = useState('');
    


    useEffect(() => {
        if (data.length > 0 && !currentSelected) {
            setCurrentSelected(data[0].id);
            returnData(data[0].id);
        }
    }, [data])

    return <>
        <div>
            {
                data && data.length > 0 && data.map((d, index) => (

                    <CustomTab
                        key={`tab-${d.id}`}
                        className={` ${currentSelected === d.id ? 'list-group-item-checked' : ''}`}
                        style={{ backgroundColor: d.is_updated ? "#FFC9AD" : "transparent" }}
                    >
                        <div
                            className="d-flex flex-column"
                            onClick={() => {
                                returnData(d.id);
                                setCurrentSelected(d.id);
                            }}
                        >
                            <span
                                key={`sidebar-text-${d.id}`}
                                className="d-flex justify-content-between"
                            >
                                <HtmlRenderer
                                    tag={'span'}
                                    className={'me-2'}
                                    key={d.id}
                                    htmlContent={striptHtml ? stripHtml(d.standard_content, true) : d.standard_content}

                                />
                                <span>
                                    {
                                        isCode && d?.children && d?.children.length > 0 &&
                                        <HtmlRenderer
                                            tag={"span"}
                                            className="fs-4 "
                                            style={{
                                                flexShrink: 0
                                            }}
                                            htmlContent={`${d?.children[0]?.standard_content}`}
                                        />
                                    }
                                </span>
                            </span>
                            {
                                isCode &&
                                <>
                                    {
                                        d && d?.draft_html_contents && d?.draft_html_contents.length > 0 && d?.draft_html_contents.find((i) => i.text_type === 135) &&
                                        <span>
                                            <HtmlRenderer
                                                tag={"span"}
                                                htmlContent={d?.draft_html_contents.find((i) => i.text_type === 135)?.content ?? ""}
                                            
                                            />
                                        </span>
                                    }
                                </>
                            }
                            {
                                showAction &&
                                <span className='w-100' key={`sidebar-action-${d.id}`}>
                                    <IconButton
                                        size="medium"
                                        sx={{ padding: '6px' }}
                                    >
                                        <Tooltip title="Edit" placement="top">
                                            <EditIcon
                                                onClick={() => {
                                                    setAddButtonClicked(false)
                                                    handleEdit(d.id)
                                                }}
                                                fontSize="inherit"
                                                sx={{ fontSize: '1.4rem' }}
                                            />
                                        </Tooltip>
                                    </IconButton>
                                    {
                                        isCode &&
                                        <IconButton
                                            size="medium"
                                            sx={{ padding: '6px' }}
                                        >
                                            <Tooltip title="Add Subtitle" placement="top">
                                                <AddIcon
                                                    onClick={() => { handleAddArticle(d.id) }}
                                                    fontSize="inherit"
                                                    sx={{ fontSize: '1.4rem' }}
                                                />
                                            </Tooltip>
                                        </IconButton>
                                    }
                                    <IconButton
                                        size="medium"
                                        sx={{ padding: '6px' }}
                                    >
                                        <Tooltip title="Delete" placement="top">
                                            <DeleteIcon
                                                color="action"
                                                onClick={() => handleDelete(d.id)}
                                                fontSize="inherit"
                                                sx={{ fontSize: '1.4rem' }}
                                            />
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton
                                        size="medium"
                                        sx={{ padding: '6px' }}
                                        disabled={index < 1}
                                    >
                                        <Tooltip title="Swap Up" placement="top">
                                            <KeyboardArrowUpIcon
                                                onClick={() => handleMoveUp(index)}
                                                fontSize="inherit"
                                                sx={{ fontSize: '1.4rem' }}
                                            />
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton
                                        size="medium"
                                        sx={{ padding: '6px' }}
                                        disabled={index + 1 === data.length}
                                    >
                                        <Tooltip title="Swap Down" placement="top">
                                            <KeyboardArrowDownIcon
                                                onClick={() => handleMoveDown(index)}
                                                fontSize="inherit"
                                                sx={{ fontSize: '1.4rem' }}
                                            />
                                        </Tooltip>
                                    </IconButton>
                                    {isCode && (
                                        <IconButton
                                            size="medium"
                                            sx={{ padding: '6px' }}
                                            disabled={index + 1 === data.length}
                                        >
                                            <Tooltip title="Chapter Tagging" placement="top">
                                                <OpenInNew
                                                    onClick={() => {
                                                        handleTagging(d.id);
                                                    }}
                                                    fontSize="inherit"
                                                    sx={{ fontSize: '1.4rem' }}
                                                />
                                            </Tooltip>
                                        </IconButton>
                                    )}
                                </span>
                            }
                        </div>
                    </CustomTab>
                ))
            }
        </div>
    </>
}

SideBarComponent.defaultProps = {
    data: [],
    isCode: true,
    showAction: false,
    striptHtml: true,
    returnData: () => { },
    handleEdit: () => { },
    handleDelete: () => { },
    handleMoveUp: () => { },
    handleMoveDown: () => { },
    handleTagging: () => { },
    handleAddArticle: () => { },
};

export default SideBarComponent;