import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TextFieldCustom from 'components/TextFieldCustom';
import { useEffect } from 'react';
import Container from 'components/common/Container';

const ChapterAddModel = ({ open, handleClose, reloadData, selectedChapterName, handleChapterEdit }) => {

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({ chapter_name: '', chapter_no: '' });

    // useEffect(() => {
    //     if (selectedChapterName) {
    //         setFormData((prevFormData) => ({
    //             ...prevFormData,
    //             chapter_name: selectedChapterName.name,
    //             chapter_no: selectedChapterName.refNumber,
    //         }));
    //     } else {
    //         setFormData({ chapter_name: "", chapter_no: "" });
    //     }
    // }, [selectedChapterName, setFormData]);

    useEffect(() => {
        if (open) {
            if (selectedChapterName) {
                setFormData({
                    chapter_name: selectedChapterName.name,
                    chapter_no: selectedChapterName.refNumber,
                });
            } else {
                setFormData({ chapter_name: "", chapter_no: "" });
            }
        }
    }, [open, selectedChapterName]);

    // const handleClick = () => {
    //     setIsLoading(true);
    //     if (selectedChapterName && selectedChapterName.id) {
    //         handleChapterEdit(selectedChapterName.id)
    //     } else {
    //         reloadData(formData);
    //     };
    //     setIsLoading(false);
    // }
    const handleClick = async () => {
        setIsLoading(true);
        try {
            if (selectedChapterName && selectedChapterName.RID) {
                await handleChapterEdit(selectedChapterName.RID, selectedChapterName.lang_id, formData.chapter_name);
            } else {
                await reloadData(formData);
            }
            handleClose();
        } catch (error) {
            console.error('Error during save/update:', error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Modal
                show={open} size='md' centered={true}
            >
                <Modal.Body className='p-4'>
                    <Container>
                        <Typography id="modal-modal-title" variant="h6" component="h2"
                            className="text-center"
                        >{selectedChapterName?.id ? t("-1", "Edit Chapter") : t("-1", "Add New Chapter")}
                        </Typography>
                        <div className='col-12 mb-3 fs-4 '>
                            <span className='fw-bold pe-2'>{t("-1", "Chapter No")}</span>
                            <TextFieldCustom
                                variant="outlined"
                                id="Chapter"
                                type='number'
                                disabled={selectedChapterName?.id ? true : false}
                                // label={t("-1", "English")}
                                fullWidth
                                rows={4}
                                required={true}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                    border: "#ff4815",
                                    width: "99%",
                                }}
                                size='small'
                                value={formData.chapter_no}
                                onChange={(e) => setFormData({ ...formData, chapter_no: parseInt(e.target.value) })}
                            />
                        </div>
                        <div className='col-12 mb-3 fs-4 '>
                            <span className='fw-bold pe-2'>{t("-1", "Chapter Name")}</span>
                            <TextFieldCustom
                                variant="outlined"
                                id="Chapter"
                                fullWidth
                                rows={4}
                                required={true}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                    border: "#ff4815",
                                    width: "99%",
                                }}
                                size='large'
                                multiline
                                value={formData.chapter_name}
                                onChange={(e) => setFormData({ ...formData, chapter_name: e.target.value })}
                            />
                        </div>
                    </Container>

                </Modal.Body>
                <Modal.Footer className='p-4 d-flex justify-content-end'>
                    <button
                        className='btn btn--outline'
                        onClick={() => { handleClose() }}
                    >
                        {t("-1", "Close")}
                    </button>
                    <button
                        className="btn btn--primary font-custom fs-6"
                        onClick={handleClick}
                        disabled={isLoading}
                    >
                        {isLoading
                            ? t("-1", "Saving...")
                            : t("-1", selectedChapterName?.id ? "Update" : "Save")
                        }
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ChapterAddModel
