import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ViewButton from '../components/pages/ViewButton';
import EditButton from '../components/pages/EditButton';

const CodeTabs = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authData = useSelector(s => s.auth.data);

  return (
    <>
      <div className='p-5'>
        <div className="card-title1 lh-1 border-bottom border-dark">{t("29", "Codes")}</div>
        <div className="d-flex w-100">
          <div className='d-flex justify-content-between'>
            <h5 className="pt-7">{t("32","Section")} 1 | {t("33","Chapter")} 1 | {t("34","Article")} 7.3</h5>
          </div>
          <div className="header-right-items ms-auto d-flex align-items-center ">
            <div className="ms-auto">
              <div className='ms-2'>
                {
                  [1, 2].includes(authData.role_id) ? (
                    <ViewButton />
                  ) : (
                    <EditButton />
                  )
                }
              </div>
            </div>
          </div>
          </div>
          <h6 className='d-flex justify-content-between py-2'>{t("35","Last Update")}: 05 Nov-23 13:34 PM</h6>
          <ul className='py-3'>
            <li>
            {t("36","Version")}   : 03 Nov-23 11:35 AM
            </li>
            <li>
            {t("36","Version")}  : 20 Oct-23 09:35 AM
            </li>
          </ul>
          <hr />
          <div className="d-flex w-100">
          <div className='d-flex justify-content-between'>
            <h5 className="pt-7">{t("32","Section")} 1 | {t("33","Chapter")}  1 | {t("34","Article")} 7.3</h5>
          </div>
          <div className="header-right-items ms-auto d-flex align-items-center">
            <div className="ms-auto">
              <div className='ms-2'>
                {
                  [1, 2].includes(authData.role_id) ? (
                    <ViewButton />
                  ) : (
                    <EditButton />
                  )
                }
              </div>
            </div>
          </div>
          </div>
          <h6 className='d-flex justify-content-between py-2'>{t("35","Last Update")}: 05 Nov-23 13:34 PM</h6>
          <ul className='py-3'>
            <li>
            {t("36","Version")}: 03 Nov-23 11:35 AM
            </li>
            <li>
            {t("36","Version")}: 20 Oct-23 09:35 AM
            </li>
          </ul>
        </div>
      </>
      )
}

      export default CodeTabs
