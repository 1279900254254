import ThreeDotsDropdown from "components/ThreeDotsDropdown";
import { LIST_DETAIL_ELEMENTS } from "constants";
import React from "react";

const ManualDropDownMenu = ({handleMarkasGLossaryTerm,setSelectedImageInfo,setImageContent,editRef,setImageRef,splitRef,handleMarkAsGeneralHeading ,setContentNumber,handleImageSet,handleMarkOnlineResources,handleMarkFutherReadig,handleMarkAsImage, handleMarking,handleManualIgnore,handleAsMarkBibloGrapgy,handleMarkASApendexHeading, handleMarkAsParagraph,handleMarkASCOdeEditingDate, handleMarkAsHeading,handleMarkAsParaph,handleMarkAsFooter,handleMarkAsRefernce, handleEdit,handleMarkAsAppendex ,handleRevert,updateContent, ignoreContent, splitText, index, index2,level, d, setCheckModal ,handleChangeTextType}) => {
let isHandled = d.text_type?true:false
let op;

if(level.isChild){
op = [
    (className) => (
        <div
            className={className}
            onClick={() => {
                editRef.current.handleOpen(d.html, "Edit Text", (content) => handleEdit(index, level,content), 8)
            }}   
        >
            Edit 
        </div>
),
(className) => (
    <div
        className={className}
        onClick={() => {
         setCheckModal(true)
         setImageContent(d.html?d.html:d.standard_content)
        //  setImageContent(d.offset)
         setSelectedImageInfo({
             child: true, 
             parentIndex: level.p,
             elementIndex: index
           });
        }
     }
    >
        Map Image
    </div>
),
(className) => (
        <div
            className={className}
            onClick={() => {
                handleManualIgnore(index ,level)
            }}
        >
            Ignore
        </div>
),   
]
}
else{
    if(isHandled){
        op= [
       (className) => (
               <div
                   className={className}
                   onClick={() => {
                       // editRef.current.handleOpen(d?.html, "Edit Text", (content) => handleEdit(index, level,content))
                       handleRevert(index)
                   }}
               >
                   Revert
               </div>
       ),
       (className) => (
               <div
                   className={className}
                   onClick={() => {
                       // editRef.current.handleOpen(d?.html, "Edit Text", (content) => handleEdit(index, level,content))
                       handleChangeTextType(index)
                   }}
               >
                   Change Text Type
               </div>
       ),
       (className) => (
               <div
                   className={className}
                   onClick={() => {
                    
                       editRef.current.handleOpen(d.html?d.html:d.standard_content, "Edit Text", (content) =>{ handleEdit(index, level,content,10)})
                   }}
               >
                   Edit
               </div>
       ),
       
       (className) => (
               <div
                   className={className}
                   onClick={() => {
                       // ignoreContent(index, index2);
                       handleManualIgnore(index ,level)
                   }}
               >
                   Ignore
               </div>
       ),
       ]   
       }
       else{
       
          op= [
               (className) => (
                   <div
                           className={className}
                           onClick={() => {
                               handleMarkAsHeading(index)
                           }}
                       >
                           Mark as SubHeading
                       </div>
               ),
               (className) => (
                   <div
                           className={className}
                           onClick={() => {
                               handleMarkAsParaph(index,level)
                           }}
                       >
                           Mark as Paragraph
                       </div>
               ),
               (className) => (
                   <div
                           className={className}
                           onClick={() => {
                               handleMarkAsAppendex(index,level)
                           }}
                       >
                           Mark as Appendex
                       </div>
               ),
               
               (className) => (
                   <div
                           className={className}
                           onClick={() => {
                               handleMarkAsRefernce(index,level)
                           }}
                       >
                           Mark as Refference
                       </div>
               ),
               
               (className) => (
                   <div
                           className={className}
                           onClick={() => {
                            handleAsMarkBibloGrapgy(index,level)
                           }}
                       >
                           Mark as Biblography
                       </div>
               ),
               (className) => (
                   <div
                           className={className}
                           onClick={() => {
                               handleMarkAsFooter(index,level)
                           }}
                       >
                           Mark as Footer
                       </div>
               ),
               (className) => (
                   <div
                           className={className}
                           onClick={() => {
                            handleMarkASApendexHeading(index,level)
                           }}
                       >
                           Mark as Appendex Heading
                       </div>
               ),
               (className) => (
                   <div
                           className={className}
                           onClick={() => {
                            handleMarkFutherReadig(index,level)
                           }}
                       >
                           Mark as Further Reading
                       </div>
               ),
               (className) => (
                   <div
                           className={className}
                           onClick={() => {
                            handleMarkOnlineResources(index,level)
                           }}
                       >
                           Mark as Online Resources
                       </div>
               ),
               (className) => (
                   <div
                           className={className}
                           onClick={() => {
                            handleMarkASCOdeEditingDate(index,level)
                           }}
                       >
                           Mark as Code editing date text
                       </div>
               ),
               (className) => (
                   <div
                           className={className}
                           onClick={() => {
                            handleMarkAsGeneralHeading(index,level)
                           }}
                       >
                           Mark as General Heading
                       </div>
               ),
               (className) => (
                   <div
                           className={className}
                           onClick={() => {
                            handleMarkasGLossaryTerm(index,level)
                           }}
                       >
                           Mark as Glossary Term
                       </div>
               ),
           (className) => (
                   <div
                       className={className}
                       onClick={() => {
                           editRef.current.handleOpen(d.html?d.html:d.standard_content, "Edit Text", (content) => { handleEdit(index, level,content )},10)

                       }
                    }
                   >
                       Edit
                   </div>
           ),
           (className) => (
                   <div
                       className={className}
                       onClick={() => {
                        setCheckModal(true)
                        setImageContent(d.html??d.standard_content)
                        setSelectedImageInfo({
                            child: false, 
                            parentIndex: null,
                            elementIndex: index
                          });
                       }
                    }
                   >
                       Map Image
                   </div>
           ),
           (className) => (
                   <div
                       className={className}
                       onClick={() => {
                           handleManualIgnore(index ,level)
                       }}
                   >
                       Ignore
                   </div>
           ),
       ]
       }
       
}

    return (
        <ThreeDotsDropdown
                options={op}
                className="text-primary fs-5 ms-2"
                isVertical={true}
            />
    );
}

ManualDropDownMenu.defaultProps = {
    editRef: null,
    splitRef: null,
    setContentNumber: () => {},
    handleMarking: () => {},
    handleMarkAsParagraph: () => {},
    updateContent: () => {},
    ignoreContent: () => {},
    handleHeadingMarking: () => {},
    index: null,
    index2: null,
    d: {}
}

export default ManualDropDownMenu;