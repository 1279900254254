import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Command from '@ckeditor/ckeditor5-core/src/command';
import { createDropdown, addListToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import getAncestors from '@ckeditor/ckeditor5-utils/src/dom/getancestors';

import InputTextView from '@ckeditor/ckeditor5-ui/src/inputtext/inputtextview';

export default class CustomStylePlugin extends Plugin {
    async init() {
        const editor = this.editor;

        // Access custom data from the editor configuration
        const customStyleData = editor.config.get('customStyleData');

        // Extend the schema to allow the class attribute on block elements
        editor.model.schema.extend('$block', { allowAttributes: 'class' });

        // Add upcast and downcast converters to handle class attributes
        editor.conversion.for('upcast').attributeToAttribute({
            model: 'class',
            view: 'class'
        });

        editor.conversion.for('downcast').attributeToAttribute({
            model: 'class',
            view: 'class'
        });

        // Add a custom command for applying the styles
        editor.commands.add('customStyle', new CustomStyleCommand(editor, customStyleData));

        // Add a dropdown to the toolbar for custom styles
        editor.ui.componentFactory.add('customStyles', locale => {
            const dropdownView = createDropdown(locale);
            const items = new Collection();

            // Set up the initial dropdown button view
            dropdownView.buttonView.set({
                label: 'Custom Styles',
                withText: true,
                tooltip: true,
                isOn: false, // This will track if the dropdown is selected
            });

            // Add search input to the dropdown
            const searchInputView = new InputTextView(locale);
            searchInputView.placeholder = 'Search styles...';

            // Add search functionality
            searchInputView.on('input', () => {
                const query = searchInputView.element.value.toLowerCase();
                this.filterItems(query, items, customStyleData);
            });

            // Add the search input to the dropdown panel
            dropdownView.panelView.children.add(searchInputView);

            // Populate the dropdown with the custom style data
            customStyleData.forEach(style => {
                const buttonModel = {
                    type: 'button',
                    model: new Model({
                        label: style.name,
                        withText: true,
                        commandParam: style
                    })
                };

                // Add the button model to the collection
                items.add(buttonModel);
            });

            // Use addListToDropdown to populate the dropdown
            addListToDropdown(dropdownView, items);

            // Handle item execution
            dropdownView.on('execute', evt => {
                const selectedStyle = evt.source.commandParam; // Get the selected style
                editor.execute('customStyle', selectedStyle);
                editor.editing.view.focus(); // Refocus the editor
            });

            // Refresh the dropdown state based on the current selection
            editor.commands.get('customStyle').on('change:isEnabled', () => {
                const command = editor.commands.get('customStyle');
                dropdownView.buttonView.set({
                    label: command.value,
                    isOn: command.isStyleApplied // Update dropdown state to reflect the applied class
                });
            });

            return dropdownView;
        });
    }

    // Method to filter items based on search query
    filterItems(query, items, customStyleData) {
        items.clear(); // Clear the existing items
        customStyleData
            .filter(style => style.name.toLowerCase().includes(query))
            .forEach(filteredStyle => {
                const buttonModel = {
                    type: 'button',
                    model: new Model({
                        label: filteredStyle.name,
                        withText: true,
                        commandParam: filteredStyle
                    })
                };
                items.add(buttonModel);
            });
    }
}

class CustomStyleCommand extends Command {
    constructor(editor, styles) {
        super(editor);
        this.styles = styles; // Store the custom styles data
    }

    execute(style) {
        const editor = this.editor;
        const model = editor.model;
        const selection = model.document.selection;

        // Apply the selected style class to the current selection
        model.change(writer => {
            const blocks = selection.getSelectedBlocks();
            for (const block of blocks) {
                
                const classesList = this.filterClasses(style.classes);

                // Apply the new class to the block
                writer.setAttribute('class', classesList.join(' ')+' '+'custom-classes-'+style.id, block);
            }
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const blocks = selection.getSelectedBlocks();

        let isStyleApplied = false;
        let currentValue = 'Custom Styles';

        // Check if the selected blocks have the style applied
        for (const block of blocks) {
     
            const currentClass = block.getAttribute('class') || '';

            // Remove other classes
            let classesList = currentClass.split(' ');
                
            const filteredClasses = classesList.filter(className => className.startsWith('custom-classes-'));

            if (filteredClasses.length > 0) {

            const menuId = filteredClasses.join('').replace('custom-classes-', '');
            // Store the custom class in a variable or use it as needed
            const currentStyle = this.styles.find( style => style.id === Number(menuId) );

            if(currentStyle) {
                currentValue = currentStyle.name;
                isStyleApplied = true;
            }   
         }
        }

        this.isStyleApplied = isStyleApplied;
        this.value = currentValue;
        this.isEnabled = model.schema.checkAttributeInSelection(selection, 'class');
    }

    filterClasses(classLists) {

                // Split the selectors by commas
        const classNames = classLists.split(',').map(selector => {
        // Extract the class name by splitting at the period (.)
        return selector.split('.')[1];
        });

        // Remove duplicates, if any
        return [...new Set(classNames)];
    }
}
