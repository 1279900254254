import { TRANSFER_STATUS } from 'constants';
import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const EditButton = ({status}) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const authData = useSelector(s => s.auth.data);
    const [currentTab, setCurrentTab] = useState('codes');

    const getStatusChip = (status) => {
        switch (status) {
            case 4:
                return "Published";
                // <CustomChi label="Published" type="primary" />;
            case 3:
                return "Pending Published";
                // <CustomChip label="Pending Publication" type="orange" />;
                
            case 2:
                return "Pending Validation"
                // <CustomChip label="Pending Validation" type="blue" />;
            default:
                return "Draft";
                // <CustomChip label="Draft" type="green" />;
        }
    };

    const handleReturnStatus = (arr = []) => {
        let status = arr.find((i) => i.transfer_status === TRANSFER_STATUS.publishing);
        if (status) {
            console.log("Status is:", status);
            return 3;

        }
        status = arr.find((i) => i.transfer_status === TRANSFER_STATUS.validation);
        if (status) {
            console.log("Status is:", status);
            return 2;
        } else {
            console.log("Status is:", status);
            return 1;
        }

    }
    handleReturnStatus();
    return (
        <div>
            <p className='mt-6'
                onClick={() => setCurrentTab('codes')}
            >
                <span className="text-dark fw-bold fs-7 me-2">
                {t("-1", "Status:")}
                </span>
                <span  className="text-primary fs-7">
                 {getStatusChip(handleReturnStatus(status ?? []))}
                </span>
            </p>
        </div>
    )
}

export default EditButton
