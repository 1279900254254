import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { displayNotification, getBaseURL } from '../../../../helpers';
import { sendAPIRequest } from '../../../../helpers';
import { useDispatch } from 'react-redux';
import { setHeaderTheme } from '../../../../redux/generalSlice';
import { useTranslation } from "react-i18next";
import TextFieldCustom from 'components/TextFieldCustom';

const ResetPassword = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isWorking, setIsWorking] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [isTokenValidated, setIsTokenValidated] = useState(true);

    useEffect(() => {
        validateToken();
        dispatch(setHeaderTheme('white'));
    }, []);

    const validateToken = async () => {
        const token = searchParams.get('token');
        if (token) {
            try {
                const ret = await sendAPIRequest(`/api/auth/validate-otp/?token=${token}`);
                const res = await ret.json();
                if (res.success) {
                    setIsTokenValidated(true);
                } else {
                    setIsTokenValidated(false);
                }
            } catch (e) {
                console.log({e});
                setIsTokenValidated(false);
            }
        } else {
            setIsTokenValidated(false);
        }
    }

    const resetPassword = async () => {
        setIsWorking(true);
        try {
            const token = searchParams.get('token');
            const ret = await sendAPIRequest(
                `/api/auth/profile/reset-password/?token=${token}`,
                'POST',
                {
                    password,
                    confirm_password: confirmPassword,
                }
            );
            const res = await ret.json();
            if (res.success) {
                if (res.status == 500) {
                    displayNotification('error', `Password didn't matched. Please try again [B]`);
                } else {
                    setIsWorking(false);
                    displayNotification('success', 'Password updated successfully [B]');
                    navigate('/portal/login');
                }
            } else {
                displayNotification('error', `Failed to update password. Please try again [B]`);
            }
        } catch (e) {
            console.log({e});
            displayNotification('error', `Failed to update password. Please try again [F]`);
        }
        setIsWorking(false);
    }

    return (
        <div className='py-20 ' style={{
            backgroundImage: `url(${getBaseURL("/assets/icons/reset-password.jpg")})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: "center",
        }}>
            <div className='container py-20 min-vh-50'
            >
                <div className="row">
                    <div className="col-md-4 offset-md-4 col-sm-6 offset-sm-3 col-12">
                        <div className="card bg-login d-flex">
                            <div className="card-body bg-white border shadow shadow-lg rounded  px-17 py-12" style={{
                                backgroundImage: `url(${getBaseURL("/assets/icons/Reset-Password.png")})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '80%',
                                backgroundPosition: "10px -250px",
                            }}>
                                <h1 className="text-center mb-3 text-dark">RESET PASSWORD</h1>
                                {
                                    isTokenValidated ? (
                                        <>
                                            <div className="form-group row mb-3">
                                                <div className="col-12">
                                                    <TextFieldCustom
                                                        type="password"
                                                        label="Re-enter Password"
                                                        variant="outlined"
                                                        className='form-control'
                                                        fullWidth
                                                        size='small'
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-12">
                                                    <TextFieldCustom
                                                        type="password"
                                                        label="Confirm Password"
                                                        variant="outlined"
                                                        className='form-control'
                                                        fullWidth
                                                        size='small'
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="pt-12">
                                                <button
                                                    className="btn1 w-100"
                                                    disabled={isWorking}
                                                    onClick={() => resetPassword()}
                                                >
                                                    {isWorking ? t("901", "Please Wait") + '...' : t("258", "Reset") + " " + t("661", "Password")}
                                                </button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="form-group row">
                                                <div className="col-12">
                                                    <div className='text-center fs-4'>This link is expired.</div>
                                                </div>
                                            </div>
                                            <div className="pt-12 text-center">
                                                <Link
                                                    className="menu-link"
                                                    to={"/forgotpassword"}
                                                    style={{
                                                    borderRadius: "0%",
                                                    marginLeft: "0%",
                                                    marginRight: "0%",
                                                    }}
                                                >
                                                    <button className="btn rounded-0 text-white btn-dark btn-bg-dark fs-7 lh-1">
                                                    <i className="fas fa-angle-left" />
                                                    {t("32", "Back")}
                                                    </button>
                                                </Link>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
