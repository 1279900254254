import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { displayNotification, getBaseURL, sendAPIRequest } from 'helpers';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import Spinner from 'components/spinner/Spinner';
import { Box } from '@mui/system';
import HtmlRenderer from 'components/HtmlRenderer copy';
import { useTranslation } from "react-i18next";
import { setCurrentPage, setHeaderTheme } from 'redux/generalSlice';
import { Divider, Typography } from '@mui/material';

function DraftChapterPreview() {
    const [location] = useSearchParams();
    const dispatch = useDispatch();
    const [chapterData, setChapterData] = useState('');
    const { chapter_id } = {
        chapter_id: location.get('chapter_id'),
    };
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState({
        loading_data: false,
    });

    useEffect(() => {
        dispatch(setHeaderTheme('white'));
        dispatch(setCurrentPage("draft-management"));
    }, []);

    useEffect(() => {
        getChapterData();
    }, [])

    const getChapterData = async () => {
        setIsLoading({ ...isLoading, loading_data: true });
        try {
            let ret;
            ret = await sendAPIRequest(`/api/draft-content/draft-preview/${chapter_id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setChapterData(res.data);
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Failed to load chapter data [F]');
        }
        setIsLoading({ ...isLoading, loading_data: false });
    }

    console.log('test', chapterData)

    return (
        <>
            <div className="container">
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    my={2}
                    width="100%"
                >
                    {isLoading.loading_data ? (
                        <Spinner message={t('98', 'Loading Data, Please wait...')} />
                    ) : (
                        chapterData && chapterData.children && chapterData.children.length > 0 ? (
                            <>
                                <Box key={`section-${chapterData.parent?.id}`}>
                                    <Typography
                                        textAlign="center"
                                        pt={2}
                                        style={{
                                            letterSpacing: '2px',
                                            textTransform: 'uppercase'
                                        }}
                                        key={chapterData.parent.id}
                                    >
                                        {
                                            chapterData.parent.standard_type == LIST_DETAIL_ELEMENTS.standard_type.codes ? (
                                                `Section ${chapterData.parent?.section_no}`
                                            ) : (
                                                `Section ${chapterData.parent?.area_vol_no}.${chapterData.parent?.section_no}`
                                            )
                                        }
                                    </Typography>
                                    <Typography
                                        className="chapter-heading text-black text-center"
                                        key={`name-${chapterData.parent?.chapter_name}`}
                                        style={{
                                            letterSpacing: '2px',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        <span className='fs-6'>
                                            {chapterData.parent?.section_name}
                                        </span>
                                    </Typography>
                                </Box>
                                <Divider
                                    variant="middle"
                                    sx={{
                                        width: '50%',
                                        margin: 'auto',
                                        marginTop: '16px',
                                        marginBottom: '6px',
                                        backgroundColor: '#000',
                                    }}
                                />
                                <Divider
                                    variant="middle"
                                    sx={{
                                        width: '50%',
                                        margin: 'auto',
                                        backgroundColor: '#000',
                                    }}
                                />
                                <Box key={`chapter-${chapterData.id}`}>
                                    <Typography
                                        my={0}
                                        py={0}
                                        textAlign="center"
                                        p={3}
                                        style={{
                                            letterSpacing: '2px',
                                            textTransform: 'uppercase'
                                        }}
                                        key={chapterData.id}
                                    >
                                        {
                                            chapterData.parent.standard_type == LIST_DETAIL_ELEMENTS.standard_type.codes ? (
                                                `Chapter ${chapterData.parent?.section_no}.${chapterData.parent?.chapter_no}`
                                            ) : (
                                                `Chapter ${chapterData.parent?.area_vol_no}.${chapterData.parent?.section_no}.${chapterData.parent?.chapter_no}`
                                            )
                                        }
                                    </Typography>
                                    <Typography
                                        textAlign="center"
                                        style={{
                                            letterSpacing: '2px',
                                            textTransform: 'uppercase'
                                        }}
                                        className="chapter-heading text-center pb-5"
                                        key={`name-${chapterData.id}`}
                                    >
                                        {chapterData.parent?.chapter_name}
                                    </Typography>
                                </Box>
                                {chapterData.children && chapterData.children.length > 0 && chapterData.children.map(d => (
                                    <Box
                                        key={`article-${d.id}`}
                                        id={`article-${d.id}`}
                                        data-myattribute={d.ref_number}
                                        className={'w-100'}
                                    >
                                        <HtmlRenderer
                                            tag={"div"}
                                            className="text-center py-4 fs-5"
                                            htmlContent={d?.standard_content}
                                        />
                                        {d.draft_html_contents && d.draft_html_contents.length > 0 ? (
                                            d.draft_html_contents.map((child) => (
                                                <Typography
                                                    className="px-4 text-justified"
                                                    key={`art-child-${child.id}`}
                                                >
                                                    <HtmlRenderer
                                                        tag={"div"}
                                                        htmlContent={child.content}
                                                    />
                                                </Typography>
                                            ))
                                        ) : (
                                            d.children && d.children.map(child => (
                                                <Box key={`general-${child.id}`} id={`${child.id}`}>
                                                    <HtmlRenderer
                                                        tag={"div"}
                                                        className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === child.text_type ? "py-4 fw-bold" : "py-4"}
                                                        htmlContent={child?.standard_content}
                                                    />
                                                    {child.draft_html_contents && child.draft_html_contents.length > 0 && child.draft_html_contents.map((c, i) => (
                                                        <Box
                                                            className={`d-flex ${c.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && 'justify-content-end pt-3'}`}
                                                        >
                                                            <Typography className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === c.text_type ? "text-justified" : 'justify-content-end'}>
                                                                <HtmlRenderer
                                                                    tag={LIST_DETAIL_ELEMENTS.heading_types.general_heading === c.text_type ? "div" : "p"}
                                                                    htmlContent={c.content}
                                                                />
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            ))
                                        )}
                                    </Box>
                                ))}
                            </>
                        ) : (
                            <div>NO Data Found</div>
                        )
                    )}
                </Box>
            </div>
        </>
    );
}

export default DraftChapterPreview
