import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import HeadingCommand from '@ckeditor/ckeditor5-heading/src/headingcommand';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import InputTextView from '@ckeditor/ckeditor5-ui/src/inputtext/inputtextview';
import { ViewModel, createDropdown, addListToDropdown, MenuBarMenuListItemView, MenuBarMenuListView, MenuBarMenuView, MenuBarMenuListItemButtonView } from 'ckeditor5/src/ui.js';
import Command from '@ckeditor/ckeditor5-core/src/command';


export default class CustomHeadingPlugin extends Heading {
    async init() {
        const editor = this.editor;

        // Access custom data from the editor configuration
        const customStyleData = [   ...[{
            id: Math.floor(Math.random() * 10000),
            name: 'Paragraph',
            classes: 'ck-heading_paragraph',
            tag: 'paragraph',
            title: 'Paragraph'
        },{
            id: Math.floor(Math.random() * 10000),
            name: 'Large Heading',
            tag: 'heading1',
            classes: 'p.ck-heading_heading1',
            title: 'Heading 1'
        },{
            id: Math.floor(Math.random() * 10000),
            name: 'Medium Heading',
            tag: 'heading2',
            classes: 'ck-heading_heading2',
            title: 'Heading 2'
        },{
            id: Math.floor(Math.random() * 10000),
            name: 'Small Heading',
            classes: 'ck-heading_heading3',
            tag: 'heading3',
            title: 'Heading 3'
        }], ...editor.config.get('customStyleData') ];

        // Extend the schema to allow the class attribute on block elements
        editor.model.schema.extend('$block', { allowAttributes: 'class' });

        // Add upcast and downcast converters to handle class attributes
        editor.conversion.for('upcast').attributeToAttribute({
            model: 'class',
            view: 'class'
        });

        editor.conversion.for('downcast').attributeToAttribute({
            model: 'class',
            view: 'class'
        });

        editor.commands.add('customStyle', new CustomStyleCommand(editor, customStyleData));

        // Add a custom dropdown for styles
        this._addCustomStyleDropdown(customStyleData);
    }

    _addCustomStyleDropdown(customStyleData) {
        const editor = this.editor;

        // Add the custom styles dropdown
        editor.ui.componentFactory.add('customStyles', locale => {
            const dropdownView = createDropdown(locale);
            const items = new Collection();

            // Set up the initial dropdown button view
            dropdownView.buttonView.set({
                label: 'Custom Styles',
                withText: true,
                tooltip: true
            });

            // Add search input to the dropdown
            const searchInputView = new InputTextView(locale);
            searchInputView.placeholder = 'Search styles...';
            searchInputView.on('input', () => {
                const query = searchInputView.element.value.toLowerCase();
                this._filterItems(query, items, customStyleData);
            });

            // Add search input to the dropdown panel
            dropdownView.panelView.children.add(searchInputView);

            // Populate the dropdown with custom style data
            customStyleData.forEach(style => {
                const buttonModel = new Model({
                    label: style.name,
                    withText: true,
                    commandParam: style
                });
                items.add({
                    type: 'button',
                    model: buttonModel
                });
            });

            // Add list to the dropdown
            addListToDropdown(dropdownView, items);

            // Render panel view and set max height for scrolling
            dropdownView.render();
            if (dropdownView.panelView.element) {
                dropdownView.panelView.element.style.maxHeight = '200px';
                dropdownView.panelView.element.style.overflowY = 'auto';
            }

            dropdownView.on('execute', evt => {
                const selectedStyle = evt.source.commandParam; // Get the selected style
                editor.execute('customStyle', selectedStyle);
                editor.editing.view.focus(); // Refocus the editor
            });

            // Refresh the dropdown state based on the current selection
            editor.commands.get('customStyle').on('change:isEnabled', () => {
                const command = editor.commands.get('customStyle');
                dropdownView.buttonView.set({
                    label: command.value,
                    isOn: command.isStyleApplied // Update dropdown state to reflect the applied class
                });
            });

            return dropdownView;
        });
    }

    _filterItems(query, items, customStyleData) {
        items.clear();
        customStyleData
            .filter(style => style.name.toLowerCase().includes(query))
            .forEach(style => {
                const buttonModel = new Model({
                    label: style.name,
                    withText: true,
                    commandParam: style
                });
                items.add({
                    type: 'button',
                    model: buttonModel
                });
            });
    }
}


class CustomStyleCommand extends Command {
    constructor(editor, styles) {
        super(editor);
        this.styles = styles; // Store the custom styles data
    }

    execute(style) {
        const editor = this.editor;
        const model = editor.model;
        const selection = model.document.selection;

        model.change(writer => {
            const blocks = selection.getSelectedBlocks();

            for (const block of blocks) {
                // Extract tag and class information from style
                const tagAndClasses = this.extractTagAndClasses(style);
                const tag = tagAndClasses.tag;
                const classesList = tagAndClasses.classes;

                // Rename the block if a tag is specified
                if (tag) {
                    writer.rename(block, tag);
                }

                // Apply the class to the block
                writer.setAttribute('class', classesList.join(' ') + ' custom-classes-' + style.id, block);
            }
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const blocks = selection.getSelectedBlocks();

        let isStyleApplied = false;
        let currentValue = 'Custom Styles';

        // Check if the selected blocks have the style applied
        for (const block of blocks) {
            const currentClass = block.getAttribute('class') || '';

            // Remove other classes
            let classesList = currentClass.split(' ');

            const filteredClasses = classesList.filter(className => className.startsWith('custom-classes-'));

            if (filteredClasses.length > 0) {
                const menuId = filteredClasses.join('').replace('custom-classes-', '');
                const currentStyle = this.styles.find(style => style.id === Number(menuId));

                if (currentStyle) {
                    currentValue = currentStyle.name;
                    isStyleApplied = true;
                }
            }
        }

        this.isStyleApplied = isStyleApplied;
        this.value = currentValue;
        this.isEnabled = model.schema.checkAttributeInSelection(selection, 'class');
    }

    // Helper method to extract the tag and classes
    extractTagAndClasses(style) {
        const selectors = style.classes.split(',');

        // The tag is the first part of the selector, before the dot (.)
        const tag = style.tag ?? 'paragraph';

        // Extract the class names (everything after the dot)
        const classes = selectors.map(selector => selector.split('.')[1]).filter(Boolean);

        return { tag, classes };
    }
}