import { API_URL, BASE_URL, RECOMMENDATION_STATUS_TYPES, LIST_DETAIL_ELEMENTS } from "constants";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import 'animate.css/animate.min.css';
import dayjs from "dayjs";
import moment from "moment";
import { lazy } from "react";
import { store } from 'redux/store';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MANUAL_HEADINGS } from "constants";
import DOMPurify from "dompurify";
import { cloneDeep } from "lodash";
const i18nIsoCountries = require("i18n-iso-countries");

const getCountryISO2 = (iso3) => {
  const countries = i18nIsoCountries.getAlpha3Codes();
  return String(countries[iso3]).toLowerCase();
}

// export const getCountry = (user, complete_name = false) => {
//     let country_name = '';
//     if (COUNTRY_MAPPING[user]) {
//         country_name = complete_name ? `${COUNTRY_MAPPING[user]?.country_name} - ${COUNTRY_MAPPING[user]?.country_code}` : COUNTRY_MAPPING[user]?.country_name;
//     }
//     return country_name;
// }

export const getHeaderHeight = () => {
  return document.getElementsByClassName("bob")[0].getBoundingClientRect().height;
}
export const getStatusType = (id) => {
  const statusCategories = RECOMMENDATION_STATUS_TYPES;
  for (const status in statusCategories) {
    if (statusCategories[status] == id) {
      return status;
    }
  }
};

// export const getCountryLabelForDelgFP = (user) => {
//     let country_name = `${user.country_name} (${user?.person?.country?.country_code})`;
//     if (COUNTRY_MAPPING[user.name]) {
//         country_name = `${COUNTRY_MAPPING[user.name]?.country_name} (${COUNTRY_MAPPING[user.name]?.country_code})` ;
//     }
//     return country_name;
// }

// export const getCountryFlagURL = (country_code, size='h120') => {
//     const countries = Object.values(COUNTRY_MAPPING).map(r => ({ country_code: r.country_code, flag: r.flag }));
//     let x;
//     if (x = countries.find(r => r.country_code == country_code)) {
//         return x.flag;
//     } else {
//         return `https://flagcdn.com/${size}/${getCountryISO2(country_code)}.png`;
//     }
// }

export const getAPIURL = (url = '') => {
  return `${API_URL}${url}`;
};

export const getBaseURL = (url = '') => {
  return `${BASE_URL}${url}`;
};

export const isEmpty = (obj) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

export const isEmptyObject = (value) => {
  if (value == null) {
    // null or undefined
    return false;
  }

  if (typeof value != 'object') {
    // boolean, number, string, function, etc.
    return false;
  }

  const proto = Object.getPrototypeOf(value);

  // consider `Object.create(null)`, commonly used as a safe map
  // before `Map` support, an empty object as well as `{}`
  if (proto != null && proto != Object.prototype) {
    return false;
  }

  return isEmpty(value);
}

export const sendAPIRequest = (url, method = 'GET', body = '', additionalHeaders = {}, ACObj = null) => {
  const authToken = store.getState().auth.accessToken;
  let contentType = {};
  if (body != '' && typeof body == 'object' && !(body instanceof FormData)) {
    contentType = { 'Content-Type': 'application/json' };
  }
  let options = {
    method,
    headers: {
      ...(authToken && authToken != '' ? { 'Authorization': `Bearer ${authToken}` } : {}),
      'Accept': 'application/json',
      ...(body != '' ? contentType : {}),
      ...additionalHeaders
    }
  };
  if (body != '') {
    if (body instanceof FormData) {
      options.body = body;
    } else if (typeof body == 'object') {
      options.body = JSON.stringify(body);
    } else {
      options.body = body;
    }
  }
  if (ACObj) {
    options.signal = ACObj;
  }
  return fetch(getAPIURL(url), options);
}

const MySwal = withReactContent(Swal);

const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  }
});

export const displayNotification = (type, text) => {
  let icon = type; // type should match the Swal icons (success, error, warning, info)
  Toast.fire({
    icon,
    title: text
  });
};

// export const displayNotification = (type, text, layout = "bottomRight") => {
//   let animation = {
//     open: 'animate__animated animate__fadeInRight', // Animate.css class names
//     close: 'animate__animated animate__fadeOutRight' // Animate.css class names
//   };
//   if (layout == "bottomRight") {
//     animation = {
//       open: 'animate__animated animate__slideInRight',
//       close: 'animate__animated animate__slideOutRight'
//     }
//   }
//   const noty = new Noty({
//     type,
//     text,
//     layout,
//     // theme: 'bootstrap-v5',
//     timeout: 5000,
//     animation,
//     closeWith: ['click', 'button'],
//   });
//   // noty.setTimeout(1500);
//   noty.show();
// }

export const generateOptionsFromData = (
  data,
  mappings = { value: "value", label: "label", group_value: "group_value" },
  useKeyValue = false,
  isGrouped = false,
  group_label = {}
) => {
  let ret = [];
  if (useKeyValue) {
    for (const [value, label] of Object.entries(data)) {
      ret.push({ value, label });
    }
  } else {
    for (const r of data) {
      ret.push({
        value: r[mappings.value],
        label:
          typeof mappings.label != "string"
            ? r[mappings.label[0]]
              ? r[mappings.label[0]][mappings.label[1]]
              : ""
            : r[mappings.label],
        group_value: isGrouped
          ? String(group_label[r[mappings.group_value]] ?? "").toUpperCase()
          : "",
        ref_no: mappings.hasOwnProperty('ref_no') ? r[mappings.ref_no] : null,
        id: mappings.hasOwnProperty('id') ? r[mappings.id] : null
      });
    }
  }
  return ret;
};

export const generateUniqueId = () => {
  const timestamp = Date.now(); // Get the current timestamp
  const randomNum = Math.floor(Math.random() * 1000000); // Generate a random number
  return `${timestamp}-${randomNum}`; // Combine them to create a unique ID
}

export const toggleValueInArray = (arr = [], val = '') => {
  if (arr.includes(val)) {
    return arr.filter(v => v != val);
  } else {
    return [...arr, val];
  }
}

export const getDashboardTranslation = (language_id) => {
  if (language_id === 4) {
    return "%20and%20Translation/Translation%20eq%20%27ES%27"
  } else if (language_id === 5) {
    return "%20and%20Translation/Translation%20eq%20%27FR%27"
  } else {
    return "%20and%20Translation/Translation%20eq%20%27EN%27"
  }
}

export const handleSideBarContent = (data = [], isManual = false, animalType, standardType) => {
  const clonedSidebarData = cloneDeep(data);
  let sidebarData = [];
  if (isManual) {
    sidebarData = clonedSidebarData.filter(
      (i) =>
        i.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_heading ||
        i.text_type === LIST_DETAIL_ELEMENTS.text_types.article_heading ||
        i.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_reference ||
        i.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_Further_reading ||
        i.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_appendex ||
        i.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_online_resource ||
        i.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_biblography ||
        i.text_type === LIST_DETAIL_ELEMENTS.text_types.glossary_term
    );
  } else {
    sidebarData = clonedSidebarData.filter(
      (i) =>
        i.text_type === LIST_DETAIL_ELEMENTS.text_types.article_heading ||
        i.text_type === LIST_DETAIL_ELEMENTS.text_types.glossary_term ||
        MANUAL_HEADINGS.includes(parseInt(i.text_types))
    );
  }
  if (sidebarData && sidebarData.length > 0) {
    for (let x of sidebarData) {
      let t = DOMPurify.sanitize(x.standard_content);
      if (animalType === LIST_DETAIL_ELEMENTS.animal_type.aquatic && standardType === LIST_DETAIL_ELEMENTS.standard_type.manuals ) {
        x.standard_content = t.replace(
          /class="([^"]*)"/g,
          'class="aquatic-manual-article"'
        );
      }
      else if (animalType === LIST_DETAIL_ELEMENTS.animal_type.terrestrial && standardType === LIST_DETAIL_ELEMENTS.standard_type.manuals ) {
        x.standard_content = t.replace(
          /class="([^"]*)"/g,
          'class="manual-article"'
        );
      }
    }
  }
  return sidebarData;
};

export const getFormattedDate = (val, format = "YYYY-MM-DD", defaultValue = '') => {
  if (val != null) {
    if (moment.isMoment(val) || dayjs.isDayjs(val)) {
      return val.format(format);
    } else {
      return moment(val).format(format);
    }
  } else {
    return defaultValue;
  }
}

export const lazyRetry = function (componentImport, name) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
    );
    // try to import the component
    componentImport().then((component) => {
      window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed) { // not been refreshed yet
        window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
        return window.location.reload(); // refresh the page
      }
      reject(error); // Default error behaviour as already tried refresh
    });
  });
};

export const reactLazyImport = (path, name) => {
  return lazy(() => lazyRetry(() => import(`.${path}`), name));
}

export const empty = (val) => val == '' || val == '-' || val == undefined || val == null;

export const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};

export const FirstLetterCapital = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(" ");
};

//Imported from WP-Plugin library
export const handleReplaceUrlForChapterLinking = (data = []) => {
  if (data.length > 0) {
    for (let x of data) {
      if (x.html_contents && x.html_contents.length > 0) {
        x.html_contents.map((d, k) => {
          if (d.html) {
            if (d.html.includes("get-chpater-preview")) {
              d.html = d.html.replaceAll(
                "get-chpater-preview",
                getBaseURL(`/get-chapter-preview`)
              );
            } else if (d.html.includes("get-chapter-preview")) {
              d.html = d.html.replaceAll(
                "get-chapter-preview",
                getBaseURL(`/get-chapter-preview`)
              );
            }
          } else {
            if (d.content.includes("get-chpater-preview")) {
              d.content = d.content.replaceAll(
                "get-chpater-preview",
                getBaseURL(`/get-chapter-preview`)
              );
            } else if (d.content.includes("get-chapter-preview")) {
              d.content = d.content.replaceAll(
                "get-chapter-preview",
                getBaseURL(`/get-chapter-preview`)
              );
            }
          }
        });
      } else if (x.child) {
        if (x.child.html_contents && x.child.html_contents.length > 0) {
          x.child.html_contents.map((d, k) => {
            if (d.content.includes("get-chpater-preview")) {
              d.content = d.content.replaceAll(
                "get-chpater-preview",
                getBaseURL(`/get-chapter-preview`)
              );
            } else if (d.content.includes("get-chapter-preview")) {
              d.content = d.content.replaceAll(
                "get-chapter-preview",
                getBaseURL(`/get-chapter-preview`)
              );
            }
          });
        }
      }
    }
  } else if (data && data.children.length > 0) {
    for (let y of data.children) {
      if (y.html_contents && y.html_contents.length > 0) {
        y.html_contents.map((d, k) => {
          if (d.content.includes("get-chpater-preview")) {
            d.content = d.content.replaceAll(
              "get-chpater-preview",
              getBaseURL(`/get-chapter-preview`)
            );
          } else if (d.content.includes("get-chapter-preview")) {
            d.content = d.content.replaceAll(
              "get-chapter-preview",
              getBaseURL(`/get-chapter-preview`)
            );
          }
        });
      } else if (y.children && y.children.length > 0) {
        for (let z of y.children) {
          if (z.html_contents && z.html_contents.length > 0) {
            z.html_contents.map((d, k) => {
              if (d.content.includes("get-chpater-preview")) {
                d.content = d.content.replaceAll(
                  "get-chpater-preview",
                  getBaseURL(`/get-chapter-preview`)
                );
              } else if (d.content.includes("get-chapter-preview")) {
                d.content = d.content.replaceAll(
                  "get-chapter-preview",
                  getBaseURL(`/get-chapter-preview`)
                );
              }
            });
          } else if (y.child) {
            if (y.child.html_contents && y.child.html_contents.length > 0) {
              y.child.html_contents.map((d, k) => {
                if (d.content.includes("get-chpater-preview")) {
                  d.content = d.content.replaceAll(
                    "get-chpater-preview",
                    getBaseURL(`/get-chapter-preview`)
                  );
                } else if (d.content.includes("get-chapter-preview")) {
                  d.content = d.content.replaceAll(
                    "get-chapter-preview",
                    getBaseURL(`/get-chapter-preview`)
                  );
                }
              });
            }
          }
        }
      }
    }
  }

  return data;
};

export const getStyleName = (id) => {
  const stylesCategories = LIST_DETAIL_ELEMENTS.styles_categories;
  for (const style in stylesCategories) {
    if (stylesCategories[style] == id) {
      return style;
    }
  }
};

let tokenKey = 'accessToken';
let tokenExpirationDate = 'tokenExpirationDate';
const apiURL = 'https://api.docconverter.pro';

const isTokenValid = () => {
  const token = localStorage.getItem(tokenKey);
  if (token !== null) {
    const tokenExpDateString = localStorage.getItem(tokenExpirationDate);
    if (tokenExpDateString !== null) {
      let now = new Date();
      let tokenExpDate = new Date(tokenExpDateString);
      if (tokenExpDate > now) {
        return true;
      }
    }
  }
  return false;
}

export const getDocConverterToken = async () => {
  try {
    const body = new URLSearchParams({
      grant_type: 'password',
      username: process.env.REACT_APP_DOCX_EMAIL,
      password: process.env.REACT_APP_DOCX_PASSWORD,
    });
    const response = await fetch(apiURL + '/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: body,
    });
    const data = await response.json();
    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const getBearerToken = async () => {
  if (!isTokenValid()) {
    const tokenData = await getDocConverterToken();
    localStorage.setItem(tokenKey, tokenData.access_token);
    let tokenExpDate = new Date();
    let now = new Date();
    tokenExpDate.setSeconds(now.getSeconds() + tokenData.expires_in);
    localStorage.setItem(tokenExpirationDate, tokenExpDate);
  }
}

export const processFile = async (file, template = 'Convert to DOCX', returnHtml = false, returnData = false, returnJson = false, isPostBack = true, backUrl = 'http://ReceivePostFile/Default.aspx') => {
  const token = localStorage.getItem(tokenKey);
  if (!token) {
    throw new Error("Invalid Token");
  }
  let formData = new FormData();
  formData.append('template', template);
  formData.append('returnHtml', returnHtml);
  formData.append('returnData', returnData);
  formData.append('returnJson', returnJson);
  if (isPostBack) {
    // formData.append('backUrl', backUrl);
  }
  formData.append('file', file);
  try {
    const response = await fetch(apiURL + '/api/converter/convertdoc', {
      method: "POST",
      headers: {
        //"Content-Type": 'multipart/form-data',
        "Authorization": 'Bearer ' + token
      },
      cache: "no-cache",
      body: formData,
    });
    const result = await response.text();
    if (returnJson) {
      return result;
    }
    let a = document.createElement('a');
    a.href = result;
    a.innerText = result;
    a.target = '_blank';
    document.body.append(a);
    a.click();
    document.body.removeChild(a);
  }
  catch (error) {
    console.error("Error: ", error);
  }
}


export const objectToFormData = (obj, form = new FormData(), namespace = '') => {
  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      let formKey = namespace ? `${namespace}[${property}]` : property;

      // Check if the property is an array
      if (Array.isArray(obj[property])) {
        obj[property].forEach((value, index) => {
          let arrayKey = `${formKey}[${index}]`;
          if (typeof value === 'object' && !(value instanceof File)) {
            objectToFormData(value, form, arrayKey);
          } else {
            form.append(arrayKey, value);
          }
        });
      } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        // Check if the property is an object (but not a File)
        objectToFormData(obj[property], form, formKey);
      } else {
        // Otherwise, directly append the value
        form.append(formKey, obj[property]);
      }
    }
  }
  return form;
}

/**
 * This function is used to stript html form elements
 * @param {*} content 
 * @param {*} is_upper 
 * @returns 
 */
export const stripHtml = (content, is_upper = false) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = content;
  const tmp = tempElement.textContent;
  content = tmp.trim();
  return is_upper ? content.toUpperCase() : content;
}

/**
 * This function is used to sort array of objects against specific key in ascending order
 * @param {*} arr | required
 * @param {*} key | required
 * @returns 
 */
export const sortASC = (arr = [], key = '') => {
  if (!key) throw new Error(`Invalid 'key' argument`);
  return arr.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};

/**
 * This function is used to sort array of objects against specific key in descending order
 * @param {*} arr | required
 * @param {*} key | required
 * @returns 
 */
export const sortDESC = (arr = [], key = '') => {
  if (!key) throw new Error(`Invalid 'key' argument`);
  return arr.sort((a, b) => {
    if (a[key] > b[key]) {
      return -1;
    }
    if (a[key] < b[key]) {
      return 1;
    }
    return 0;
  });
};

export const updateLastNumber = (text, newNumber) => {
  return text.replace(/(\d+)(\D*)$/, (_, lastNumber, nonDigits) => {
    return newNumber + nonDigits;
  });
}

export const hasPermissions = (permissions) => {
  if (permissions && (permissions.terrestrial_code || permissions.terrestrial_manual || permissions.aquatic_code || permissions.aquatic_manual)) {
    return true;
  } else {
    return false;
  }
}

/**
 * This function returns standard name against ids
 * @param {int} standard_type | required
 * @param {int} animal_type | required
 * @param {boolean} full_form | optional
 * @returns 
 */
export const getStandardAgainstIDs = (standard_type = null, animal_type = null, full_form = false) => {
  try {
    if (!standard_type || !animal_type) throw new Error(`'standard_type' and 'animal_type' must be specified`);
    if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes && animal_type === LIST_DETAIL_ELEMENTS.animal_type.terrestrial) {
      return full_form ? "Terrestrial Code" : "terrestrial_code";
    }
    if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes && animal_type === LIST_DETAIL_ELEMENTS.animal_type.aquatic) {
      return full_form ? "Aquatic Code" : "aquatic_code";
    }
    if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals && animal_type === LIST_DETAIL_ELEMENTS.animal_type.terrestrial) {
      return full_form ? "Terrestrial Manual" : "terrestrial_manual";
    }
    if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals && animal_type === LIST_DETAIL_ELEMENTS.animal_type.aquatic) {
      return full_form ? "Aquatic Manual" : "aquatic_manual";
    }
    return "unknown";
  } catch (e) {
    throw new Error(e);
  }
}

/**
 * This function returns the language code against id
 * @param {int} language_id | required
 * @param {boolean} permission_language | optional 
 * @returns 
 */
export const getLanguageAgainstIDs = (language_id = '', permission_language = false) => {
  try {
    if (!language_id) throw new Error(`'language_id' is required`);
    if (language_id === LIST_DETAIL_ELEMENTS.languages.english) {
      return permission_language ? "is_en" : "en";
    }
    if (language_id === LIST_DETAIL_ELEMENTS.languages.french) {
      return permission_language ? "is_fr" : "fr";
    }
    if (language_id === LIST_DETAIL_ELEMENTS.languages.spanish) {
      return permission_language ? "is_es" : "es";
    }
    return "unknown";
  } catch (e) {
    throw new Error(e);
  }
}

export const toCapitalizedFirstLowerCaseRest = (str = '') => {
  const lowerStr = str.toLowerCase();
  return lowerStr.charAt(0).toUpperCase() + lowerStr.slice(1);
}

/**
 * This function returns terrestrial code permissions
 * @param {*} authData | authendication data from auth slice
 * @param {*} key | 'author_permissions' | 'validator_permissions' | 'publisher_permissions'
 * @returns 
 */
export const hasPermissionForTerrestrialCode = (authData, key = 'author_permissions') => {
  return authData?.[key]?.terrestrial_code;
}

/**
 * This function returns terrestrial manual permissions
 * @param {*} authData | authendication data from auth slice
 * @param {*} key | 'author_permissions' | 'validator_permissions' | 'publisher_permissions'
 * @returns 
 */
export const hasPermissionForTerrestrialManual = (authData, key = 'author_permissions') => {
  return authData?.[key]?.terrestrial_manual;
}

/**
 * This function returns aquatic code permissions
 * @param {*} authData | authendication data from auth slice
 * @param {*} key | 'author_permissions' | 'validator_permissions' | 'publisher_permissions'
 * @returns 
 */
export const hasPermissionForAquaticCode = (authData, key = 'author_permissions') => {
  return authData?.[key]?.aquatic_code;
}

/**
 * This function returns aquatic manual permissions
 * @param {*} authData | authendication data from auth slice
 * @param {*} key | 'author_permissions' | 'validator_permissions' | 'publisher_permissions'
 * @returns 
 */
export const hasPermissionForAquaticManual = (authData, key = 'author_permissions') => {
  return authData?.[key]?.aquatic_manual;
}

/**
 * This function returns english permissions
 * @param {*} authData | authendication data from auth slice
 * @param {*} key | 'author_permissions' | 'validator_permissions' | 'publisher_permissions'
 * @returns 
 */
export const hasPermissionsForEN = (authData, key = 'author_permissions') => {
  return authData?.[key]?.is_en;
}

/**
 * This function returns french permissions
 * @param {*} authData | authendication data from auth slice
 * @param {*} key | 'author_permissions' | 'validator_permissions' | 'publisher_permissions'
 * @returns 
 */
export const hasPermissionsForFR = (authData, key = 'author_permissions') => {
  return authData?.[key]?.is_fr;
}

/**
 * This function returns spanish permissions
 * @param {*} authData | authendication data from auth slice
 * @param {*} key | 'author_permissions' | 'validator_permissions' | 'publisher_permissions'
 * @returns 
 */
export const hasPermissionsForES = (authData, key = 'author_permissions') => {
  return authData?.[key]?.is_es;
}

export const handlePermissionsAgainstStandard = (authData, key, standard_type, animal_type, language_id) => {
  if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes && animal_type === LIST_DETAIL_ELEMENTS.animal_type.terrestrial && language_id === LIST_DETAIL_ELEMENTS.languages.english) {
    return hasPermissionForTerrestrialCode(authData, key) && hasPermissionsForEN(authData, key);
  }
  if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes && animal_type === LIST_DETAIL_ELEMENTS.animal_type.terrestrial && language_id === LIST_DETAIL_ELEMENTS.languages.french) {
    return hasPermissionForTerrestrialCode(authData, key) && hasPermissionsForFR(authData, key);
  }
  if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes && animal_type === LIST_DETAIL_ELEMENTS.animal_type.terrestrial && language_id === LIST_DETAIL_ELEMENTS.languages.spanish) {
    return hasPermissionForTerrestrialCode(authData, key) && hasPermissionsForES(authData, key);
  }
  if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes && animal_type === LIST_DETAIL_ELEMENTS.animal_type.aquatic && language_id === LIST_DETAIL_ELEMENTS.languages.english) {
    return hasPermissionForAquaticCode(authData, key) && hasPermissionsForEN(authData, key);
  }
  if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes && animal_type === LIST_DETAIL_ELEMENTS.animal_type.aquatic && language_id === LIST_DETAIL_ELEMENTS.languages.french) {
    return hasPermissionForAquaticCode(authData, key) && hasPermissionsForFR(authData, key);
  }
  if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes && animal_type === LIST_DETAIL_ELEMENTS.animal_type.aquatic && language_id === LIST_DETAIL_ELEMENTS.languages.spanish) {
    return hasPermissionForAquaticCode(authData, key) && hasPermissionsForES(authData, key);
  }
  if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals && animal_type === LIST_DETAIL_ELEMENTS.animal_type.terrestrial && language_id === LIST_DETAIL_ELEMENTS.languages.english) {
    return hasPermissionForTerrestrialManual(authData, key) && hasPermissionsForEN(authData, key);
  }
  if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals && animal_type === LIST_DETAIL_ELEMENTS.animal_type.terrestrial && language_id === LIST_DETAIL_ELEMENTS.languages.french) {
    return hasPermissionForTerrestrialManual(authData, key) && hasPermissionsForFR(authData, key);
  }
  if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals && animal_type === LIST_DETAIL_ELEMENTS.animal_type.terrestrial && language_id === LIST_DETAIL_ELEMENTS.languages.spanish) {
    return hasPermissionForTerrestrialManual(authData, key) && hasPermissionsForES(authData, key);
  }
  if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals && animal_type === LIST_DETAIL_ELEMENTS.animal_type.aquatic && language_id === LIST_DETAIL_ELEMENTS.languages.english) {
    return hasPermissionForAquaticManual(authData, key) && hasPermissionsForEN(authData, key);
  }
  if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals && animal_type === LIST_DETAIL_ELEMENTS.animal_type.aquatic && language_id === LIST_DETAIL_ELEMENTS.languages.french) {
    return hasPermissionForAquaticManual(authData, key) && hasPermissionsForFR(authData, key);
  }
  if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals && animal_type === LIST_DETAIL_ELEMENTS.animal_type.aquatic && language_id === LIST_DETAIL_ELEMENTS.languages.spanish) {
    return hasPermissionForAquaticManual(authData, key) && hasPermissionsForES(authData, key);
  }
}

export const findValueForDropdown = (data = [], value) => {
  let ob = { value: '', label: '' };
  const item = data.find(item => item.value === value);
  if (item) {
    ob['value'] = item?.value;
    ob['label'] = item?.label;
  }
  return ob;
}

export const getAuthorPermission = async (draft_id = '') => {
  try {
    const ret = await sendAPIRequest(`/api/doc-transfer/get-permission/${draft_id}/?author=true`);
    const res = await ret.json();
    if (res.success && res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.error({ err });
    displayNotification('error', 'Failed to Retrive Author Permission');
    return false;
  }
}
export const getValidationPermission = async (draft_id = '') => {
  try {
    const ret = await sendAPIRequest(`/api/doc-transfer/get-permission/${draft_id}/?validator=true`);
    const res = await ret.json();
    if (res.success && res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.error({ err });
    displayNotification('error', 'Failed to Retrive Author Permission');
    return false;
  }
}
export const getPublishingPermission = async (draft_id = '') => {
  try {
    const ret = await sendAPIRequest(`/api/doc-transfer/get-permission/${draft_id}/?publisher=true`);
    const res = await ret.json();
    if (res.success && res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.error({ err });
    displayNotification('error', 'Failed to Retrive Author Permission');
    return false;
  }
}