import Button from 'components/common/Button';
import CustomCard from 'components/CustomCard'
import DataTable from 'pages/components/DataTable';
import React, { useState } from 'react'
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import AutoCompleteDropdownCustom from 'pages/components/autoComplete/AutoCompleteDropdownCustom';

const GlossaryValidation = () => {
    const rows = [
        { id: 1, standards: 'TC', terms: 'animal', occurence: 'Chapter Name (Chapter:1.1.2)', actions: 'Edit/Delete' },
        { id: 2, standards: 'TC', terms: 'animal', occurence: 'Chapter Name (Chapter:2.1.1)', actions: 'Edit/Delete' },
        { id: 3, standards: 'AM', terms: 'animal', occurence: 'Chapter Name (Chapter:3.1.2)', actions: 'Edit/Delete' },
        { id: 4, standards: 'AM', terms: 'animal', occurence: 'Chapter Name (Chapter:1.1.1)', actions: 'Edit/Delete' }
    ];

    const dummyOptions = [
        { label: 'Chapter 1.1', value: 1 },
        { label: 'Chapter 1.2', value: 2 },
        { label: 'Chapter 1.3', value: 3 },
        { label: 'Chapter 1.4', value: 4 }
    ];


    const { t } = useTranslation();
    const [checkedRows, setCheckedRows] = useState({});
    const [selectedValue, setSelectedValue] = useState([]);
    const [filters, setFilters] = useState({
        chapter_selection: []
    });
    const [isSearch, setIsSearch] = useState(false);
    const handleCheckboxChange = (id) => {
        setCheckedRows((prevCheckedRows) => ({
            ...prevCheckedRows,
            [id]: !prevCheckedRows[id]
        }));
    };
    const handleChange = (event, newValue) => {
        setSelectedValue(newValue); // Directly update the selected values from the dropdown
    };


    return (
        <>
            <div className="container my-2">
                <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2">
                    <div className="d-flex justify-content-between w-100">
                        <div className="card-title1 lh-1">
                            {t("-1", "Glossay Validation")}
                        </div>
                    </div>
                </div>
                <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2">
                    <div className="d-flex justify-content-between w-100">
                        <div className="col-md-6">
                            <AutoCompleteDropdownCustom
                                showClearIcon={true}
                                error={true}
                                required={false}
                                label={t("-1", "Filter Terms")}
                                multiple={true}
                                hasselectall={true}
                                disabled={false}
                                options={dummyOptions}
                                onChange={(event, value) => {
                                    if (value.some((v) => v.value === "Select all")) {
                                        if (filters.chapter_selection.length === dummyOptions.length) {
                                            
                                            setFilters({
                                                ...filters,
                                                chapter_selection: [],
                                            });
                                        } else {
                                        
                                            setFilters({
                                                ...filters,
                                                chapter_selection: dummyOptions.map((r) => r.value),
                                            });
                                        }
                                    } else {
                                        
                                        setFilters({
                                            ...filters,
                                            chapter_selection: value.map((r) => r.value),
                                        });
                                    }
                                    setIsSearch(true); 
                                }}
                                value={filters.chapter_selection}
                            />

                            {/* <Autocomplete
                                multiple
                                options={dummyOptions} // Set the options for the dropdown
                                getOptionLabel={(option) => option.label} // Display label as dropdown option
                                value={selectedValue} // Controlled component value
                                onChange={handleChange} // Set new selected values to state
                                isOptionEqualToValue={(option, value) => option.value === value.value} // Ensure proper option selection
                                renderInput={(params) => (
                                    <TextField {...params} label="Filter Terms" variant="outlined" />
                                )}
                            /> */}
                        </div>
                        <div className="col-md-6" style={{ textAlign: 'end' }}>
                            <Button
                            // onClick={openExludeModal}
                            >
                                {t("-1", "Add Exclude Term")}
                            </Button>
                        </div>
                    </div>
                </div>
                <DataTable
                    getRowId={(rows) => rows.id}
                    // isLoading={isLoading}
                    rows={rows}
                    className="text-white bg-primary rounded-0"
                    columns={
                        [
                            {
                                field: "checkbox",
                                headerName: (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox className="text-light"
                                        />
                                        <span>{t("-1", "")}</span>
                                    </div>
                                ),
                                headerClassName: "text-white bg-primary rounded-0",
                                flex: 1.1,
                                renderCell: () => <Checkbox className="text-dark" />
                            },

                            {
                                field: "standards",
                                headerName: t("-1", "Standards"),
                                headerClassName: "text-white bg-primary rounded-0",
                                flex: 1.1,

                            },
                            {
                                field: "terms",
                                headerName: t("-1", "Terms"),
                                headerClassName: "text-white bg-primary rounded-0",
                                flex: 1.1,

                            },
                            {
                                field: "occurence",
                                headerName: t("-1", "Occurence"),
                                headerClassName: "text-white bg-primary rounded-0",
                                flex: 1.1,

                            },

                            {
                                field: "actions",
                                headerName: t("-1", "Actions"),
                                headerClassName: "text-white bg-primary rounded-0 border-none",
                                flex: 0.5,
                                renderCell: () => <button className="rounded py-2" style={{ border: 'none', backgroundColor: '#77B464', color: '#fff', fontWeight: 'bold', width: '50px', }} >{t("-1", "Ignore")}</button>
                            },


                        ]
                    }
                />
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-end">
                        <button
                            className="btn btn--primary font-custom fs-6 ms-1 mt-5"
                        // onClick={() => {
                        //     resetFilters();
                        // }}
                        >
                            <span> {t("-1", "Ignore")}</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GlossaryValidation
