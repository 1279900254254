import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import TextFieldCustom from 'components/TextFieldCustom';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import HtmlRenderer from 'components/HtmlRenderer';
import { displayNotification, getBaseURL, getBearerToken, handleReplaceUrlForChapterLinking, handleSideBarContent, processFile, sendAPIRequest } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { isExpandedState, isSectionSecState, isSectionState } from '../../../redux/expandSlice'
import { Breadcrumbs, Divider, Grid, IconButton, InputAdornment, Tabs, TextField, Tooltip, } from '@mui/material';
import Spinner from 'components/spinner/Spinner';
import ClearIcon from '@mui/icons-material/Clear';
import ChapterPreview from 'components/previews/ChapterPreview';
import { KeyboardArrowDown, KeyboardArrowUp, OpenInNew } from '@mui/icons-material';
import NorthIcon from '@mui/icons-material/North';
import CustomTab from 'components/CustomTab';
import { MANUAL_HEADINGS } from 'constants';
import SearchIcon from '@mui/icons-material/Search';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Articles = (props) => {
  const scrollspyContainerRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('');
  const [currentElement, setCurrentElement] = useState('');
  const headerTheme = useSelector((s) => s.general.headerTheme);
  const siteLanguage = useSelector(s => s.general.siteLanguageSont);
  const [activeArticleNumber, setActiveArticleNumber] = useState('');
  const [showScrollButton, setShowScrollButton] = useState(false);
  const isExpanded = useSelector(s => s.expand.isExpanded);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [data, setData] = useState([]);
  const [sideBar, setSideBar] = useState([]);
  const [documentMetaData, setDocumentMetaData] = useState({
    section_number: '',
    chapter_number: '',
    structure_section_name: '',
    structure_chapter_name: '',
  });
  const [manualheading, setManualHeading] = useState("");
  const [lastUpdated, setLastUpdated] = useState('');
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [highlightedData, setHighlightedData] = useState(data);
  const [isLoading, setIsLoading] = useState({
    loading_data: false,
  });
  const [searchingContentValue, setSearchingContentValue] = useState("")
  var originalTextMap = new Map();
  const [documentID, setDocumentID] = useState('');
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentTerm, setCurrentTerm] = useState(0);

  useEffect(() => {
    setDocumentMetaData({
      section_number: props?.section?.section_no ?? "",
      chapter_number: props?.currentChapter?.chapter_no ?? "",
    })
    setActiveArticleNumber('')
    setManualHeading('')
    getChapterData();
  }, [props.chapter, props.metaData]);

  useEffect(() => {
    const listGroupItems = document.querySelectorAll('#list-example');
    listGroupItems.forEach(item => {
      item.addEventListener('click', handleClick);
    });

    return () => {
      listGroupItems.forEach(item => {
        item.removeEventListener('click', handleClick);
      });
    };
  }, [props.selectedChapter]);


  const getManualStyle = async () => {
    try {
      const ret = await sendAPIRequest('/api/manual-styles');
      const res = await ret.json();
      if (res.success && res.status === 200) {
        if (res.data && res.data.length > 0) {
          const css = res.data[0].style.replace(/<[^>]+>/g, '');
          return css;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getHTMLContent = async () => {
    if (scrollspyContainerRef.current) {
      displayNotification('info', 'Downloading file [F]');
      const htmlContent = scrollspyContainerRef.current.innerHTML;
      const css = await getManualStyle();
      const html = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>${css}</style>
      </head>
      <body>
        ${htmlContent}
      </body>
      </html>
      `;
      const blob = new Blob([html], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'test_html_file.htm';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  const handleWordDownload = async () => {
    if (scrollspyContainerRef.current) {
      displayNotification('info', 'Downloading file [F]');
      const htmlContent = scrollspyContainerRef.current.innerHTML;
      const css = await getManualStyle();
      const html = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>${css}</style>
      </head>
      <body>
        ${htmlContent}
      </body>
      </html>
      `;
      const blob = new Blob([html], { type: 'text/html' });
      const file = new File([blob], 'test_html_file.htm', { type: 'text/html' });
      await getBearerToken();
      await processFile(file);
    }
  }

  const getChapterData = async () => {
    setIsLoading({ ...isLoading, loading_data: true });
    let setBar;
    try {
      const ret = await sendAPIRequest(`/api/contents/navigation-chapter-preview/?is_rid=true&RID=${props.currentChapter?.RID}&language_id=${props.componentLanguage}&is_manual=${!props.isCode}`);
      const res = await ret.json();
      if (res.success && res.status === 200) {
        const modfiFiedData = handleReplaceUrlForChapterLinking(res.data)
        setData(modfiFiedData);
        let filterStandardType = res.data.filter((i) => i.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name)[0]?.standards_structures_revised
        if (filterStandardType.standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals) {
          setBar = handleSideBarContent(res.data, true)
        } else {
          setBar = handleSideBarContent(res.data, false)
        }
        setSideBar(setBar)
        res.data.forEach(d => (
          d.html_contents && d.html_contents.length > 0 && d.html_contents.forEach(c => {
            if (c.text_type == LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text) {
              setLastUpdated(c.content);
            }
          })
        ))
        const section = res.data.filter(d => d.text_type === LIST_DETAIL_ELEMENTS.text_types.section_name)[0];
        const chapter = res.data.filter(d => d.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name)[0];
        setDocumentID(res.data.filter(d => d.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name)[0]?.document_id);
        // setDocumentMetaData({
        //   ...documentMetaData,
        //   section_number: section?.ref_number,
        //   chapter_number: chapter?.ref_number,
        //   structure_chapter_name: chapter?.standards_structures_revised?.chapter_name?.toUpperCase(),
        //   structure_section_name: chapter?.standards_structures_revised?.section_name?.toUpperCase(),
        // });
      }
    } catch (e) {
      displayNotification('error', 'Failed to load chapter data [F]');
    }
    setIsLoading({ ...isLoading, loading_data: false });
  }

  useEffect(() => {
    if (scrollspyContainerRef.current) {
      scrollspyContainerRef.current.scrollTop = 0;
    }
    const handleScroll = () => {
      setShowScrollButton(window.scrollY > 100);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [highlightedData]);


  const handleScrollToArticle = (targetId, key) => {
    setActiveTab(key);
    const targetElement = document.getElementById(targetId);
    const yourAttributeValue = targetElement.getAttribute('data-myattribute');
    setActiveArticleNumber(yourAttributeValue);
    if (props?.selectedOption?.includes('terrestrial-manual') || props?.selectedOption?.includes('aquatic-manual')) {
      let dataIs = sideBar[key]?.standard_content;
      if (dataIs) {
        setManualHeading(dataIs)
      } else {
        setManualHeading("")
      }
    } else {
      setManualHeading("")
    }
    const stickyBoxHeight = document.getElementById('sticky-bar').getBoundingClientRect().height;
    window.scrollTo({
      behavior: 'smooth',
      top:
        document.getElementById(targetId).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        stickyBoxHeight - 30,
    })
  };


  const handleClick = (e) => {
    e.preventDefault();
    const targetId = e.target.getAttribute('href');
    if (targetId && targetId.startsWith('#')) {
      const targetElement = document.getElementById(targetId.slice(1));
      const scrollspyContainer = scrollspyContainerRef.current;
      if (targetElement && scrollspyContainer) {
        const offsetTop = targetElement.offsetTop - scrollspyContainer.offsetTop;
        scrollspyContainer.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        });
      }
    }
  };


  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
  };

  const highlightSearchTerm = (htmlContent, search, currentIndex) => {
    if (typeof htmlContent !== 'string' || !search) return { highlightedContent: htmlContent, occurrences: 0 };
    const escapedSearch = escapeRegExp(search);
    const regex = new RegExp(`\\b(${escapedSearch})\\b`, 'gi');
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    let occurrences = 0;
    const highlightNode = (node) => {
      Array.from(node.childNodes).forEach((childNode) => {
        if (childNode.nodeType === Node.TEXT_NODE) {
          const parts = childNode.textContent.split(regex);
          const fragment = document.createDocumentFragment();
          parts.forEach((part, index) => {
            const span = document.createElement('span');
            if (regex.test(part)) {
              span.style.backgroundColor = (occurrences === currentIndex) ? 'green' : 'yellow';
              span.textContent = part;
              const uniqueId = `highlight_${occurrences}`;
              span.id = uniqueId;
              if (occurrences === currentIndex) {
              }
              occurrences++;
            } else {
              span.textContent = part;
            }
            fragment.appendChild(span);
          });
          childNode.parentNode.replaceChild(fragment, childNode);
        } else if (childNode.nodeType === Node.ELEMENT_NODE) {
          highlightNode(childNode);
        }
      });
    };
    highlightNode(doc.body);
    const serializer = new XMLSerializer();
    const highlightedHtml = serializer.serializeToString(doc);
    return { highlightedContent: highlightedHtml, occurrences };
  };

  useEffect(() => {
    if (!search) {
      setHighlightedData(data);
      setCount(0);
      return;
    }
    let totalCount = 0;
    const highlighted = data.map(item => {
      const highlightedItem = {};
      let itemOccurrences = 0;
      for (const key in item) {
        if (Object.hasOwnProperty.call(item, key)) {
          if (key === 'html_contents') {
            const highlightedContents = item[key].map(htmlContent => {
              const { highlightedContent, occurrences } = highlightSearchTerm(htmlContent.content, search);
              itemOccurrences += occurrences;
              return {
                ...htmlContent,
                content: highlightedContent
              };
            });
            highlightedItem[key] = highlightedContents;
          } else if (key === 'child') {
            const highlightedChild = {};
            for (const childKey in item[key]) {
              if (Object.hasOwnProperty.call(item[key], childKey)) {
                if (childKey === 'html_contents') {
                  const highlightedChildContents = item[key][childKey].map(htmlContent => {
                    const { highlightedContent, occurrences } = highlightSearchTerm(htmlContent.content, search);
                    itemOccurrences += occurrences;
                    return {
                      ...htmlContent,
                      content: highlightedContent
                    };
                  });
                  highlightedChild[childKey] = highlightedChildContents;
                } else {
                  const { highlightedContent, occurrences } = highlightSearchTerm(item[key][childKey], search);
                  if (occurrences > 0) {
                    itemOccurrences += occurrences;
                    highlightedChild[childKey] = highlightedContent;
                  } else {
                    highlightedChild[childKey] = item[key][childKey];
                  }
                }
              }
            }
            highlightedItem[key] = highlightedChild;
          } else {
            // Search within regular properties
            const { highlightedContent, occurrences } = highlightSearchTerm(item[key], search);
            if (occurrences > 0) {
              itemOccurrences += occurrences;
              highlightedItem[key] = highlightedContent;
            } else {
              highlightedItem[key] = item[key];
            }
          }
        }
      }
      totalCount += itemOccurrences;
      return highlightedItem;
    });
    setHighlightedData(highlighted);
    setCount(totalCount);
  }, [search, data]);


  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const highlightSearchTerm2 = (content) => {
    if (!search || (typeof content !== 'string' && typeof content !== 'number')) return content
    const text = String(content);
    const regex = new RegExp(`(${search})`, 'gi');
    return text.split(regex).map((part, index) => {
      return part.toLowerCase() === search.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: 'yellow' }}>
          {part}
        </span>
      ) : (
        part
      );
    });
  };

  const handleIsExpanded = (value) => {
    dispatch(isExpandedState(value));
    dispatch(isSectionState(value));
    dispatch(isSectionSecState(value));
  }

  const handleClearSearch = () => {
    setSearch('');
  };

  const moveToNextOccurrence = () => {
    if (search.length <= 0) {
      setCurrentIndex(0);
      scrollToHighlightedContent(0);
    } else {
      const nextIndex = currentIndex < count - 1 ? currentIndex + 1 : 0;
      setCurrentIndex(nextIndex);
      scrollToHighlightedContent(nextIndex);
    }
  };

  const moveToPreviousOccurrence = () => {
    if (search.length <= 0) {
      setCurrentIndex(0);
      scrollToHighlightedContent(0);
    } else {
      const prevIndex = currentIndex > 0 ? currentIndex - 1 : count - 1;
      setCurrentIndex(prevIndex);
      scrollToHighlightedContent(prevIndex);
    }
  };


  const scrollToHighlightedContent = (index) => {
    const highlightedElement = document.getElementById(`key-${index}`);
    // const highlightedElement = document.getElementById(`highlight-${index}`);
    if (highlightedElement) {
      // Set text color to green using style property
      // highlightedElement.style.backgroundColor = 'green';
      highlightedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };


  const scrollToTop = () => {
    const element = document.getElementById(`topOfGrid`);
    element.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const scrollToTopButtonStyle = {
    position: 'fixed',
    bottom: '2%',
    right: '2%',
    zIndex: 1000,
    fontSize: '1.5rem',
    borderRadius: '50%',
    backgroundColor: '#ff4815',
    boxShadow: '0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.3)',
  };


  return (
    <>
      <Grid container spacing={0}>
        {
          isExpanded && (
            <Grid
              item
              lg={2}
              md={2}
              sm={3}
              xs={12}
              sx={{
                marginX: '0px',
                height: '100vh',
                marginBottom: '10px',
                position: 'sticky', top: 0, zIndex: 999, backgroundColor: '#fff',
                color: '#000',
                backgroundColor: '#F4F4F4',
                border: '1px solid #eaeaea',
                marginTop: '10px',
                borderRadius: '0px',
                paddingY: '10px',
                boxShadow: '0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05)',
              }}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                aria-label="Vertical tabs example"
                sx={{
                  borderRight: 1,
                  borderColor: 'divider',
                  alignItems: 'left',
                  paddingX: '0px',
                  marginX: '0px',
                  height: '100%',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  '& .MuiTabs-scroller': {
                    scrollbarWidth: 'thin'
                  },
                  '& .MuiTabs-scrollButtons': {
                    display: 'none',
                  },
                }}
                value={false}
              >
                {
                  sideBar && sideBar.length > 0 ? (
                    sideBar.map((d, k) =>
                    (
                      <CustomTab
                        key={d.id}
                        index={d.id}
                        sx={{
                          width: '100%',
                          maxWidth: {
                            lg: '360px',
                            md: '360px',
                            sm: '360px',
                            xs: '100%',
                          },
                          paddingBottom: '7px',
                          textAlign: 'left',
                          marginBottom: '0px',
                          opacity: '2 !important',
                        }}
                        label={
                          <Box
                            sx={{
                              display: 'flex',
                              '& *': { display: 'inline' },
                              width: '100%',
                              paddingY: '10px',
                              opacity: '2',
                              paddingX: '10px',
                            }}
                          >
                            <span
                              style={{
                                marginRight: '0.5rem',
                                fontWeight: 'bold',
                                fontSize: '11pt',
                              }}
                              className='article-number'
                            >
                              {
                                documentMetaData.section_number && documentMetaData.chapter_number
                                  ? <>
                                    {
                                      props.isCode &&
                                      `${documentMetaData.section_number}.${documentMetaData.chapter_number}.`
                                    }
                                    {
                                      (d.ref_number && props.isCode) ? d.ref_number : <HtmlRenderer tag={"span"} htmlContent={d.standard_content} />
                                    }
                                  </>
                                  : <HtmlRenderer tag={"span"} htmlContent={d.standard_content} />
                              }
                            </span>
                            {
                              d?.child &&
                              <HtmlRenderer
                                tag={"span"}
                                htmlContent={`${d?.child?.standard_content}`}
                              />
                            }
                          </Box>
                        }
                        onClick={() => handleScrollToArticle(`article-${d.id}`, k)}
                        isActive={activeTab === k}
                      />
                    )
                    )
                  ) : (
                    isLoading.loading_data &&
                    (
                      <Box sx={{ textAlign: 'center' }} className="text-primary">
                        <Spinner />
                      </Box>
                    ))
                }
              </Tabs>
            </Grid>
          )
        }
        <Grid
          item
          lg={!isExpanded ? 12 : 10}
          md={!isExpanded ? 12 : 10}
          sm={!isExpanded ? 12 : 9}
          xs={12}
          id={'topOfGrid'}
          sx={{
            color: '#000',
            backgroundColor: '#fff',
            border: '1px solid #eaeaea',
            marginY: '10px',
            padding: '.5rem 1rem',
            borderRadius: '0px',
            boxShadow: '0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05)',
          }}
        >
          <Box style={{
            position: 'sticky',
            top: 0,
            zIndex: 999,
            backgroundColor: '#fff',
            borderBottom: '0.1rem solid rgba(0, 0, 0, 0.05)',
            boxShadow: '0 0.25rem 1rem rgba(0, 0, 0, 0.05)',
          }}
            id="sticky-bar"
          >
            <Box marginY={1}
              sx={{
                display: 'flex',
                fontSize: '12px',
                paddingX: '10px'
              }}
            >
              {
                parseInt(documentMetaData.section_number) !== 0 &&
                <>
                  <span style={{
                    color: 'inherit',
                    marginRight: '14px',
                  }}
                  >
                    {
                      !props.isCode ? (
                        <>{t("65", "Section")} {props.volume_no}.{documentMetaData.section_number}.</>
                      ) : (
                        <>{t("65", "Section")} {documentMetaData.section_number}.</>
                      )
                    }
                  </span>
                  <span>/</span>
                  <a
                    href={
                      props.chapter
                        // ? getBaseURL(`chapter/?rid=${props.currentChapter.RID}&volume_no=${props.volume_no ?? ''}&ismanual=${!props.isCode}&language=${props.componentLanguage}`)
                        ? getBaseURL(`/portal/chapter/?rid=${props?.currentChapter?.RID}&language=${props.componentLanguage}&ismanual=${!props.isCode}`)
                        : ""
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: 'inherit',
                      marginLeft: '14px',
                      marginRight: '14px',
                    }}
                  >
                    {
                      !props.isCode ? (
                        <>{t("33", "Chapter")} {props.volume_no}.{documentMetaData.section_number}.{documentMetaData.chapter_number}.</>
                      ) : (
                        <>{t("33", "Chapter")} {documentMetaData.section_number}.{documentMetaData.chapter_number}.</>
                      )
                    }
                  </a>
                  <span>/ </span>
                  {
                    manualheading !== '' &&
                    <span style={{
                      color: '#000',
                      marginLeft: '14px',
                      marginRight: '14px',
                    }}
                    >
                      <HtmlRenderer
                        tag={"p"}
                        htmlContent={manualheading}
                      />
                    </span>
                  }
                  {props.isCode && activeArticleNumber && (
                    <span style={{
                      color: '#000',
                      marginLeft: '14px',
                      marginRight: '14px',
                    }}
                    >
                      {t("34", "Article")} {`${documentMetaData.section_number}.${documentMetaData.chapter_number}.${activeArticleNumber}`}
                    </span>
                  )}
                </>
              }
            </Box>
            <Grid container spacing={2} alignItems="center" sx={{
              padding: '10px',
            }}
            >
              <Grid item xs={7} >
                <TextField
                  required={false}
                  error={false}
                  fullWidth
                  size="small"
                  value={search}
                  onChange={(e) => (setSearch(e.target.value), setCurrentTerm(0))}
                  placeholder='Search content here...'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{
                            fontSize: 'medium'
                          }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {search.length > 0 && (
                          <ClearIcon
                            onClick={handleClearSearch}
                            style={{
                              cursor: 'pointer',
                              fontSize: 'medium'
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    backgroundColor: '#fff',
                  }}
                />
              </Grid>
              {
                search && search.length > 0 &&
                <Grid item xs={3}>
                  <Grid sx={{ 'display': 'flex' }}>
                    <span className='cursor-pointer mx-1'
                      onClick={() => {
                        const previousTerm = currentTerm - 1;
                        if (previousTerm > 0) {
                          const element = document.getElementById(`highlight_${previousTerm}`);
                          if (currentElement) {
                            currentElement.style.backgroundColor = 'yellow';
                            currentElement.style.color = '';
                          }
                          if (element) {
                            setCurrentElement(element);
                            element.style.backgroundColor = '#ff4815';
                            element.style.color = '#fff';
                            if (!isInViewport(element)) {
                              const offset = 150;
                              const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                              const offsetPosition = elementPosition - offset;
                              window.scrollTo({
                                top: offsetPosition,
                                behavior: 'smooth'
                              });
                            }
                          }
                          setCurrentTerm(previousTerm);
                        }
                      }}
                    >
                      <KeyboardArrowUp sx={{
                        color: '#ff4815'
                      }}
                        fontSize="small"
                      />
                    </span>
                    <span className='cursor-pointer mx-1'
                      onClick={() => {
                        const nextTerm = currentTerm + 1;
                        if (nextTerm <= count) {
                          const element = document.getElementById(`highlight_${nextTerm}`);
                          if (currentElement) {
                            currentElement.style.backgroundColor = 'yellow';
                            currentElement.style.color = '';
                          }
                          if (element) {
                            setCurrentElement(element);
                            element.style.backgroundColor = '#ff4815';
                            element.style.color = '#fff';
                            if (!isInViewport(element)) {
                              const offset = 150;
                              const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                              const offsetPosition = elementPosition - offset;
                              window.scrollTo({
                                top: offsetPosition,
                                behavior: 'smooth'
                              });
                            }
                          }
                          setCurrentTerm(nextTerm);
                        }
                      }}
                    >
                      <KeyboardArrowDown sx={{
                        color: '#ff4815'
                      }}
                        fontSize="small"
                      />
                    </span>
                    <Typography
                      variant="body1"
                    >
                      {t("101", "Number of Matches:")} {count}
                    </Typography>
                  </Grid>
                </Grid>
              }
              <Grid item xs={2}>
                <Box sx={{ display: 'flex', paddingTop: '0', justifyContent: 'end' }}>
                  <Tooltip title={"Download Html"} placement="top">
                    <span onClick={() => getHTMLContent()}
                      className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ms-2`}
                    >
                      <i class="fa-solid fa-file-arrow-down text-primary fs-3"></i>
                    </span>
                  </Tooltip>
                  <Tooltip title={"Download Word File"} placement="top">
                    <span onClick={() => handleWordDownload()}
                      className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ms-2`}
                    >
                      <i class="fa-solid fa-file-word text-primary fs-3"></i>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={!isExpanded ? "Collapse Chapter" : "Expand Chapter"}
                    placement="top"
                  >
                    <span
                      className={`cursor-pointer text-hover-primary border-0 ms-2`}
                      onClick={() => handleIsExpanded(!isExpanded)}
                      style={{ textAlign: 'center', marginX: '0px', }}
                    >
                      {!isExpanded ? (
                        <ZoomInMapIcon sx={{
                          marginX: '10px',
                          color: '#ff4815'
                        }}
                          fontSize="small"
                        />
                      ) : (
                        <OpenWithIcon sx={{
                          marginX: '10px',
                          color: '#ff4815'
                        }}
                          fontSize="small"
                        />
                      )}
                    </span>
                  </Tooltip>
                  <Tooltip title={t("127", "Open in new tab")} placement="top">
                    <a
                      href={
                        props.chapter
                          // ? getBaseURL(`chapter/?rid=${props.currentChapter.RID}&volume_no=${props.volume_no ?? ''}&ismanual=${!props.isCode}&language=${props.componentLanguage}`)
                          ? getBaseURL(`/portal/chapter/?rid=${props?.currentChapter?.RID}&language=${props.componentLanguage}&ismanual=${!props.isCode}`)
                          : ""
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textAlign: 'center', marginX: '0px', }}
                      className={`cursor-pointer text-hover-primary border-0 ms-2`}
                    >
                      <OpenInNew sx={{
                        marginX: '10px'
                      }}
                        fontSize="small" />
                    </a>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{
            padding: '0rem 1rem',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            opacity: isLoading.loading_data ? 0.5 : 1
          }}
            id="sont-content-area"
            ref={scrollspyContainerRef}
          >
            {/*chall */}
            {
              highlightedData && highlightedData.length > 0 ? (
                highlightedData.map((d, index) => (
                  <Box key={`key-${index}`}
                  >
                    {
                      d.text_type === LIST_DETAIL_ELEMENTS.text_types.section_name
                      && parseInt(documentMetaData.chapter_number) === 1
                      && parseInt(documentMetaData.section_number) !== 0
                      && (
                        <Box
                          key={`section-${d.id}`}
                        >
                          <Typography
                            className="pb-5 pt-10 text-black text-center"
                            sx={{
                              letterSpacing: "2px",
                              textTransform: 'uppercase'
                            }}
                            key={d.id}
                          >
                            {
                              !props.isCode ? (
                                <>{t("65", "Section")} {props.volume_no}.{documentMetaData.section_number}.</>
                              ) : (
                                <>{t("65", "Section")} {documentMetaData.section_number}.</>
                              )
                            }
                          </Typography>
                          <Typography
                            className="chapter-heading"
                            variant="p"
                            sx={{
                              letterSpacing: "2px",
                              textAlign: "center",
                              textTransform: 'uppercase'
                            }}
                            key={`name-${d.id}`}
                          >
                            {/* <HtmlRenderer
                              tag={"p"}
                              htmlContent={props?.section?.section_name ?? ""}
                            /> */}
                            <p
                              variant='h5'
                              className="justify-content-center d-flex"
                            >
                              <HtmlRenderer
                                tag={"span"}
                                className="document-chftre-intitule"
                                htmlContent={d?.standard_content}
                              />
                            </p>
                          </Typography>
                          <Divider
                            sx={{
                              width: "50%",
                              marginX: 'auto',
                            }}
                          />
                          <Divider
                            sx={{
                              width: "50%",
                              paddingTop: '5px',
                              marginX: 'auto'
                            }}
                          />
                        </Box>
                      )
                    }
                    {
                      d.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name && (
                        <Box key={`chapter-${d.id}`}>
                          {
                            parseInt(documentMetaData.section_number) !== 0 &&
                            <Box
                              className="pb-5 pt-10 text-black text-center section-heading"
                              sx={{
                                letterSpacing: "2px",
                                textAlign: "center",
                                paddingTop: '15px',
                                textTransform: 'uppercase'
                              }}
                              key={d.id}
                            >
                              {
                                !props.isCode ? (
                                  <>{t("33", "Chapter")} {props.volume_no}.{documentMetaData.section_number}.{documentMetaData.chapter_number}.</>
                                ) : (
                                  <>{t("33", "Chapter")} {documentMetaData.section_number}.{documentMetaData.chapter_number}.</>
                                )
                              }
                            </Box>
                          }
                          <Typography
                            className="chapter-heading"
                            variant="p"
                            sx={{
                              letterSpacing: "2px",
                              textAlign: "center",
                              textTransform: 'uppercase'
                            }}
                            key={`name-${d.id}`}
                          >
                            {/* <HtmlRenderer
                              tag={"p"}
                              className="document-chftre-intitule"
                              htmlContent={documentMetaData.structure_chapter_name}
                            /> */}
                            <p
                              variant='h5'
                              className="document-chftre-intitule"
                            >
                              <HtmlRenderer
                                tag={"span"}
                                className="document-chftre-intitule"
                                htmlContent={d?.standard_content}
                              />
                            </p>
                          </Typography>
                        </Box>
                      )
                    }
                    {
                      (d.text_type === LIST_DETAIL_ELEMENTS.text_types.article_heading && props.isCode) && (
                        <Box
                          key={`article-${d.id}`}
                          id={`article-${d.id}`}
                          data-myattribute={d.ref_number}
                        >
                          {/* <HtmlRenderer
                            tag={"div"}
                            className="text-center fs-4"
                            style={{ marginBottom: '20px', marginTop: '10px' }}
                            htmlContent={d?.standard_content}
                          /> */}
                          <Typography
                            className="text-center fs-4"
                            style={{ marginBottom: '20px', marginTop: '10px' }}
                            key={`art-title-${d.id}`}
                          >
                            Article.{documentMetaData.section_number}.{documentMetaData.chapter_number}.{d.ref_number}
                          </Typography>
                          {
                            d.html_contents &&
                              d.html_contents.length > 0 ? (
                              d.html_contents.map((child) => (
                                <Box
                                  sx={{
                                    paddingX: '4px',
                                    textAlign: 'justify',
                                  }}
                                  key={`art-child-${child.id}`}
                                >
                                  <HtmlRenderer
                                    tag={"div"}
                                    style={{ fontSize: `${props.fontSize}px` }}
                                    htmlContent={child.content}
                                  />
                                </Box>
                              ))
                            ) : (
                              d.child && Object.keys(d.child).length > 0 && (
                                <Box
                                  key={`general-${d.child.id}`}
                                  id={`${d.child.id}`}
                                >
                                  <HtmlRenderer
                                    tag={"div"}
                                    className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type}
                                    style={{ fontSize: `${props.fontSize + 2}px` }}
                                    htmlContent={d.child?.standard_content}
                                  />
                                  {d.child.html_contents &&
                                    d.child.html_contents.length > 0 &&
                                    d.child.html_contents.map((child, i) => (
                                      <>
                                        {child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && (
                                          <Divider />
                                        )}
                                        <Box
                                          key={`gen-child-${child.id}`}
                                          sx={{
                                            display: 'flex',
                                            justifyContent: child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text ? 'flex-end' : 'normal',
                                          }}
                                        >
                                          <Box className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "text-justified" : 'justify-content-end'}>
                                            <HtmlRenderer
                                              tag={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "div" : "p"}
                                              style={{
                                                fontSize: `${props.fontSize}px`,
                                              }}
                                              htmlContent={child.content}
                                            />
                                          </Box>
                                        </Box>
                                      </>
                                    ))}
                                </Box>
                              )
                            )
                          }
                        </Box>
                      )
                    }
                    {
                      (d.text_type === LIST_DETAIL_ELEMENTS.text_types.glossary_term || MANUAL_HEADINGS.includes(parseInt(d.text_type))
                        || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_paragraph
                        || d.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text
                        || d.text_type === LIST_DETAIL_ELEMENTS.text_types.paragraph
                        || (d.text_type === LIST_DETAIL_ELEMENTS.text_types.article_heading && !props.isCode)
                      ) && (
                        <Box
                          key={`article-${d.id}`}
                          id={`article-${d.id}`}
                          data-myattribute={d.ref_number}
                        >
                          <HtmlRenderer
                            tag={"div"}
                            className="text-center fs-4"
                            style={{ marginBottom: '20px', marginTop: '10px' }}
                            htmlContent={d?.standard_content}
                          />
                          {
                            d.html_contents &&
                              d.html_contents.length > 0 ? (
                              d.html_contents.map((child) => (
                                <Box
                                  sx={{
                                    paddingX: '4px',
                                    textAlign: 'justify',
                                  }}
                                  key={`art-child-${child.id}`}
                                >
                                  <HtmlRenderer
                                    tag={"div"}
                                    style={{ fontSize: `${props.fontSize}px` }}
                                    htmlContent={child.content}
                                  />
                                </Box>
                              ))
                            ) : (
                              d.child && Object.keys(d.child).length > 0 && (
                                <Box
                                  key={`general-${d.child.id}`}
                                  id={`${d.child.id}`}
                                >
                                  <HtmlRenderer
                                    tag={"div"}
                                    className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type}
                                    style={{ fontSize: `${props.fontSize + 2}px` }}
                                    htmlContent={d.child?.standard_content}
                                  />
                                  {d.child.html_contents &&
                                    d.child.html_contents.length > 0 &&
                                    d.child.html_contents.map((child, i) => (
                                      <>
                                        {child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && (
                                          <Divider />
                                        )}
                                        <Box
                                          key={`gen-child-${child.id}`}
                                          sx={{
                                            display: 'flex',
                                            justifyContent: child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text ? 'flex-end' : 'normal',
                                          }}
                                        >
                                          <Box className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "text-justified" : 'justify-content-end'}>
                                            <HtmlRenderer
                                              tag={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "div" : "p"}
                                              style={{
                                                fontSize: `${props.fontSize}px`,
                                              }}
                                              htmlContent={child.content}
                                            />
                                          </Box>
                                        </Box>
                                      </>
                                    ))}
                                </Box>
                              )
                            )
                          }
                        </Box>
                      )
                    }
                    {
                      (d.text_type == LIST_DETAIL_ELEMENTS.text_types.manual_footer) && (
                        <Box
                          key={`article-${d.id}`}
                          id={`article-${d.id}`}
                          data-myattribute={d.ref_number}
                        >
                          <HtmlRenderer
                            tag={"div"}
                            className=""
                            style={{ fontSize: `${props.fontSize + 2}px`, marginBottom: '20px', marginTop: '10px' }}
                            htmlContent={d?.standard_content}
                          />
                          {
                            d.html_contents &&
                              d.html_contents.length > 0 ? (
                              d.html_contents.map((child) => (
                                <Box
                                  sx={{
                                    paddingX: '4px',
                                    textAlign: 'justify',
                                  }}
                                  key={`art-child-${child.id}`}
                                >
                                  <HtmlRenderer
                                    tag={"div"}
                                    style={{ fontSize: `${props.fontSize}px` }}
                                    htmlContent={child.content}
                                  />
                                </Box>
                              ))
                            ) : (
                              d.child && Object.keys(d.child).length > 0 && (
                                <Box
                                  key={`general-${d.child.id}`}
                                  id={`${d.child.id}`}
                                >
                                  <HtmlRenderer
                                    tag={"div"}
                                    className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type}
                                    style={{ fontSize: `${props.fontSize + 2}px` }}
                                    htmlContent={d.child?.standard_content}
                                  />
                                  {d.child.html_contents &&
                                    d.child.html_contents.length > 0 &&
                                    d.child.html_contents.map((child, i) => (
                                      <>
                                        {child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && (
                                          <Divider />
                                        )}
                                        <Box
                                          key={`gen-child-${child.id}`}
                                          sx={{
                                            display: 'flex',
                                            justifyContent: child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text ? 'flex-end' : 'normal',
                                          }}
                                        >
                                          <Box className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "text-justified" : 'justify-content-end'}>
                                            <HtmlRenderer
                                              tag={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "div" : "p"}
                                              style={{
                                                fontSize: `${props.fontSize}px`,
                                              }}
                                              htmlContent={child.content}
                                            />
                                          </Box>
                                        </Box>
                                      </>
                                    ))}
                                </Box>
                              )
                            )
                          }
                        </Box>
                      )
                    }
                  </Box>
                ))
              ) : (
                <Box sx={{ textAlign: 'center' }}>
                  <Spinner />
                </Box>
              )
            }
            {showScrollButton && (
              <IconButton
                onClick={scrollToTop}
                disableRipple
                sx={scrollToTopButtonStyle}
              >
                <NorthIcon
                  className="custom-scroll-top-button cursor-pointer"
                  fontSize="medium"
                />
              </IconButton>
            )}

          </Box>
        </Grid>
      </Grid>
    </>

  );

}

export default Articles 
