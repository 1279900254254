import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { displayNotification, sendAPIRequest } from "../../helpers";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "redux/generalSlice";
import DataTable from "pages/components/DataTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Spinner from "components/spinner/Spinner";
import EditModal from "components/modals/EditModal";
import ImageUpload from "./ImageUpload";
import { LIST_DETAIL_ELEMENTS } from '../../constants'

const FileCrack = () => {

  const authData = useSelector(s => s.auth.data);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState({
    load_documents: false,
    delete_document: false,
  });
  const [data, setData] = useState([]);
  const [options, setOption] = useState([])

  const editRef = useRef();
  const imageUploadRef = useRef();

  useEffect(() => {
    dispatch(setCurrentPage("data-migrations"));
    getDocuments();
  }, []);

  const getDocuments = async () => {
    setIsLoading({ ...isLoading, load_documents: true });
    try {
      const ret = await sendAPIRequest("/api/documents");
      const res = await ret.json();
      if (res.success) {
        setData(res.data);
      }
    } catch (e) {
      console.log({ e });
    }
    setIsLoading({ ...isLoading, load_documents: false });
  }

  const deleteDocumentData = async (id, html_content = false) => {
    if (window.confirm('This action will delete whole document with data')) {
      setIsLoading({ ...isLoading, delete_document: true });
      try {
        let ret;
        ret = !html_content
          ? await sendAPIRequest(`/api/documents/${id}`, 'DELETE')
          : await sendAPIRequest(`/api/html-contents/bulk-delete/${id}`);
        const res = await ret.json();
        if (res.success && res.status === 200) {
          displayNotification('success', 'Successfully deleted document and data [B]');
          !html_content && await getDocuments();
        } else {
          displayNotification('error', 'Error deleting document [B]');
        }
      } catch (e) {
        console.log({ e });
        displayNotification('error', 'Error deleting document [F]');
      }
      setIsLoading({ ...isLoading, delete_document: false });
    }
  }
  const deleteManual = async (id) => {
    if (window.confirm('This action will delete whole Manual Document with data')) {
      setIsLoading({ ...isLoading, delete_document: true });
      try {
        let ret;
        ret = await sendAPIRequest(`/api/raw_structure/${id}`, "DELETE");
        const res = await ret.json();
        if (res.success && res.status === 200) {
          displayNotification('success', 'Successfully deleted document and data [B]');
          await getDocuments();
        } else {
          displayNotification('error', 'Error deleting document [B]');
        }
      } catch (e) {
        console.log({ e });
        displayNotification('error', 'Error deleting document [F]');
      }
      setIsLoading({ ...isLoading, delete_document: false });
    }
  }
  const deleteManualContent = async (id) => {
    if (window.confirm('This action will delete whole Manual Document with data')) {
      setIsLoading({ ...isLoading, delete_document: true });
      try {
        let ret;
        ret = await sendAPIRequest(`/api/html-contents/delete-manual-content/${id}`, "DELETE");
        const res = await ret.json();
        if (res.success && res.status === 200) {
          displayNotification('success', 'Successfully deleted document and data [B]');
          //  await getDocuments();
        } else {
          displayNotification('error', 'Error deleting document [B]');
        }
      } catch (e) {
        console.log({ e });
        displayNotification('error', 'Error deleting document [F]');
      }
      setIsLoading({ ...isLoading, delete_document: false });
    }
  }

  const markSection = async (sectionNumber, document_id, language_id) => {
    try {
      const ret = await sendAPIRequest(
        `/api/documents/edit-section`,
        'POST',
        {
          document_id: document_id,
          language_id: language_id,
          ref_number: sectionNumber
        }
      );
      const res = await ret.json();
      if (res.success && res.status === 200) {
        displayNotification('success', 'Successfully updated section [B]');
        await getDocuments();
      } else if (res.status === 401) {
        displayNotification('info', `Provided section(${sectionNumber}) is not available [B]`);
      } else {
        displayNotification('error', 'Failed to update section [B]');
      }
    } catch (e) {
      console.log({ e });
      displayNotification('error', 'Failed to update section [F]');
    }
  }



  return (
    <>
      <EditModal ref={editRef} />
      <ImageUpload ref={imageUploadRef} />
      <div className="card card-woah border border-secondary shadow rounded-0 my-2">
        <div className="card-body p-4">
          <div className="d-flex justify-content-between align-items-xl-baseline">
            <h5 className="card-title2 m-0">
              {t("-1", "File Crack")}{" "}
            </h5>
          </div>
        </div>
      </div>
      <div className="card card-woah border border-secondary shadow rounded-0 my-2">
        <div className="card-body p-4">
          <DataTable
            getRowId={(row) => row.id}
            isLoading={isLoading.load_documents}
            rows={data}
            className="text-white bg-primary rounded-0"
            columns={
              [
                {
                  field: "document_name",
                  headerName: t("-1", "Document Name"),
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "animal_type",
                  headerName: t("-1", "Animal Type"),
                  valueGetter: (params) => {
                    return params.row?.animal?.list_element_name;
                  },
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "standard_type",
                  headerName: t("-1", "Standard Type"),
                  valueGetter: (params) => {
                    return params.row?.standard?.list_element_name;
                  },
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "disease_name",
                  headerName: t("-1", "Disease Name"),
                  valueGetter: (params) => {
                    return params.row?.disease_name?.list_element_name;
                  },
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 3,
                },
                {
                  field: "language",
                  headerName: t("-1", "Language"),
                  valueGetter: (params) => {
                    return params.row?.language?.list_element_name;
                  },
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "is_cracked",
                  headerName: t("-1", "Is Cracked"),
                  valueGetter: (params) => {
                    return params.row?.is_cracked ? "True" : "False"
                  },
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "is_migrated",
                  headerName: t("-1", "Is Migrated"),
                  valueGetter: (params) => {
                    return params.row?.is_migrated ? "True" : "False"
                  },
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "has_section",
                  headerName: t("-1", "Has Section"),
                  valueGetter: (params) => {
                    return params.row?.has_section ? "True" : "False"
                  },
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "is_completed_htm_mapping",
                  headerName: t("-1", "Completed Html Mapping"),
                  valueGetter: (params) => {
                    return params.row?.is_completed_htm_mapping ? "True" : "False"
                  },
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "actions",
                  type: "actions",
                  headerClassName: "text-white bg-primary rounded-0",
                  headerName: t("74", "Actions"),
                  getActions: (params) =>
                    params.row.standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes ?
                      [
                        <GridActionsCellItem
                          label={
                            "HTML Data Mapping"
                          }
                          key={0}
                          showInMenu
                          onClick={() =>
                            window.open(`/portal/data-migrations/html-data-mapping/${params.row.id}`, "_blank")
                          }
                        />,
                        <GridActionsCellItem
                          label={
                            "Chapter Preview"
                          }
                          key={0}
                          showInMenu
                          onClick={() =>
                            window.open(`/portal/html-chapter/?document=${params.row.id}`, "_blank")
                          }
                        />,
                        <GridActionsCellItem
                          label={t("-1", "Manual Data Mapping")}
                          key={0}
                          showInMenu
                          onClick={() => {
                            return window.open(`/portal/data-migrations/data-manual-mapping/${params.row.id}?page=data-mapping&meta-data=true&is-migrated=${params.row?.is_migrated ? "true" : "false"}`, "_blank");
                            // return window.open(`/portal/data-migrations/data-mapping/${params.row.id}?page=data-mapping&meta-data=true&is-migrated=${params.row?.is_migrated ? "true" : "false"}`, "_blank");
                          }
                          }
                        />,
                        <GridActionsCellItem
                          label={t("-1", "Data Mapping")}
                          key={0}
                          showInMenu
                          onClick={() => {
                            return window.open(`/portal/data-migrations/data-mapping/${params.row.id}?page=data-mapping&meta-data=true&is-migrated=${params.row?.is_migrated ? "true" : "false"}`, "_blank");
                            // return window.open(`/portal/data-migrations/data-mapping/${params.row.id}?page=data-mapping&meta-data=true&is-migrated=${params.row?.is_migrated ? "true" : "false"}`, "_blank");
                          }
                          }
                        />,
                        // ...(
                        //   params.row?.contents && params.row?.contents.length > 0 ?
                        //     [
                        //       <GridActionsCellItem
                        //         label={t("-1", "Style Mapping")}
                        //         key={0}
                        //         showInMenu
                        //         onClick={() => {
                        //           return window.open(`/portal/data-migrations/data-mapping/${params.row.id}?page=style-mapping&meta-data=false`, "_blank");
                        //         }
                        //         }
                        //       />,
                        //     ] : []
                        // ),
                        <GridActionsCellItem
                          label={t("-1", "Edit Documnet MetaData")}
                          key={0}
                          showInMenu
                          onClick={() => {

                            return window.open(`/portal/data-migrations/?document_id=${params.row.id}&page=upload-file&standard=${params.row?.standard?.list_element_name}`, "_blank");
                          }
                          }
                        />,
                        <GridActionsCellItem
                          label={t("-1", "Image Upload")}
                          key={0}
                          showInMenu
                          onClick={() => {
                            return imageUploadRef.current.handleOpen(params.row.id, () => { });
                          }
                          }
                        />,
                        <GridActionsCellItem
                          label={t("-1", "View Images")}
                          key={0}
                          showInMenu
                          onClick={() => {
                            return window.open(`/portal/data-migrations/images/${params.row.id}`, "_blank");
                          }
                          }
                        />,
                        <GridActionsCellItem
                          label={t("-1", "Image Mapping")}
                          key={0}
                          showInMenu
                          onClick={() => {
                            if (params.row.standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals) {
                              return window.open(`/portal/data-migrations/image-mapping/${params.row.id}?iscode=false`, "_blank");
                            }
                            else {
                              return window.open(`/portal/data-migrations/image-mapping/${params.row.id}?iscode=true`, "_blank");
                            }
                          }
                          }
                        />,
                        // <GridActionsCellItem
                        //   label={t("-1", "Glossary Mapping")}
                        //   key={0}
                        //   showInMenu
                        //   onClick={() => {
                        //     return window.open(`/portal/data-migrations/ref-tag-mapping/${params.row?.contents[0]?.id}`, "_blank");

                        //   }
                        //   }
                        // />,
                        <GridActionsCellItem
                          label={
                            isLoading.delete_document
                              ? <Spinner message="Deleting..." />
                              : t("-1", "Delete HTML Content")
                          }
                          disabled={isLoading.delete_document}
                          key={0}
                          showInMenu
                          onClick={() => deleteDocumentData(params.row.id, true)}
                        />,
                        <GridActionsCellItem
                          label={
                            isLoading.delete_document
                              ? <Spinner message="Deleting..." />
                              : t("-1", "Delete Document")
                          }
                          disabled={isLoading.delete_document}
                          key={0}
                          showInMenu
                          onClick={() => deleteDocumentData(params.row.id, false)}
                        />,
                        <GridActionsCellItem
                          label={
                            "Edit Section"
                          }
                          key={0}
                          showInMenu
                          onClick={() =>
                            editRef.current.handleOpen('', "Section Number", (content) => markSection(content, params.row.id, params.row.language_id))
                          }
                        />,
                      ] :
                      [
                        <GridActionsCellItem
                          label={t("-1", "Data Manual Mapping")}
                          key={0}
                          showInMenu
                          onClick={() => {
                            return window.open(`/portal/data-migrations/data-manual-mapping/${params.row.id}?page=data-mapping&meta-data=true&is-migrated=${params.row?.is_migrated ? "true" : "false"}`, "_blank");
                            // return window.open(`/portal/data-migrations/data-mapping/${params.row.id}?page=data-mapping&meta-data=true&is-migrated=${params.row?.is_migrated ? "true" : "false"}`, "_blank");
                          }
                          }
                        />,

                        <GridActionsCellItem
                          label={
                            "Manual Chapter Preview"
                          }
                          key={0}
                          showInMenu
                          onClick={() =>
                            window.open(`/portal/manual-chapter/${params.row.id}`, "_blank")
                          }
                        />,
                        // ...(
                        //   params.row?.contents && params.row?.contents.length > 0 ?
                        //     [
                        //       <GridActionsCellItem
                        //         label={t("-1", "Style Mapping")}
                        //         key={0}
                        //         showInMenu
                        //         onClick={() => {
                        //           return window.open(`/portal/data-migrations/data-mapping/${params.row.id}?page=style-mapping&meta-data=false`, "_blank");
                        //         }
                        //         }
                        //       />,
                        //     ] : []
                        // ),
                        <GridActionsCellItem
                          label={t("-1", "Image Upload")}
                          key={0}
                          showInMenu
                          onClick={() => {
                            return imageUploadRef.current.handleOpen(params.row.id, () => { });
                          }
                          }
                        />,
                        <GridActionsCellItem
                          label={t("-1", "View Images")}
                          key={0}
                          showInMenu
                          onClick={() => {
                            return window.open(`/portal/data-migrations/images/${params.row.id}`, "_blank");
                          }
                          }
                        />,
                        <GridActionsCellItem
                          label={t("-1", "Image Mapping")}
                          key={0}
                          showInMenu
                          onClick={() => {
                            if (params.row.standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals) {
                              return window.open(`/portal/data-migrations/image-mapping/${params.row.id}?iscode=false`, "_blank");
                            }
                            else {
                              return window.open(`/portal/data-migrations/image-mapping/${params.row.id}?iscode=true`, "_blank");
                            }
                          }
                          }
                        />,
                        <GridActionsCellItem
                          label={
                            isLoading.delete_document
                              ? <Spinner message="Deleting..." />
                              : t("-1", "Delete Manual")
                          }
                          disabled={isLoading.delete_document}
                          key={0}
                          showInMenu
                          onClick={() => deleteManual(params.row.id)}
                        />,
                        <GridActionsCellItem
                          label={
                            isLoading.delete_document
                              ? <Spinner message="Deleting..." />
                              : t("-1", "Delete Manual Content")
                          }
                          disabled={isLoading.delete_document}
                          key={0}
                          showInMenu
                          onClick={() => deleteManualContent(params.row.id)}
                        />,
                        <GridActionsCellItem
                          label={
                            "Edit Section"
                          }
                          key={0}
                          showInMenu
                          onClick={() =>
                            editRef.current.handleOpen('', "Section Number", (content) => markSection(content, params.row.id, params.row.language_id))
                          }
                        />,
                      ],
                  width: 60,
                  flex: 1,
                },
              ]
            }
          />
        </div>
      </div>
    </>
  );
};

export default FileCrack;
