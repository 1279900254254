import { useRef } from "react";

const CustomTab = ({id, children, className, ref, style, onClick, customKey}) => {

    const tabRef = useRef(null);
    const currentRef = tabRef || ref;
    const defaultStyle = 'list-group-item rounded-0 list-group-item-action fs-5 fw-bold bg-list border-custom cursor-pointer';

    return (
        <a id={id} className={`${defaultStyle} ${className}`} ref={currentRef} style={style} onClick={onClick} key={customKey}>
            {children}
        </a>
    );
}

CustomTab.defaultProps = {
    id: 'custom-tab',
    customKey: 'custom-tab-key',
    children: <></>,
    className: '',
    ref: null,
    style: {},
    onClick: () => {},
};

export default CustomTab;