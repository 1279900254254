import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { sendAPIRequest, generateOptionsFromData, displayNotification } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "../../../redux/generalSlice";
import { useTranslation } from "react-i18next";
import AutoCompleteDropdown from "../../../components/autocomplete/AutoCompleteDropdown";
import Crumbs from "components/crumbs/Crumbs";
import TextFieldCustom from "components/TextFieldCustom";

const Form = () => {
  const dispatch = useDispatch();
  const { keywordId } = useParams();
  const userId = useSelector((s) => s.auth.data.id);
  const initialForm = {
    tag: "",
    description: "",
    language_id: "",
    name_space: "",
    createdBy: userId,
    updatedBy: userId,
  };

  const [form, setForm] = useState({ ...initialForm });
  const [languages, setLanguages] = useState([]);
  const [nameSpaces, setNameSpaces] = useState([]);
  const [checkRequestSent, setRequestSent] = useState(false);
  const { t } = useTranslation();
  const crumbs = [
    {
      path: "/portal/multilingual-keywords",
      label: t("12", "Multilingual Keywords"),
      active: false,
    },
    {
      // path:
      //   keywordId == undefined
      //     ? "/portal/multilingual-keywords/create"
      //     : `/portal/multilingual-keywords/${keywordId}/edit`,
      label:
        keywordId == undefined
          ? t("94", "Add New") + " " + t("93", "Multilingual Keyword")
          : t("80", "Edit") + " " + t("93", "Multilingual Keyword"),
      active: true,
    },
  ];
  useEffect(() => {
    dispatch(setCurrentPage("data-migration"));
    loadLanguages();
    loadNameSpaces();
    if (keywordId != undefined) {
      loadData();
    }
  }, []);

  const loadData = async () => {
    try {
      const req = await sendAPIRequest(`/api/multilingual/${keywordId}`);
      const res = await req.json();
      if (res.success) {
        setForm({ ...initialForm, ...res.data, updatedBy: userId });
      } else {
        displayNotification('error', `${t("925", "Error loading multilingual keyword data")} [B]`);
      }
    } catch (e) {
      console.log(e);
      displayNotification('error', `${t("925", "Error loading multilingual keyword data")} [F]`);
    }
  };

  const loadNameSpaces = async () => {
    try {
      const req = await sendAPIRequest(`/api/lists/master/10`);
      const res = await req.json();
      if (res.success) {
        setNameSpaces(res.data);
      } else {
        displayNotification('error', `${t("926", "Error loading languages data")} [B]`);
      }
    } catch (e) {
      console.log(e);
      displayNotification('error', `${t("926", "Error loading languages data")} [F]`);
    }
  };

  const loadLanguages = async () => {
    try {
      const req = await sendAPIRequest(`/api/lists/master/8?ref_id=1`);
      const res = await req.json();
      if (res.success) {
        setLanguages(res.data);
      } else {
        displayNotification('error', `${t("926", "Error loading languages data")} [B]`);
      }
    } catch (e) {
      console.log(e);
      displayNotification('error', `${t("926", "Error loading languages data")} [F]`);
    }
  };

  const handleSave = async () => {
    const successKeyword = keywordId == undefined ? t("216", "created") : t("217", "saved");
    const errorKeyword = keywordId == undefined ? t("218", "creating") : t("219", "saving");
    try {
      if (
        window.confirm(
          t("220", "You are about to save a record. Do you want to continue?")
        )
      ) {
        setRequestSent(true);
        const req = await sendAPIRequest(
          keywordId == undefined
            ? `/api/multilingual`
            : `/api/multilingual/${keywordId}`,
          keywordId == undefined ? "POST" : "PUT",
          JSON.stringify(form),
          { "Content-Type": "application/json" }
        );
        const res = await req.json();
        if (res.success) {
          if (keywordId == undefined) {
            setForm({ ...initialForm });
          }
          displayNotification('success', `Successfully ${successKeyword} multilingual keyword`);
        } else {
          displayNotification('error', `Error ${errorKeyword} multilingual keyword [B]`);
        }
        setRequestSent(false);
      }
    } catch (e) {
      setRequestSent(false);
      console.log(e);
      displayNotification('error', `Error ${errorKeyword} multilingual keyword [F]`);
    }
  };

  const languagesOptions = useMemo(() => {
    return generateOptionsFromData(languages, {
      value: "id",
      label: "list_element_name",
    });
  }, [languages]);
  const nameSpaceOptions = useMemo(() => {
    return generateOptionsFromData(nameSpaces, {
      value: "id",
      label: "list_element_name",
    });
  }, [nameSpaces]);

  return (
    <>
      <div className="container my-2">
        <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2">
          <div className='d-flex justify-content-between'>
            <div className="card-title1 lh-1">
              {keywordId == undefined ? t("94", "Add New") : t("80", "Edit")}{" "}
              {t("93", "Multilingual Keyword")}
            </div>
          </div>
          <div className="header-right-items ms-auto d-flex align-items-center">
            <Link to={"/portal/data-migrations"} className="ms-auto">
              <button className="btn btn--primary">
                <i className="fas fa-angle-left" />
                <span>{t("47", "Back")}</span>
              </button>
            </Link>
          </div>
        </div>
        <div className="card card-woah border border-secondary shadow rounded-0">
          <div className="card-body p-4">
            <div className="row">
              <div className="col-md-2 col-sm-6 col-12 mt-auto">
                <div className="form-group">
                  <AutoCompleteDropdown
                    showClearIcon={false}
                    error={false}
                    required={false}
                    multiple={false}
                    label={t("26", "Language")}
                    hasselectall={false}
                    options={languagesOptions}
                    onChange={(event, value) => {
                      setForm({ ...form, language_id: value.value });
                    }}
                    value={
                      form.language_id ?
                        languagesOptions.find(
                          (r) => r.value == form.language_id
                        ) : null
                    }
                  />
                </div>
              </div>
              <div className="col-md-2 col-sm-6 col-12 mt-auto">
                <div className="form-group">
                  <AutoCompleteDropdown
                    showClearIcon={false}
                    error={false}
                    required={false}
                    multiple={false}
                    label={t("-1", "NameSpace")}
                    hasselectall={false}
                    options={nameSpaceOptions}
                    onChange={(event, value) => {
                      setForm({ ...form, name_space: value.value });
                    }}
                    value={
                      form.name_space ?
                      nameSpaceOptions.find(
                          (r) => r.value == form.name_space
                        ) : null
                    }
                  />
                </div>
              </div>
              <div className="col-md-2 col-sm-6 col-12 mt-2">
                <div className="form-group">
                  <TextFieldCustom
                    type="text"
                    required={true}
                    className="form-control"
                    label={t("408", "Tag")}
                    value={form.tag}
                    onChange={(e) => setForm({ ...form, tag: e.target.value })}
                    fullWidth
                    size="small"
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-12 mt-2">
                <div className="form-group">
                  <TextFieldCustom
                    type="text"
                    required={true}
                    className="form-control"
                    label={t("84", "Keyword")}
                    value={form.description}
                    onChange={(e) => setForm({ ...form, description: e.target.value })}
                    fullWidth
                    size="small"
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-5 col-10 d-flex justify-content-end">
                <button
                  className="btn btn--primary"
                  disabled={checkRequestSent}
                  onClick={() => handleSave()}
                >
                  <i className="fa-sharp fa-solid fa-check"></i>
                  <span>{t("85", "Save")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
