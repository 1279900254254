import React from 'react'
import logoG from '../../../../images/woah.png';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import { BsInstagram, BsTwitter, BsYoutube } from 'react-icons/bs'
import { ImFlickr } from 'react-icons/im'
import { Link } from 'react-router-dom';
import { APP_VERSION } from '../../../../constants';
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="footer py-10">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logoG} className='mb-3'  height="60px" />
          </Link>
          <hr className="bg-white border-2 border-top border-gray-600" />
          <div className="row g-0 my-10">
            <div className="col-md-3 col-sm-6 col-12 px-5 border-end border-gray-600">
              <h1 className='h4 text-white mb-5 text-uppercase font-custom fw-bold'>{t("571", "Regions")}</h1>
              <ul className='footer-col-ul fs-6'>
                <li style={{ listStyleType: "none"}} className='mb-4'><Link to="/" className='fw-normal text-white font-custom'>{t("718", "Africa")}</Link></li>
                <li style={{ listStyleType: "none"}} className='mb-4'><Link to="/" className='fw-normal text-white font-custom'>{t("719", "Americas")}</Link></li>
                <li style={{ listStyleType: "none"}} className='mb-4'><Link to="/" className='fw-normal text-white font-custom'>{t("720", "Asia and the pacific")}</Link></li>
                <li style={{ listStyleType: "none"}} className='mb-4'><Link to="/" className='fw-normal text-white font-custom'>{t("721", "Europe")}</Link></li>
                <li style={{ listStyleType: "none"}} className='mb-4'><Link to="/" className='fw-normal text-white font-custom'>{t("722", "Middle East")}</Link></li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-6 col-12 px-5 border-end border-gray-600">
              <h1 className='h4 text-white mb-5 text-uppercase font-custom fw-bold'>WOAH</h1>
              <ul className='footer-col-ul fs-6'>
                <li style={{ listStyleType: "none"}} className='mb-4'><Link to="/" className='fw-normal text-white font-custom'>{t("203", "Contact")}</Link></li>
                <li style={{ listStyleType: "none"}} className='mb-4'><Link to="/" className='fw-normal text-white font-custom'>{t("723", "Career")}</Link></li>
                <li style={{ listStyleType: "none"}} className='mb-4'><Link to="/ " className='fw-normal text-white font-custom'>{t("724", "Procurement")}</Link></li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-6 col-12 px-5 border-end border-gray-600">
              <h1 className="h4 text-white mb-5 text-uppercase font-custom fw-bold">{t("725", "Follow us")}</h1>
              <div className="footer-col-social-links">
                <a href="https://www.facebook.com/worldanimalhealth" className='rounded-circle text-center d-inline-block'><FaFacebookF /></a>
                <a href="https://twitter.com/WOAH" className='rounded-circle text-center d-inline-block ms-2'><BsTwitter /></a>
                <a href="https://www.linkedin.com/company/worldanimalhealth/" className='rounded-circle text-center d-inline-block ms-2'><FaLinkedinIn /></a>
                <a href="https://www.instagram.com/worldanimalhealth/" className='rounded-circle text-center d-inline-block ms-2'><BsInstagram /></a>
                <a href="https://www.youtube.com/c/WorldAnimalHealth" className='rounded-circle text-center d-inline-block ms-2'><BsYoutube /></a>
                <a href="https://www.flickr.com/photos/woah-photos/" className='rounded-circle text-center d-inline-block ms-2'><ImFlickr /></a>
              </div>
            </div>
          </div>
          <hr className="bg-white border-2 border-top border-gray-300" />
          <ul className="list-group list-group-horizontal footer-bottom-text fs-6">
            <li style={{ listStyleType: "none"}} className="list-group-item border-right border-gray-600 border-top-0 border-bottom-0 border-start-0 text-white pe-2">{t("726", "Terms & Conditions")}</li>
            <li style={{ listStyleType: "none"}} className="list-group-item border-right border-gray-600 border-top-0 border-bottom-0 border-start-0 text-white px-2">{t("727", "Privacy Policy")}</li>
            <li style={{ listStyleType: "none"}} className="list-group-item border-0 text-white ps-2">{t("728", "Copyright © World Organisation for Animal Health")} {currentYear}</li>
            <li style={{ listStyleType: "none"}} className="list-group-item border-0 text-white ms-auto">
              <Link to='/changelog' className='text-secondary'>{APP_VERSION}</Link>
              <span style={{
                marginLeft: '10px',
              }}>
              <Link to='/portal/system-status' className='text-secondary'>System Status</Link>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Footer
