import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Tooltip, Typography, styled, tooltipClasses } from "@mui/material";
import CardBody from "components/common/CardBody";
import CardHeader from "components/common/CardHeader";
import CustomCard from "components/common/CustomCard";
import StandardsSelectCard from "components/common/StandardsSelectCard";
import { LIST_DETAIL_ELEMENTS } from "constants";
import { useEffect, useMemo, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import { useSelector } from "react-redux";
import { generateOptionsFromData, sendAPIRequest, displayNotification, hasPermissionForTerrestrialCode, hasPermissionsForEN, hasPermissionForTerrestrialManual, hasPermissionForAquaticCode, hasPermissionForAquaticManual, hasPermissionsForFR, hasPermissionsForES, stripHtml } from "helpers";
import AutoCompleteDropdown from "components/autocomplete/AutoCompleteDropdown";

const initStandard = { animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard_type: LIST_DETAIL_ELEMENTS.standard_type.codes, standard: 'terrestrial-code' };

const StructureNavigationComponent = ({ reloadData, config, reFetch = '102', chilldCheck = false, model = '' }) => {

    const { t } = useTranslation();
    const siteLanguage = useSelector(s => s.general.siteLanguageSont);
    const authData = useSelector(s => s.auth?.data);
    const [componentLanguage, setComponentLanguage] = useState(siteLanguage ?? "102");
    const [selectedStandard, setSelectedStandard] = useState(initStandard);
    const currentDate = new Date().toISOString().slice(0, 10);
    const [isStandardVisible, setIsSetandardVisible] = useState(true);
    const [isFilterVisible, setIsFilterVisible] = useState(true);
    const [loader, setLoader] = useState({
        loading_volumes: false,
        loading_sections: false,
        loading_chapters: false,
        loading_subsections: false,
    });
    const [isChapterLoading ,setIsChapterLoading] = useState(false);
    const [sections, setSections] = useState([]);
    const [volumes, setVolumes] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [subSections, setSubSections] = useState([]);
    const [codesSectionsGroups, setCodesSectionsGroups] = useState([]);
    const [volumesGroups, setVolumesGroups] = useState([]);
    const [selectedVolume, setSelectedVolume] = useState({
        value:"",
        label:""
    });
    const [selectedSection, setSelectedSection] = useState({
        value:"",
        label:"",
        group_value:"",
        id:"",
        ref_no:""
    });
    const [selectedChapter, setSelectedChapter] = useState({
        value:"",
        label:"",
        group_value:"",
        id:"",
        ref_no:""
    });
    const [selectedSubSection, setSelectedSubSection] = useState({});
    const [fileName, setFileName] = useState("");
    const [currentFile, setCurrentFile] = useState();
    const [sectionMetaData, setSectionMetaData] = useState({ ref_number: '', id: '', volume_no: '' });

    const browseFileRef = useRef();
    const initStructure = {
        animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial,
        standard_type: LIST_DETAIL_ELEMENTS.standard_type.codes,
        language_id: siteLanguage === reFetch ? siteLanguage : reFetch,
        volume: {},
        section: {},
        chapter: {},
        sub_section: {},
        chapter_id: '',
        file: {
            currentFile: null,
            fileName: ""
        }
    };
    const [selectedStructures, setSelectedStructures] = useState(initStructure);

    useEffect(() => {
        if (reFetch !== componentLanguage && chilldCheck) {
            updateLanguage()
        }
        loadData();
        handleSetName();
    }, [componentLanguage, selectedStandard, reFetch]);

    const updateLanguage = () => {
        setComponentLanguage(reFetch);
        setSelectedStructures({...selectedStructures, language_id: reFetch });
    }

    useEffect(() => {
        loadSections();
    }, []);

    useEffect(() => {
        loadChapters();
        handleSetVolumn();
    }, [selectedSection?.value]);

    useEffect(() => {
        if (selectedChapter?.value) {
            handleSetSection()
            loadSubSections();
        }
    }, [selectedChapter?.value, selectedStructures?.chapter_id]);

    useEffect(() => {
        reloadData(selectedStructures);
    }, [selectedStructures])
    
    useEffect(() => {
   if(selectedSection?.ref_no===0){
    // let chap = sections?.find((i) => i.group_name === selectedSection?.value);
    // if(chap) {
    //     let id = await returnChapterId(chap?.RID,chap?.lang_id,true)
    //     chap['id'] =id;
    //     setSelectedChapter({ value: chap?.group_name, label: chap?.display_name, group_value:'', id: "", ref_no: chap?.chapter_no });
    //     setSelectedStructures({...selectedStructures, chapter:chap });
    // }
    handleSetForZeroChapter();
   }
   return;
    }, [selectedSection.value]);


const handleSetForZeroChapter =async  ()=> {
    let chap = sections?.find((i) => i.group_name === selectedSection?.value);
    if(chap) {
        let id = await returnChapterId(chap?.RID,chap?.lang_id,chap,true)
        chap['id'] = id;
        setSelectedChapter({ value: chap?.group_name, label: chap?.display_name, group_value:'', id: "", ref_no: chap?.chapter_no });
        setSelectedStructures({...selectedStructures, chapter:chap });
    }
}
    const loadData = async () => {
        await loadVolumes();
        await loadSections();
        await loadChapters();
    }

    const handleSetSection = () => {
        const chapt = chapters?.find((i) => i.group_name === selectedChapter?.value);
        if(chapt) {
            let section = sections?.find((i)=>i.group_name ===chapt.section_group);
            if(section) {
                setSelectedStructures({ ...selectedStructures, section: section });
                setSelectedSection({
                    value:section.group_name,
                    label:section.display_name,
                    group_value:section.area_vol_no,
                    id:"",
                    ref_no:section.section_no
                });
            }
        }
    }

    const handleSetVolumn = () => {
        const sect = sections?.find((i) => i.group_name === selectedSection?.value);
        if(sect) {
            let vol = volumes?.find((i)=>i.area_vol_no ===sect.area_vol_no);
            if(vol) {
                setSelectedStructures({ ...selectedStructures, volume: vol });
                setSelectedVolume({
                    value:vol.area_vol_no,
                    label:vol.area_vol_name,
                    group_value:"",
                    id:"",
                    ref_no:""
                });
            }
        }
    }

    const handleChange = async (event) => {
        const standard = event.target.value;
        switch (event.target.value) {
            case 'terrestrial-code':
                setSelectedStandard({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard_type: LIST_DETAIL_ELEMENTS.standard_type.codes, standard });
                setSelectedStructures({...selectedStructures,
                    volume: {}, section: {}, chapter: {}, sub_section: {}, file: {
                        currentFile: null,
                        fileName: ""
                    }, animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard_type: LIST_DETAIL_ELEMENTS.standard_type.codes
                });
                break;
            case 'terrestrial-manual':
                setSelectedStandard({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard_type: LIST_DETAIL_ELEMENTS.standard_type.manuals, standard });
                setSelectedStructures({...selectedStructures,
                    volume: {}, section: {}, chapter: {}, sub_section: {}, file: {
                        currentFile: null,
                        fileName: ""
                    }, animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard_type: LIST_DETAIL_ELEMENTS.standard_type.manuals
                });
                break;
            case 'aquatic-code':
                setSelectedStandard({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.aquatic, standard_type: LIST_DETAIL_ELEMENTS.standard_type.codes, standard });
                setSelectedStructures({...selectedStructures,
                    volume: {}, section: {}, chapter: {}, sub_section: {}, file: {
                        currentFile: null,
                        fileName: ""
                    }, animal_type: LIST_DETAIL_ELEMENTS.animal_type.aquatic, standard_type: LIST_DETAIL_ELEMENTS.standard_type.codes
                });
                break;
            case 'aquatic-manual':
                setSelectedStandard({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.aquatic, standard_type: LIST_DETAIL_ELEMENTS.standard_type.manuals, standard });
                setSelectedStructures({...selectedStructures,
                    volume: {}, section: {}, chapter: {}, sub_section: {}, file: {
                        currentFile: null,
                        fileName: ""
                    }, animal_type: LIST_DETAIL_ELEMENTS.animal_type.aquatic, standard_type: LIST_DETAIL_ELEMENTS.standard_type.manuals
                });
                break;
            default:
                return '';
        }
        setSelectedVolume({
            value:"",
            label:"",
            group_value:"",
            id:"",
            ref_no:""
        });
        setSelectedSection({ value:"",
            label:"",
            group_value:"",
            id:"",
            ref_no:""});
        setSelectedChapter({
            value:"",
            label:"",
            group_value:"",
            id:"",
            ref_no:""
        });
    };

    const getHeaderText = () => {
        switch (selectedStandard.standard) {
            case 'terrestrial-code':
                return t("136", 'Terrestrial Animal Health Code');
            case 'terrestrial-manual':
                return t('137', 'Manual of Diagnostic Tests and Vaccines for Terrestrial Animals');
            case 'aquatic-code':
                return t('138', 'Aquatic Animal Health Code');
            case 'aquatic-manual':
                return t('139', 'Manual of Diagnostic Tests for Aquatic Animals');
            default:
                return '';
        }
    };

    const getVolumesGroup = (volumes) => {
        let obj = {};
        volumes.forEach(volume => {
            obj[volume.area_vol_no] = volume.area_vol_name;
        });
        setVolumesGroups(obj);
    }

    const loadVolumes = async () => {
        setLoader({ ...loader, loading_volumes: true });
        try {
            const ret = await sendAPIRequest(`/api/standard-structures/volumes/?model=${model}&lang_id=${componentLanguage}&animal_type=${selectedStandard.animal_type}&standard_type=${selectedStandard.standard_type}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setVolumes(res.data);
                getVolumesGroup(res.data);
                setLoader({ ...loader, loading_volumes: false });
            }
        } catch (e) {
            console.log({ e });
            setLoader({ ...loader, loading_volumes: false });
        }
    }

    const setCodesSectionsGroupsList = (sect = []) => {
        let obj = {};
        sect.forEach(section => {
            obj[section.group_name] = section.display_name;
        });
        setCodesSectionsGroups(obj);
    }

    const loadSections = async () => {
        setLoader({ ...loader, loading_sections: true });
        try {
            const ret = await sendAPIRequest(`/api/standard-structures/sections/?model=${model}&lang_id=${componentLanguage}&animal_type=${selectedStandard.animal_type}&standard_type=${selectedStandard.standard_type}&area_vol_no=${''}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setSections(res.data);
                setCodesSectionsGroupsList(res.data);
                setLoader({ ...loader, loading_sections: false });
            }
        } catch (e) {
            console.log({ e });
            setLoader({ ...loader, loading_sections: false });
        }
    }

    const returnChapterId = async (RID, lang_id, currentValue,returnId=false) => {
        let id = ''
        try {
            const ret = await sendAPIRequest(`/api/contents/chapter-id/?lang_id=${lang_id}&RID=${RID}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                id=res?.data??'';
                if(!returnId){
                    currentValue['id'] = res.data;
                    setSelectedStructures({ ...selectedStructures, chapter: currentValue, chapter_id: currentValue.id });
                }
                return res.data
            } else {
                setSelectedStructures({ ...selectedStructures, chapter: currentValue, chapter_id: currentValue?.id ?? '' });
                return null;
            };
        } catch (e) {
            console.log({ e });
            setLoader({ ...loader, loading_sections: false });
        }
        return id;
    }

    const loadChapters = async () => {
        setIsChapterLoading(true)
        let tmp = returnAreaVolum(selectedSection.value)
        try {
            const ret = await sendAPIRequest(`/api/standard-structures/chapters/?model=${model}&lang_id=${componentLanguage}&animal_type=${selectedStandard.animal_type}&standard_type=${selectedStandard.standard_type}&area_vol_no=${selectedStructures.standard_type===LIST_DETAIL_ELEMENTS.standard_type.codes&&selectedStructures.animal_type===LIST_DETAIL_ELEMENTS.animal_type.aquatic ?"":returnAreaVolum(selectedSection.value) }&section_no=${selectedSection?.ref_no ?? ''}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                const modifyChapterName = changeChaptersName(res.data);
                setChapters(modifyChapterName);
            }
        } catch (e) {
            console.log({ e });
        }
        setIsChapterLoading(false);
    }

    const returnAreaVolum = (str='') => {
        let x = str.split('.')[0];
        return x ? x : '';
    }

    const handleModifySubHeading = (data = []) => {
        if (data.length === 0) {
            return data;
        } else {
            for (let x of data) {
                x.standard_content = stripHtml(x.standard_content, true);
            }
            return data = data.sort((a, b) => a.standard_content - b.standard_content);
        }
    }

    const loadSubSections = async () => {
        setLoader({ ...loader, loading_subsections: true });
        try {
            const ret = await sendAPIRequest(`/api/contents/chapter-subsections/?model=${model}&chapter_id=${selectedStructures.chapter_id}&standard_type=${selectedStructures.standard_type}`)
            const res = await ret.json();
            if (res.success && res.status === 200) {
                let data = [];
                if (selectedStructures.standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals) {
                    data = handleModifySubHeading(res.data);
                } else {
                    data = res.data;
                }
                setSubSections(data);
            }
        } catch (e) {
            console.log({ e });
        }
        setLoader({ ...loader, loading_subsections: false });
    }

    const volumeOptions = useMemo(() => {
        return generateOptionsFromData(volumes, {
            value: "area_vol_no",
            label: "area_vol_name",
        }
        );
    }, [volumes]);

    const sectionOptions = useMemo(() => {
        return generateOptionsFromData(sections, {
            value: "group_name",
            label: "display_name",
            group_value: 'area_vol_no',
            ref_no: 'section_no'
        },
            false,
            true,
            volumesGroups
        );
    }, [sections, volumesGroups]);

    const chapterOptions = useMemo(() => {
        return generateOptionsFromData(chapters, {
            value: "group_name",
            label: "display_name",
            group_value: 'section_group',
            ref_no: 'chapter_no'
        },
            false,
            true,
            codesSectionsGroups
        );
    }, [chapters, codesSectionsGroups]);

    const subSectionOptions = useMemo(() => {
        return generateOptionsFromData(subSections, {
            value: "id",
            label: "standard_content",
            ref_no: 'ref_number'
        }
        );
    }, [subSections]);


    useEffect(() => {
        handleSetName();
    }, [chapterOptions]);


    const fileUpload = () => {
        setFileName("");
        setCurrentFile("");
        if (browseFileRef) {
            browseFileRef.current.click();
        } else {
            displayNotification("error", "Error opening file selection window.");
        }
    };

    const clearFile = () => {
        setSelectedStructures({ ...selectedStructures, file: { currentFile: null, fileName: "" } });
        setFileName("");
    }

    const getFile = (files) => {
        const selectedFile = files[0];
        if (selectedFile) {
            setCurrentFile(selectedFile);
            setSelectedStructures({
                ...selectedStructures, file: {
                    currentFile: selectedFile,
                    fileName: selectedFile.name,
                }
            });
            setFileName(selectedFile.name);
        } else {
            setFileName("");
        }
    };

    const changeComponentLanguage = async (lang) => {
        setSelectedStructures({ ...selectedStructures, language_id: lang });
        setComponentLanguage(lang);
    }

    const generateFullPdf = async () => {
        try {
            const fName = `WOAH_Report_${currentDate}.pdf`;
            let generalText = '';
            const ret = await sendAPIRequest(`/api/generate-pdf/full-chapter-pdf-download?lang_id=${componentLanguage}&animal_type=${selectedStandard.animal_type}&standard_type=${selectedStandard.standard_type}&general_text=${generalText}`, 'GET')
            const res = await ret.json()
            if (res.success && res.status === 200) {
                // For local enviornment
                const downloadResponse = await sendAPIRequest(`/api/content-navigation-pdf/download?fileName=${fName}&delete_action=false&uniqueName=${res.data.pdf_link}`);
                // For live enviornment only
                // const downloadResponse = await fetch(${CONTENT_PDF_DOWNLOAD_API}?file_name=${fName}, {
                //     method: 'GET',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                // });
                if (downloadResponse.ok) {
                    const blob = await downloadResponse.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fName ?? 'testing.pdf';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    displayNotification('success', 'Successfully downloaded file');
                } else {
                    displayNotification('error', 'Error downloading file');
                }
            } else {
                displayNotification('error', 'Error creating PDF');
            }
        } catch (error) {
        }
    }

    const handleSetName = () => {
        if (selectedStructures.chapter && Object.keys(selectedStructures.chapter).length > 0) {
            let newCha = chapterOptions.find((i) => i.value === selectedStructures.chapter.group_name)
            if (newCha) {
                setSelectedChapter({ ...selectedChapter, label: newCha.label })
            }
        }
        if (selectedStructures.section && Object.keys(selectedStructures.section).length > 0) {
            let newCha = sectionOptions.find((i) => i.value === selectedStructures.section.group_name)
            if (newCha) {
                setSelectedSection({ ...selectedSection, label: newCha.label })
            }
        }
    }

    const changeChaptersName = (chapters) => {
        if (chapters.length > 0) {
            return chapters.map((chapter) => {
                if (chapter.chapter_no === 0) {
                    return {
                        ...chapter,
                        display_name: chapter.chapter_name
                    };
                }
                return chapter;
            });
        }
        return chapters;
    };

    return (
        <>
            {
                config.is_display_header && (
                    <>
                        <CustomCard className={'d-flex justify-content-between'}>
                            <CardHeader>
                                {t("55", "WOAH International Standards")}
                            </CardHeader>
                            <CardBody>
                                <Tooltip title="Expand/Collapse Section" placement="top">
                                    <i className={`fas ${isStandardVisible ? 'fa-chevron-down' : 'fa-chevron-up'} text-primary fs-2 ps-2`} onClick={() => { setIsSetandardVisible(prev => !prev) }}></i>
                                </Tooltip>
                            </CardBody>
                        </CustomCard>
                    </>
                )
            }
            {
                isStandardVisible && config.is_display_structures && (
                    <CustomCard>
                        <div className="row py-3">
                            <div className="col-md-12">
                                <StandardsSelectCard>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            className="text-center d-flex justify-content-between"
                                            onChange={handleChange}
                                            value={selectedStandard?.standard}
                                        >
                                            <FormControlLabel
                                                className="px-5 mb-2"
                                                value="terrestrial-code"
                                                control={<Radio />}
                                                label={t("57", "Terrestrial Code​")}
                                                disabled={config.checkForPermissions ? !hasPermissionForTerrestrialCode(authData) : false}
                                            />
                                            <FormControlLabel
                                                className="px-5 mb-2"
                                                value="terrestrial-manual"
                                                control={<Radio />}
                                                label={t("58", "Terrestrial Manual​")}
                                                disabled={config.checkForPermissions ? !hasPermissionForTerrestrialManual(authData) : false}
                                            />
                                            <FormControlLabel
                                                className="px-5 mb-2"
                                                value="aquatic-code"
                                                control={<Radio />}
                                                label={t("59", "Aquatic Code​")}
                                                disabled={config.checkForPermissions ? !hasPermissionForAquaticCode(authData) : false}
                                            />
                                            <FormControlLabel
                                                className="px-5 mb-2"
                                                value="aquatic-manual"
                                                control={<Radio />}
                                                label={t("60", "Aquatic Manual​")}
                                                disabled={config.checkForPermissions ? !hasPermissionForAquaticManual(authData) : false}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </StandardsSelectCard>
                            </div>
                        </div>
                    </CustomCard>
                )
            }
            <CustomCard>
                {
                    config.is_display_languages && (
                        <Grid container>
                            <Grid item lg={8} md={8} sm={8} xs={12}>

                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={12}>

                                <Box sx={{ display: 'flex', justifyItems: 'end', justifyContent: 'end', alignItems: 'center' }}>
                                    <Typography
                                        variant="button"
                                        sx={{
                                            cursor: hasPermissionsForEN(authData) ? 'pointer' : 'default',
                                            marginX: '5px',
                                            textDecoration: parseInt(componentLanguage) === LIST_DETAIL_ELEMENTS.languages.english
                                                ? 'underline'
                                                : 'none',
                                            opacity: hasPermissionsForEN(authData) ? '1' : '0.5'
                                        }}
                                        disabled={config.checkForPermissions ? !hasPermissionsForEN(authData) : true}
                                        onClick={() => { (hasPermissionsForEN(authData) && changeComponentLanguage(LIST_DETAIL_ELEMENTS.languages.english)) }}
                                    >
                                        EN
                                    </Typography>
                                    <Typography
                                        variant="button"
                                        sx={{
                                            cursor: hasPermissionsForFR(authData) ? 'pointer' : 'default',
                                            marginX: '5px',
                                            textDecoration: parseInt(componentLanguage) === LIST_DETAIL_ELEMENTS.languages.french
                                                ? 'underline'
                                                : 'none',
                                            opacity: hasPermissionsForFR(authData) ? '1' : '0.5'
                                        }}
                                        disabled={config.checkForPermissions ? !hasPermissionsForFR(authData) : true}
                                        onClick={() => (hasPermissionsForFR(authData) && changeComponentLanguage(LIST_DETAIL_ELEMENTS.languages.french))}
                                    >
                                        FR
                                    </Typography>
                                    <Typography
                                        variant="button"
                                        sx={{
                                            cursor: hasPermissionsForES(authData) ? 'pointer' : 'default',
                                            marginX: '5px',
                                            textDecoration: parseInt(componentLanguage) === LIST_DETAIL_ELEMENTS.languages.spanish
                                                ? 'underline'
                                                : 'none',
                                            paddingRight: 1,
                                            opacity: hasPermissionsForES(authData) ? '1' : '0.5'
                                        }}
                                        disabled={config.checkForPermissions ? !hasPermissionsForES(authData) : true}
                                        onClick={() => (hasPermissionsForES(authData) && changeComponentLanguage(LIST_DETAIL_ELEMENTS.languages.spanish))}
                                    >
                                        ES
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                <div className="list-group list-group-horizontal ms-auto">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        {
                            config.is_display_standard_heading &&
                            (<div className="d-flex">

                                <div className="card-title1 lh-1 py-3">
                                    {getHeaderText()}
                                    {config.is_display_completed_standard_pdf && (
                                        <Tooltip title={t("135", "Download Complete Standard PDF")} placement="top">
                                            <i className="fas fa-file-pdf text-primary fs-2 p-3 cursor-pointer"
                                                onClick={generateFullPdf}
                                            ></i>
                                        </Tooltip>)}
                                </div>
                            </div>)}
                    </div>
                </div>
                {
                    config.is_shown_broweFile_button && (
                        <div className='d-flex justify-content-center'>
                            <div className="d-flex flex-column justify-content-center my-3">
                                <button
                                    type="button"
                                    className="btn btn--outline mb-2 fs-6"
                                    onClick={() => fileUpload()}
                                >
                                    <i class="fa-solid fa-cloud-arrow-up me-3 fs-5"></i>
                                    {t("-1", "Browse File")}
                                </button>
                                <label>
                                    {fileName ? (
                                        <div>
                                            <strong>{t("-1", "Selected File")}: </strong>
                                            {fileName}
                                            <i class="fa-solid fa-xmark mx-3 cursor-pointer" onClick={() => clearFile()}></i>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </label>
                                <input
                                    ref={browseFileRef}
                                    type="file"
                                    id="get-file"
                                    style={{ display: "none" }}
                                    onChange={(e) => getFile(e.target.files)}
                                />
                            </div>
                        </div>
                    )
                }
                {
                    isFilterVisible && (
                        <Box sx={{ flexGrow: 1 }} my={2}>
                            <Grid container spacing={2}>
                                {
                                    config.is_display_area && (
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <AutoCompleteDropdown
                                                label={loader.loading_volumes ? 'loading...' : selectedStandard.standard === 'terrestrial-code' || selectedStandard.standard === 'aquatic-code' ? t("-1", "Area") : t("120", "Part")}
                                                className={loader.loading_volumes ? 'opacity-50' : 'opacity-100'}
                                                disabled={loader.loading_volumes}
                                                isGroup={true}
                                                showClearIcon={true}
                                                error={false}
                                                required={true}
                                                multiple={false}
                                                hasselectall={false}
                                                options={volumeOptions}
                                                onChange={(event, value) => {
                                                    setSelectedSection({
                                                        value:"",
                                                        label:"",
                                                        group_value:"",
                                                        id:"",
                                                        ref_no:""
                                                    })
                                                    const currentValue = volumes.find(volume => volume.area_vol_no === value?.value);
                                                    setSelectedVolume(value);
                                                    setSelectedStructures({ ...selectedStructures, volume: currentValue,section:{} });
                                                }}
                                                value={
                                                    selectedVolume?.value ? selectedVolume : { label: '', value: '' }
                                                }
                                                size='medium'
                                            />
                                        </Grid>
                                    )}
                                {
                                    config.is_display_sections && (
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <AutoCompleteDropdown
                                                label={loader.loading_sections ? 'loading...' : selectedStandard.standard === 'terrestrial-code' || selectedStandard.standard === 'aquatic-code' ? t("119", "Area/Section") : t("120", "Part/Section")}
                                                className={loader.loading_sections ? 'opacity-50' : 'opacity-100'}
                                                disabled={loader.loading_sections}
                                                isGroup={true}
                                                showClearIcon={true}
                                                error={false}
                                                required={true}
                                                multiple={false}
                                                hasselectall={false}
                                                options={sectionOptions}
                                                onChange={(event, value) => {
                                                    if(!value){
                                                        setSelectedVolume({
                                                            value:"",
                                                            label:"",
                                                            group_value:"",
                                                            id:"",
                                                            ref_no:""
                                                        })
                                                        setSelectedSection({
                                                            value:"",
                                                            label:"",
                                                            group_value:"",
                                                            id:"",
                                                            ref_no:""
                                                        })
                                                        setSelectedChapter({
                                                            value:"",
                                                            label:"",
                                                            group_value:"",
                                                            id:"",
                                                            ref_no:""
                                                        })
                                                        setSelectedStructures({ ...selectedStructures, section: {},chapter:{},volume:{} });
                                                        return;
                                                    }
                                                    setSelectedChapter({
                                                        value:"",
                                                        label:"",
                                                        group_value:"",
                                                        id:"",
                                                        ref_no:""
                                                    })
                                                    const currentValue = sections.find(section => section.group_name === value?.value);
                                                    if (currentValue) {
                                                        setSectionMetaData({ id: '', ref_number: '', volume_no: currentValue?.area_vol_no });
                                                    }
                                                    setSelectedSection(value);
                                                    setSelectedStructures({ ...selectedStructures, section: currentValue,chapter:{} });
                                                }}
                                                value={
                                                    selectedSection?.value ? selectedSection : { label: '', value: '' }
                                                }
                                                size='medium'
                                            />
                                        </Grid>
                                    )}
                                {
                                    config.is_display_chapters && selectedSection.ref_no !==0 && (
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <AutoCompleteDropdown
                                                label={isChapterLoading ? 'loading...' : t("23", "Chapter")}
                                                className={isChapterLoading ? 'opacity-50' : 'opacity-100'}
                                                disabled={isChapterLoading}
                                                isGroup={true}
                                                showClearIcon={true}
                                                error={false}
                                                required={true}
                                                multiple={false}
                                                hasselectall={false}
                                                options={chapterOptions}
                                                onChange={async (event, value) => {
                                                    const currentValue = chapters.find(chapter => chapter.group_name === value?.value);
                                                    if (currentValue) {
                                                        await returnChapterId(currentValue.RID, currentValue.lang_id, currentValue)
                                                    } else {
                                                        setSelectedStructures({ ...selectedStructures, chapter: currentValue });
                                                    }
                                                    setSelectedChapter(value);
                                                }}
                                                value={
                                                    selectedChapter?.value ? selectedChapter : { label: '', value: '' }
                                                }
                                                size='medium'
                                            />
                                        </Grid>
                                    )
                                }
                                {
                                    config.is_display_subsections && (
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <AutoCompleteDropdown
                                                label={loader.loading_subsections ? 'loading...' : t("-1", "Article/Subsection")}
                                                className={loader.loading_subsections ? 'opacity-50' : 'opacity-100'}
                                                disabled={loader.loading_subsections}
                                                isGroup={false}
                                                showClearIcon={true}
                                                error={false}
                                                required={true}
                                                multiple={false}
                                                hasselectall={false}
                                                options={subSectionOptions}
                                                onChange={async (event, value) => {
                                                    const currentValue = subSections.find(subsection => subsection.id === value?.value);
                                                    setSelectedSubSection(value);
                                                    setSelectedStructures({ ...selectedStructures, sub_section: currentValue });
                                                }}
                                                value={
                                                    selectedSubSection?.value ? selectedSubSection : { label: '', value: '' }
                                                }
                                                size='medium'
                                            />
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Box>
                    )
                }
            </CustomCard>
        </>
    );
}

StructureNavigationComponent.defaultProps = {
    reloadData: () => { },
    config: {
        is_display_header: false,
        header_text: '',
        is_display_header_button: false,
        header_button_config: {
            button_text: '',
            button_variant: '',
            onClick: () => { },
        },
        is_display_structures: false,
        is_display_structure_name: false,
        is_download_pdf: false,
        is_display_languages: false,
        is_display_area: false,
        is_display_sections: false,
        is_display_chapters: false,
        is_display_subsections: false,
        is_shown_broweFile_button: false,
        checkForPermissions: false,
        is_display_font_changer: false,
        is_display_pdf_component: false,
        is_display_chapter_name: false,
        is_display_standard_heading: false,
    },
    model: '',
};
export default StructureNavigationComponent;