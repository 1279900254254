import { Checkbox, FormControlLabel } from "@mui/material";
import TextFieldCustom from "components/TextFieldCustom";
import Button from "components/common/Button";
import CardBody from "components/common/CardBody";
import CardHeader from "components/common/CardHeader";
import Column from "components/common/Column";
import Container from "components/common/Container";
import CustomCard from "components/common/CustomCard";
import Row from "components/common/Row";
import { displayNotification, sendAPIRequest } from "helpers";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate,useParams } from "react-router-dom";
import { setCurrentPage } from "redux/generalSlice";

const DynamicFiltersForm = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    const initForm = {
        filter_name: '',
        is_filter: true,
    };
    const [form, setForm] = useState(initForm);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(setCurrentPage("administration"));
    }, []);
    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    const getData = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/lists/filter-list/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setForm({
                    filter_name: res.data.list_name,
                    is_filter: res.data.is_filter
                });
                // setMasterID(res.data.list_master_id);
            }
        } catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    }

    const handleSave = async () => {
        setIsSaving(true);
        try {
            if (!form.filter_name) {
                displayNotification('error', 'Filter name is required [F]');
                setIsSaving(false);
                return;
            }
            if (!window.confirm('Are you sure you want to save?')) {
                setIsSaving(false);
                return;
            }
            let ret
            if(id){
                ret = await sendAPIRequest(
                    `/api/lists/filter-update/${id}`,
                    'PUT',
                    {
                        list_name: form.filter_name,
                        is_filter: form.is_filter
                    }
                );
            }
            else{
                ret = await sendAPIRequest(
                    `/api/lists/master`,
                    'POST',
                    {
                        list_name: form.filter_name,
                        is_filter: form.is_filter
                    }
                );
                setForm({...initForm})
            }
            
            const res = await ret.json();
            if (res.success && res.status === 201) {
                displayNotification('success', 'Successfully created new filter [B]');
            } else {
                displayNotification('error', 'Failed to create new filter [B]');
            }
        } catch (err) {
            console.log(err);
            displayNotification('error', 'Failed to create new filter [F]');
        }
        setIsSaving(false);
    }

    return (
        <>
            <Container>
                <CustomCard className={'d-flex'}>
                    <CardHeader>
                        Create New Filter
                    </CardHeader>
                    <CardBody>
                        <Button
                            onClick={() => navigate('/portal/dynamic-filters')}
                        >
                            Back
                        </Button>
                    </CardBody>
                </CustomCard>
                <CustomCard className={'my-2'}>
                    <CardBody>
                        <Row className={'w-100'}>
                            <Column col={8}>
                                <TextFieldCustom
                                    variant="outlined"
                                    label='Filter Name'
                                    id="document-title"
                                    fullWidth
                                    required
                                    size="small"
                                    sx={{
                                        backgroundColor: "white",
                                        color: "white",
                                        border: "#ff4815",
                                        width: "100%",
                                    }}
                                    value={form.filter_name}
                                    onChange={(e) => setForm({ ...form, filter_name: e.target.value })}
                                />
                            </Column>
                            <Column col={4}>
                                <FormControlLabel control={
                                    <Checkbox
                                        defaultChecked
                                        size="small"
                                        checked={form.is_filter}
                                        onChange={(e) => setForm({ ...form, is_filter: e.target.checked })}
                                    />
                                } label="Enable in Search" />
                            </Column>
                        </Row>
                        <Row className={'w-100 my-2'}>
                            <Column className={'d-flex justify-content-end'}>
                                <Button
                                    onClick={() => handleSave()}
                                    disabled={isSaving}
                                >
                                    {
                                        isSaving
                                            ? 'Saving...'
                                            : id? 'Update' : 'Save'
                                    }
                                </Button>
                            </Column>
                        </Row>
                    </CardBody>
                </CustomCard>
            </Container>
        </>
    );
}

DynamicFiltersForm.defaultProps = {};

export default DynamicFiltersForm;