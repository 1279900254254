import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { displayNotification, sendAPIRequest } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "../../../redux/generalSlice";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DataTable from "../../components/DataTable";
import { useTranslation } from "react-i18next";
import Crumbs from "components/crumbs/Crumbs";

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userId = useSelector((s) => s.auth.data.id);
  // const crumbs = [
  //   {
  //     label: t("12", "Multilingual Keywords"),
  //     active: true,
  //   },
  // ];
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(setCurrentPage("data-migration"));
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const req = await sendAPIRequest(`/api/multilingual`);
      const res = await req.json();
      if (res.success) {
        setData([...res.data]);
      } else {
        displayNotification('error', `${t("925", "Error loading multilingual keywords data")} [B]`);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      displayNotification('error', `${t("925", "Error loading multilingual keywords data")} [F]`);
    }
  };

  const handleDelete = async (regionId) => {
    try {
      if (!window.confirm(t("853", "You are about to delete a keyword. Are you sure?"))) {
        return;
      }
      const req = await sendAPIRequest(
        `/api/multilingual/${regionId}`,
        "DELETE",
        { updatedBy: userId }
      );
      const res = await req.json();
      if (res.success) {
        displayNotification('success', `${t("854", "Successfully deleted keyword data")} [B]`);
        loadData();
      } else {
        displayNotification('error', `${t("855", "Error deleting keyword data")} [B]`);
      }
    } catch (e) {
      console.log(e);
      displayNotification('error', `${t("855", "Error deleting keyword data")} [F]`);
    }
  };

  return (
    <>

      <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2">
        <div className='d-flex justify-content-between'>
          <h1 className="card-title1 font-custom-2 lh-1">{t("-1", "Multilingual Keywords")}</h1>
        </div>
          <div className="header-right-items ms-auto d-flex align-items-center">
            <Link to={"/portal/data-migrations/multilingual-keywords/create"} className="ms-1">
              <button className="btn btn--primary">
                <i className="fas fa-plus align-middle" />
                <span className="align-middle">{t("83", "Add New Keyword")}</span>
              </button>
            </Link>
          </div>
        </div>
        <div className="card card-woah border border-secondary shadow rounded-0">
          <div className="card-body p-4">
            <DataTable
              getRowId={(row) => row.id}
              isLoading={isLoading}
              rows={data}
              className="text-white bg-primary rounded-0"
              columns={[
                {
                  field: "language",
                  headerName: t("26", "Language"),
                  headerClassName: "text-white bg-primary rounded-0",
                  minWidth: 150,
                  flex: 0,
                  valueGetter: (params) => params.value?.list_element_name,
                },
                {
                  field: "tag",
                  headerName: t("95", "Tag"),
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 0,
                },
                {
                  field: "name_space_data",
                  headerName: t("-1", "Name Space"),
                  headerClassName: "text-white bg-primary rounded-0",
                  valueGetter: (params) => params.value?.list_element_name,
                  flex: 0,
                },
                {
                  field: "description",
                  headerName: t("96", "Description"),
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "actions",
                  type: "actions",
                  headerClassName: "text-white bg-primary rounded-0",
                  headerName: t("74", "Actions"),
                  getActions: (params) => [
                    <GridActionsCellItem
                      label={t("80", "Edit")}
                      key={0}
                      showInMenu
                      onClick={() =>
                        navigate(
                          `/portal/multilingual-keywords/${params.row.id}/edit`
                        )
                      }
                    />,
                    <GridActionsCellItem
                      showInMenu
                      label={t("39", "Delete")}
                      key={1}
                      onClick={() => handleDelete(params.row.id)}
                    />,
                  ],
                  width: 90,
                },
              ]}
            />
          </div>
        </div>
      </>
      );
};

      export default Index;
